import React from "react";
import styled from "styled-components";
// import backgroundImage from "./accessibilitybg.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const PageWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const PrivacyContainer = styled.div`
  padding: 40px 20px;
  max-width: 900px;
  margin: 40px auto;
  background: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-family: "Roboto", sans-serif;
  color: #333;

  @media (max-width: 768px) {
    padding: 20px 15px;
    margin: 20px auto;
  }
`;

const PrivacyHeader = styled.h1`
  font-size: 2.5em;
  text-align: center;
  color: #2c3e50;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    font-size: 2em;
    margin-bottom: 20px;
  }
`;

const PrivacySection = styled.section`
  margin-bottom: 30px;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const SectionTitle = styled.h2`
  font-size: 1.8em;
  color: #34495e;
  margin-bottom: 15px;
  position: relative;
  padding-bottom: 10px;

  &::after {
    content: "";
    width: 50px;
    height: 3px;
    background: #fe4324;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  @media (max-width: 768px) {
    font-size: 1.5em;
    margin-bottom: 10px;
    padding-bottom: 5px;

    &::after {
      width: 40px;
      height: 2px;
    }
  }
`;

const SectionContent = styled.p`
  color: #7f8c8d;
  font-size: 1em;
  line-height: 1.8;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 0.9em;
  }
`;

const TickMark = styled(FontAwesomeIcon)`
  color: #dc143c;
  margin-right: 10px;
  margin-top: 6px;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start;

  @media (max-width: 768px) {
    font-size: 0.9em;
  }
`;

function Accessibility() {
  return (
    <>
      <PageWrapper>
        <PrivacyContainer>
          <PrivacyHeader>Accessibility</PrivacyHeader>
          <PrivacySection>
            <SectionContent>
              Here at Talent Folder LLC we are committed to providing a website
              that is accessible to the widest possible audience, regardless of
              technology or ability. We are actively working to increase the
              accessibility and usability of all Talent Folder LLC sites and in
              doing so adhere to many of the available standards and guidelines.
            </SectionContent>
          </PrivacySection>
          <PrivacySection>
            <SectionTitle>Accessibility Statement.</SectionTitle>
            <SectionContent>
              <ul>
                <ListItem>
                  <TickMark icon={faCheck} />
                  This website endeavors to conform to level double-A of the
                  World Wide Web Consortium (W3C) Web Content Accessibility
                  Guidelines 2.0.
                </ListItem>
              </ul>
            </SectionContent>
          </PrivacySection>
          <PrivacySection>
            <SectionTitle>Navigation</SectionTitle>
            <SectionContent>
              <ul>
                <ListItem>
                  <TickMark icon={faCheck} />
                  We have implemented a skippable area concept on each page and
                  components to allow screen reader users to link to the main
                  areas of the page.
                </ListItem>
              </ul>
            </SectionContent>
          </PrivacySection>

          <PrivacySection>
            <SectionTitle>Headings and navigation menus</SectionTitle>
            <SectionContent>
              <ul>
                <ListItem>
                  <TickMark icon={faCheck} />
                  HTML heading tags are used to convey document structure. H1
                  tags are used for main titles, H2 tags for subtitles etc.
                  Navigation menus are marked up as HTML lists. This ensures
                  that the number of links in the list is read out at the start,
                  and it can be skipped easily.
                </ListItem>
              </ul>
            </SectionContent>
          </PrivacySection>

          <PrivacySection>
            <SectionTitle>Images</SectionTitle>
            <SectionContent>
              <ul>
                <ListItem>
                  <TickMark icon={faCheck} />
                  All images used in this site include descriptive alt tag
                  attributes.
                </ListItem>
              </ul>
            </SectionContent>
          </PrivacySection>
          <PrivacySection>
            <SectionTitle>Colors</SectionTitle>
            <SectionContent>
              <ul>
                <ListItem>
                  <TickMark icon={faCheck} />
                  We have taken care to ensure that the site&#39;s font and
                  background color combinations contrast significantly and are
                  effective in ensuring information is still clear when viewed
                  in different color combinations. If you wish to override the
                  site&#39;s colors, you can do this by changing your browser
                  settings to your own preference.
                </ListItem>
              </ul>
            </SectionContent>
          </PrivacySection>

          <PrivacySection>
            <SectionTitle>Font Size</SectionTitle>
            <SectionContent>
              <ul>
                <ListItem>
                  <TickMark icon={faCheck} />
                  The font used on our site can be changed in size. You can do
                  this by changing your browser settings to your own preference.
                </ListItem>
              </ul>
            </SectionContent>
          </PrivacySection>
          <PrivacySection>
            <SectionTitle>Forms and Fields</SectionTitle>
            <SectionContent>
              <ul>
                <ListItem>
                  <TickMark icon={faCheck} />
                  All forms fields follow a logical tab sequence to ensure easy
                  navigation. Most form fields also have &#39;label&#39; and
                  &#39;id&#39; attributes to explicitly associate the form field
                  with its label to allow for easy entry of data. Furthermore,
                  we have implemented the mandatory field mark (*) on the forms.
                </ListItem>
              </ul>
            </SectionContent>
          </PrivacySection>

          <PrivacySection>
            <SectionTitle>Javascript</SectionTitle>
            <SectionContent>
              <ul>
                <ListItem>
                  <TickMark icon={faCheck} />
                  The use of Javascript has been kept to an absolute minimum.
                </ListItem>
              </ul>
            </SectionContent>
          </PrivacySection>

          <PrivacySection>
            <SectionTitle>Links</SectionTitle>
            <SectionContent>
              <ul>
                <ListItem>
                  <TickMark icon={faCheck} />
                  All links have been written to make sense when taken out of
                  context and links that open a new window are described in the
                  alt tag. Furthermore, links in text parts, are underlined to
                  make sure its clearly visible when there is a link.
                </ListItem>
              </ul>
            </SectionContent>
          </PrivacySection>

          <PrivacySection>
            <SectionTitle>Movement</SectionTitle>
            <SectionContent>
              <ul>
                <ListItem>
                  <TickMark icon={faCheck} />
                  We have kept the animated images to a minimum on our site to
                  make sure that it&#39;s accessible for the user.
                </ListItem>
              </ul>
            </SectionContent>
          </PrivacySection>

          <PrivacySection>
            <SectionTitle>Video</SectionTitle>
            <SectionContent>
              <ul>
                <ListItem>
                  <TickMark icon={faCheck} />
                  Video content on our site comes with a text transcript for
                  deaf users. Videos that have no audio content will not have a
                  transcript.
                </ListItem>
              </ul>
            </SectionContent>
          </PrivacySection>

          <PrivacySection>
            <SectionTitle>PDFs</SectionTitle>
            <SectionContent>
              <ul>
                <ListItem>
                  <TickMark icon={faCheck} />
                  All PDF downloads endeavor to be accessible, however if you
                  would like an alternative version please contact us.
                </ListItem>
              </ul>
            </SectionContent>
          </PrivacySection>

          <PrivacySection>
            <SectionTitle>Contact</SectionTitle>
            <SectionContent>
              <ul>
                <ListItem>
                  <TickMark icon={faCheck} />
                  If you have any questions regarding a local Talent Folder LLC
                  company, reach out to the local company.
                </ListItem>
              </ul>
            </SectionContent>
          </PrivacySection>
        </PrivacyContainer>
      </PageWrapper>
    </>
  );
}

export default Accessibility;

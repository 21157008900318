import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import TablePagination from "@mui/material/TablePagination";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import DialogContentText from "@mui/material/DialogContentText";
import Link from "@mui/material/Link";
import useSwr from "../../../../HOOKS/useSWR";
import moment from "moment";
function Row(props) {
  const { row, onDelete } = props;
  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.applicant_id.name || "Unknown"}
        </TableCell>
        <TableCell>{row.jobpost_id.title}</TableCell>
        <TableCell align="right">
          {row.jobpost_id.companyId.companyName}
        </TableCell>
        <TableCell align="right">{row.currentSalary}</TableCell>
        <TableCell align="right">{row.experience}</TableCell>
        <TableCell align="right">
          {row.jobpost_id.location.country || "Unknown"}
        </TableCell>
        <TableCell align="right">
          <IconButton
            aria-label="delete"
            size="small"
            onClick={() => onDelete(row.jobpost_id.title)}
            style={{ color: "red" }}
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="details">
                <TableHead>
                  <TableRow>
                    <TableCell>Current Salary</TableCell>
                    <TableCell>Apply Date</TableCell>
                    <TableCell>Work Mode</TableCell>
                    <TableCell>CV</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{row.currentSalary}</TableCell>
                    <TableCell>
                      {moment(row.dateApplied).format("Do MMMM YYYY")}
                    </TableCell>
                    <TableCell>{row.jobpost_id.modeOfWork}</TableCell>
                    <TableCell>
                      <Link
                        href={row.applicant_id.resume || "#"}
                        target="_blank"
                        rel="noopener"
                      >
                        Download CV
                      </Link>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    applicant_id: PropTypes.shape({
      name: PropTypes.string.isRequired,
      resume: PropTypes.string.isRequired,
      country: PropTypes.string,
    }).isRequired,
    jobpost_id: PropTypes.shape({
      title: PropTypes.string.isRequired,
      modeOfWork: PropTypes.string.isRequired,
    }).isRequired,
    currentSalary: PropTypes.number.isRequired,
    experience: PropTypes.number.isRequired,
    dateApplied: PropTypes.string.isRequired,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default function CollapsibleResumeTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);
  const {
    data: applicants,
    isValidating,
    mutate,
  } = useSwr(`applyjobs/jobapplied`);
  // Ensure applicants.data.data.data is initialized safely
  const applicats = applicants?.data?.data?.data || [];
  const [rows, setRows] = React.useState(applicats);

  useEffect(() => {
    if (applicats) {
      setRows(applicats);
    }
  }, [applicats]);

  const handleDelete = (title) => {
    const rowToDelete = rows.find((row) => row.jobpost_id.title === title);
    setRowToDelete(rowToDelete);
    setDeleteConfirmationOpen(true);
  };

  const handleDeleteConfirmed = () => {
    if (rowToDelete) {
      setRows(
        rows.filter(
          (row) => row.jobpost_id.title !== rowToDelete.jobpost_id.title
        )
      );
      setDeleteConfirmationOpen(false);
    }
  };

  const handleDeleteCanceled = () => {
    setRowToDelete(null);
    setDeleteConfirmationOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredRows = rows?.length
    ? rows.filter((row) =>
        row.applicant_id.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : [];

  return (
    <Paper>
      <Box sx={{ padding: 2 }}>
        <TextField
          label="Search"
          variant="outlined"
          value={searchQuery}
          onChange={handleSearch}
          fullWidth
        />
      </Box>
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Applicant Name</TableCell>
              <TableCell>Applied Post</TableCell>
              <TableCell align="right">Company Name</TableCell>
              <TableCell align="right">Expected Salary</TableCell>
              <TableCell align="right">Experience</TableCell>
              <TableCell align="right">Candidate Location</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <Row key={row._id} row={row} onDelete={handleDelete} />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog
        open={deleteConfirmationOpen}
        onClose={handleDeleteCanceled}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the row?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCanceled} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirmed} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}

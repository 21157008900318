// import * as React from "react";
import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import TextField from "@mui/material/TextField";
import TablePagination from "@mui/material/TablePagination";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import DialogContentText from "@mui/material/DialogContentText";
import useSwr from "../../../../HOOKS/useSWR";
import useMutation from "../../../../HOOKS/useMutation";
function createData(
  title,
  companyName,
  salary,
  experience,
  location,
  jobDescription
) {
  return {
    title,
    companyName,
    salary,
    experience,
    location,
    jobDescription,
  };
}

function Row(props) {
  const { mutation } = useMutation();
  const { row, onDelete, onEdit, mutate } = props;
  const [open, setOpen] = React.useState(false);
  const handleDelete = async () => {
    await mutation(`jobpost/deletejob/${row?._id}`, {
      method: "DELETE",
    });
    mutate();
  };
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row?.title || "Not Provided"}
        </TableCell>
        <TableCell align="right">
          {row?.companyName || "Not Provided"}
        </TableCell>
        <TableCell align="right">
          {`${row?.salary?.min} - ${row?.salary?.max} LPA` || "Not Provided"}
        </TableCell>
        <TableCell align="right">
          {`${row?.experience?.min} - ${row?.experience?.max} Years` ||
            "Not Provided"}
        </TableCell>
        <TableCell align="right">
          {`${row?.location?.city}, ${row?.location?.state}, ${row?.location?.country}` ||
            "Not Provided"}
        </TableCell>
        <TableCell align="right">
          {/* <IconButton
            aria-label="edit"
            size="small"
            onClick={() => onEdit(row)}
            style={{ color: "green" }}
          >
            <EditIcon />
          </IconButton> */}
          <IconButton
            aria-label="delete"
            size="small"
            onClick={handleDelete}
            style={{ color: "red" }}
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Job Description
              </Typography>
              <Typography variant="body2" component="p">
                {row?.description || "Not Provided"}
              </Typography>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function EditDialog({ open, onClose, row, onSave }) {
  const [formData, setFormData] = React.useState({ ...row });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    onSave(formData);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Row</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="title"
          type="text"
          fullWidth
          name="title"
          value={formData.title}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Company Name"
          type="text"
          fullWidth
          name="companyName"
          value={formData.companyName}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Salary"
          type="number"
          fullWidth
          name="salary"
          value={formData.salary}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Experience"
          type="number"
          fullWidth
          name="experience"
          value={formData.experience}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Location"
          type="text"
          fullWidth
          name="location"
          value={formData.location}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Job Description"
          type="text"
          fullWidth
          name="jobDescription"
          value={formData.jobDescription}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

EditDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  row: PropTypes.object,
  onSave: PropTypes.func.isRequired,
};

export default function CollapsibleTable() {
  const { data, isValidating, mutate } = useSwr(`jobpost/getjobpost`);
  const jobData = data?.data?.data?.data;
  const [rows, setRows] = React.useState(jobData);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [editRow, setEditRow] = React.useState(null);
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);
  React.useEffect(() => {
    if (jobData) {
      setRows(jobData);
    }
  }, [jobData]);
  const handleDelete = (title) => {
    const rowToDelete = rows.find((row) => row.title === title);
    setRowToDelete(rowToDelete);
    setDeleteConfirmationOpen(true);
  };

  const handleDeleteConfirmed = () => {
    if (rowToDelete) {
      setRows(rows.filter((row) => row.title !== rowToDelete.title));
      setDeleteConfirmationOpen(false);
    }
  };

  const handleDeleteCanceled = () => {
    setRowToDelete(null);
    setDeleteConfirmationOpen(false);
  };

  const handleEdit = (row) => {
    setEditRow(row);
    setEditDialogOpen(true);
  };

  const handleSave = (updatedRow) => {
    setRows(
      rows.map((row) => (row.title === updatedRow.title ? updatedRow : row))
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };
  const filteredRows = rows?.length
    ? rows.filter((row) =>
        row.title
          ? row.title.toLowerCase().includes(searchQuery.toLowerCase())
          : false
      )
    : [];

  return (
    <>
      {!jobData?.length === 0 ? (
        <p>Loading</p>
      ) : (
        <Paper>
          <Box sx={{ padding: 2 }}>
            <TextField
              label="Search"
              variant="outlined"
              value={searchQuery}
              onChange={handleSearch}
              fullWidth
            />
          </Box>
          {filteredRows?.length ? (
            <>
              <TableContainer>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell>Designation</TableCell>
                      <TableCell align="right">Company Name</TableCell>
                      <TableCell align="right">Salary</TableCell>
                      <TableCell align="right">Experience</TableCell>
                      <TableCell align="right">Location</TableCell>
                      <TableCell align="right">Actions</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {filteredRows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => (
                        <Row
                          key={row._id}
                          row={row}
                          onDelete={handleDelete}
                          onEdit={handleEdit}
                          mutate={mutate}
                        />
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          ) : (
            <p>No Data Found</p>
          )}
          {editRow && (
            <EditDialog
              open={editDialogOpen}
              onClose={() => setEditDialogOpen(false)}
              row={editRow}
              onSave={handleSave}
            />
          )}
          <Dialog
            open={deleteConfirmationOpen}
            onClose={handleDeleteCanceled}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Confirm Deletion</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete the row?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDeleteCanceled} color="primary">
                Cancel
              </Button>
              <Button onClick={handleDeleteConfirmed} color="primary" autoFocus>
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
      )}
    </>
  );
}

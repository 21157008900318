import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faEye } from "@fortawesome/free-regular-svg-icons";
import { Link, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import useMutation from "../../HOOKS/useMutation";
import { useNavigate } from "react-router-dom";
const Resetpassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [inputLength, setInputLength] = useState(0);
  const { mutation } = useMutation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm Password is required"),
    }),
    onSubmit: async (values) => {
      const res = await mutation(`user/resetpassword`, {
        method: "POST",
        body: {
          token,
          newPassword: values.confirmPassword,
        },
      });

      if (res?.results?.success) {
        Swal.fire({
          title: "Success!",
          text: "Successfully Updates",
          icon: "success",
          confirmButtonText: "ok",
        }).then(() => {
          navigate("/signin?role=candidate");
        });

        formik.resetForm();
      }

      if (res?.results?.status === 404) {
        Swal.fire({
          title: "Error!",
          text: res?.results?.error?.message,
          icon: "error",
          confirmButtonText: "ok",
        });
      }
      formik.resetForm();
    },
  });

  const togglePasswordVisibility = () => {
    inputLength && setShowPassword(!showPassword);
  };

  return (
    <section
      className="w-full bg-center bg-cover bg-no-repeat h-screen"
      style={{
        backgroundImage: `url('/img/login-bg.png')`,
        backgroundAttachment: "fixed",
      }}
    >
      <div className="flex flex-col justify-center items-center px-6 py-12 lg:px-8">
        <div className="bg-white border rounded-xl border-slate-300 w-full md:w-[40%] ld:w-[39%] py-10 px-5 md:px-5 lg:px-0">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img
              className="mx-auto h-16 w-60"
              src="/img/logo.png"
              alt="Your Company"
            />
            <h2 className="mt-4 text-center text-lg font-semibold leading-9 tracking-tight text-black-600">
              Reset Your Password
            </h2>
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form onSubmit={formik.handleSubmit} className="space-y-6">
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900 mt-2"
                >
                  New Password<span className="text-redTalent">*</span>
                </label>
                <div className="mt-2 relative">
                  <input
                    id="password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    onChange={(e) => {
                      formik.handleChange(e);
                      setInputLength(e.target.value.length);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    className="block w-full rounded-md px-4 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 pr-3 flex items-center"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? (
                      <FontAwesomeIcon icon={faEye} />
                    ) : (
                      <FontAwesomeIcon icon={faEyeSlash} />
                    )}
                  </button>
                </div>
                {formik.touched.password && formik.errors.password ? (
                  <div className="text-redTalent">{formik.errors.password}</div>
                ) : null}

                <label
                  htmlFor="confirmPassword"
                  className="block text-sm font-medium leading-6 text-gray-900 mt-2"
                >
                  Confirm Password<span className="text-redTalent">*</span>
                </label>
                <div className="mt-2 relative">
                  <input
                    id="confirmPassword"
                    name="confirmPassword"
                    type={showPassword ? "text" : "password"}
                    onChange={(e) => {
                      formik.handleChange(e);
                      setInputLength(e.target.value.length);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.confirmPassword}
                    className="block w-full rounded-md px-4 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 pr-3 flex items-center"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? (
                      <FontAwesomeIcon icon={faEye} />
                    ) : (
                      <FontAwesomeIcon icon={faEyeSlash} />
                    )}
                  </button>
                </div>
                {formik.touched.confirmPassword &&
                formik.errors.confirmPassword ? (
                  <div className="text-redTalent">
                    {formik.errors.confirmPassword}
                  </div>
                ) : null}
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-redTalent px-3 py-1.5 text-md font-semibold leading-6 text-white shadow-sm hover:bg-white hover:border hover:border-redTalent hover:text-redTalent focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Reset Now
                </button>
              </div>
            </form>
          </div>
          <div className="flex flex-col lg:flex-row items-center justify-between px-2 lg:px-16 pt-10">
            <div className="flex gap-2">
              <span className="font-medium">Already Have Password</span>
              <Link
                to="/signin?role=candidate"
                className="text-redTalent hover:underline font-semibold"
              >
                Signin?
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Resetpassword;

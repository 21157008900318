import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import TablePagination from "@mui/material/TablePagination";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import DialogContentText from "@mui/material/DialogContentText";
import logo from "./logo.png";
import useSwr from "../../../../HOOKS/useSWR";
import useMutation from "../../../../HOOKS/useMutation";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
function createData(
  companyName,
  companyDetails,
  imageUrl,
  featured = false,
  sponsored = false
) {
  return { companyName, companyDetails, imageUrl, featured, sponsored };
}

function Row(props) {
  const { mutation } = useMutation();
  const {
    row,
    onDelete,
    onEdit,
    onFeatureToggle,
    onSponsoredToggle,
    fetureMutate,
  } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.title}
        </TableCell>
        <TableCell>
          <Box sx={{ margin: 1 }}>
            <Link to={row.image} target="_blank" rel="noopener noreferrer">
              <img
                src={row.image}
                alt={row.title}
                style={{ maxWidth: "50px", marginTop: "10px" }}
                target="_blank"
              />
            </Link>
          </Box>
        </TableCell>
        <TableCell align="right">
          <IconButton
            aria-label="edit"
            size="small"
            onClick={() => onEdit(row)}
            style={{ color: "blue" }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            size="small"
            onClick={() => onDelete(row._id)}
            style={{ color: "red" }}
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <TableCell>{row.content}</TableCell>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    companyName: PropTypes.string.isRequired,
    companyDetails: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    featured: PropTypes.bool.isRequired,
    sponsored: PropTypes.bool.isRequired,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onFeatureToggle: PropTypes.func.isRequired,
  onSponsoredToggle: PropTypes.func.isRequired,
};

export default function CollapsibleBlogTable() {
  const { mutation } = useMutation();
  const [id, setId] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [editBlogName, setEditBlogName] = useState("");
  // const [editCompanyName, setEditCompanyName] = useState("");
  const [editBlogDetails, setEditBlogDetails] = useState("");
  // const [editCompanyDetails, setEditCompanyDetails] = useState("");

  const [editImageUrl, setEditImageUrl] = useState("");
  const [editImageFile, setEditImageFile] = useState(null);

  const { data: blogs, isValidating, mutate: fetureMutate } = useSwr(`blog`);

  const blogList = blogs?.data?.data?.data || [];

  useEffect(() => {
    if (blogList) {
      setRows(blogList);
    }
  }, [blogList]);
  const [rows, setRows] = useState(blogList);
  const handleDelete = (id) => {
    setRowToDelete(rowToDelete);
    setDeleteConfirmationOpen(true);
    setDeleteId(id);
  };

  const handleDeleteConfirmed = async () => {
    setDeleteConfirmationOpen(false);
    try {
      const res = await mutation(`blog/${deleteId}`, {
        method: "DELETE",
      });
      fetureMutate();
      if (res) {
        Swal.fire({
          title: "Success",
          text: res.results.message,
          icon: "success",
          confirmButtonText: "ok",
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: error.message,
        icon: "Error",
        confirmButtonText: "ok",
      });
    }
  };

  const handleDeleteCanceled = () => {
    setRowToDelete(null);
    setDeleteConfirmationOpen(false);
  };

  const handleEdit = (row) => {
    setRowToEdit(row);
    setEditBlogName(row.title);
    setId(row._id);
    setEditBlogDetails(row.content);
    setEditImageUrl(row.image);
    setEditImageFile(null);
    setEditDialogOpen(true);
  };

  const handleEditConfirmed = () => {
    setEditDialogOpen(false);
  };

  const handleEditCanceled = () => {
    setRowToEdit(null);
    setEditDialogOpen(false);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setEditImageFile(file);
      setEditImageUrl(URL.createObjectURL(file));
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      formData.append("photo", editImageFile);
      formData.append("title", editBlogName);
      formData.append("content", editBlogDetails);
      const res = await mutation(`blog/${id}`, {
        method: "PUT",
        isFormData: true,
        isAlert: true,
        body: formData,
      });
      setEditDialogOpen(false);
      fetureMutate();
      if (res) {
        Swal.fire({
          title: "Success",
          text: res.results.message,
          icon: "success",
          confirmButtonText: "ok",
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: error.message,
        icon: "Error",
        confirmButtonText: "ok",
      });
    }
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };
  const filteredRows = rows?.length
    ? rows.filter((row) =>
        row.title.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : [];
  return (
    <Paper>
      <Box sx={{ padding: 2 }}>
        <TextField
          label="Search"
          variant="outlined"
          value={searchQuery}
          onChange={handleSearch}
          fullWidth
        />
      </Box>
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Blog Title</TableCell>
              <TableCell>Blog Image</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <Row
                  key={row._id}
                  row={row}
                  onEdit={handleEdit}
                  onDelete={handleDelete}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isValidating ? (
        <TableRow>
          <TableCell colSpan={6} align="center">
            Loading...
          </TableCell>
        </TableRow>
      ) : (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
      <Dialog
        open={deleteConfirmationOpen}
        onClose={handleDeleteCanceled}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this Blog?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCanceled} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirmed} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={editDialogOpen}
        onClose={handleEditCanceled}
        aria-labelledby="edit-dialog-title"
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle id="edit-dialog-title">Edit Blog</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              label="Company Name"
              fullWidth
              variant="outlined"
              value={editBlogName}
              onChange={(e) => setEditBlogName(e.target.value)}
            />
            <TextField
              margin="dense"
              label="Blog content"
              fullWidth
              variant="outlined"
              onChange={(e) => setEditBlogDetails(e.target.value)}
            />
            <TextField
              margin="dense"
              label="Image URL"
              fullWidth
              variant="outlined"
              onChange={(e) => setEditImageUrl(e.target.value)}
            />
            <Button variant="contained" component="label">
              Upload Image
              <input type="file" hidden onChange={handleImageChange} />
            </Button>
            {editImageUrl && (
              <Box mt={2} textAlign="center">
                <img
                  src={editImageUrl}
                  alt="Preview"
                  style={{ maxWidth: "100%" }}
                />
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditCanceled} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary" autoFocus>
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Paper>
  );
}

import React, { useRef, useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUpload,
  faDownload,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import useStore from "../../../ZUSTAND";
import useMutation from "../../../HOOKS/useMutation";
const Card = styled.div``;

const UploadBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
  border: 2px dashed #ccc;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 10px;
  flex-direction: column;
  text-align: center;
`;

const UploadIcon = styled(FontAwesomeIcon)`
  font-size: 24px;
  color: #002365;
`;

const FileInput = styled.input`
  display: none;
`;

const FileName = styled.div`
  margin-top: 10px;
  color: #333;
`;

const Button = styled.button`
  padding: 5px 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  margin-top: 10px;
`;

const UploadedFileList = styled.div`
  margin-top: 20px;
`;

const UploadedFileItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const FileNameText = styled.span`
  flex: 1;
`;

const FileActions = styled.div`
  display: flex;
  gap: 10px;
`;

const ActionButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: ${(props) =>
    props.isTrash
      ? "red"
      : "#007bff"}; /* Conditional color based on isTrash prop */
  font-size: 16px;
  display: flex;
  align-items: center;
`;

const ResumeUploadComponent = (userResume) => {
  const { mutate } = useStore();
  const { mutation } = useMutation();
  const user = userResume?.userResume;
  console.log(user);
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(user?.resume || null);
  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleSaveClick = async (e) => {
    e.preventDefault();
    if (selectedFile) {
      setUploadedFile(selectedFile);
      setSelectedFile(null);
      // console.log("File saved:", selectedFile.name);
      const formData = new FormData();
      formData.append("resume", selectedFile);
      try {
        const res = await mutation(`user/updateinfo/${user?._id}`, {
          method: "PUT",
          body: formData,
          isFormData: true,
          isAlert: true,
        });
        if (res?.results?.success) {
          mutate();
          Swal.fire({
            title: "Success",
            text: "",
            icon: "success",
            confirmButtonText: "ok",
          });
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: error.message,
          icon: "Error",
          confirmButtonText: "ok",
        });
      }
    }
  };

  const handleDownloadClick = (file) => {
    if (file instanceof Blob) {
      const url = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = url;
      link.download = file.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else if (typeof file === "string") {
      window.open(file, "_blank");
    }
  };

  const handleDeleteClick = async (e) => {
    e.preventDefault();
    setUploadedFile(null);
    try {
      const res = await mutation(`user/resume/${user?._id}`, {
        method: "DELETE",
      });
      mutate();
      if (res.results.success) {
        await mutation(`user/percentage/${user?._id}`, {
          method: "DELETE",
        });
        mutate();
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: error.message,
        icon: "Error",
        confirmButtonText: "ok",
      });
    }
  };

  return (
    <Card>
      <UploadBox onClick={handleUploadClick}>
        <UploadIcon icon={faUpload} />
        {selectedFile ? (
          <FileName>{selectedFile.name}</FileName>
        ) : (
          <p>Click to upload your resume</p>
        )}
      </UploadBox>
      <FileInput type="file" ref={fileInputRef} onChange={handleFileChange} />
      {selectedFile && <Button onClick={handleSaveClick}>Save</Button>}

      <UploadedFileList>
        {uploadedFile && (
          <UploadedFileItem>
            <FileNameText>{uploadedFile.name || "Resume"}</FileNameText>
            <FileActions>
              <ActionButton
                onClick={() =>
                  handleDownloadClick(uploadedFile || user?.resume)
                }
              >
                <FontAwesomeIcon icon={faDownload} />
              </ActionButton>
              <ActionButton onClick={handleDeleteClick} isTrash>
                <FontAwesomeIcon icon={faTrashAlt} />
              </ActionButton>
            </FileActions>
          </UploadedFileItem>
        )}
      </UploadedFileList>
    </Card>
  );
};

export default ResumeUploadComponent;

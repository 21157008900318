import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import profileimage from "./demoprofile.jpeg";
import {
  faChartLine,
  faBriefcase,
  faTasks,
  faFileAlt,
  faUser,
  faSignOutAlt,
  faHome,
  faBars,
  faBell,
  faEdit,
  faUsers,
  faBuilding,
  faPen,
  faImage,
  faList,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import "./dashboard.css";
import "./adminprofile.css";
import Modal from "./changepasswordmodal";
import { useDispatch, useSelector } from "react-redux";
import { SignoutuserSuccess } from "../../../../redux/users/UserSlice";
import { useNavigate } from "react-router-dom";
import useMutation from "../../../../HOOKS/useMutation";
import Swal from "sweetalert2";
import useStore from "../../../../ZUSTAND";

function Adminprofile() {
  const { mutation } = useMutation();
  const { mutate } = useStore();

  const { currentUser } = useSelector((state) => state.user);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [profileImg, setProfileImg] = useState(profileimage);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    if (windowWidth < 768) setIsSidebarOpen(false);
    return () => window.removeEventListener("resize", handleResize);
  }, [windowWidth]);

  const toggleSidebar = () => {
    if (windowWidth <= 768) {
      setIsSidebarOpen(!isSidebarOpen);
    }
  };

  const onLogout = () => {
    dispatch(SignoutuserSuccess());
    localStorage.removeItem("token");
    navigate("/signin");
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setProfileImg(reader.result);
      };
      reader.readAsDataURL(file);
    }

    const formData = new FormData();
    formData.append("avatar", file);

    try {
      const res = await mutation(`user/updateinfo/${currentUser?._id}`, {
        method: "PUT",
        body: formData,
        isFormData: true,
        isAlert: true,
      });
      if (res?.results?.success) {
        mutate();
        Swal.fire({
          title: "Success",
          text: "",
          icon: "success",
          confirmButtonText: "ok",
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: error.message,
        icon: "Error",
        confirmButtonText: "ok",
      });
    }
  };

  const handleEditProfileImg = () => {
    document.getElementById("profileImgInput").click();
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const oldPassword = e.target.oldPassword.value;
    const newPassword = e.target.newPassword.value;
    const confirmPassword = e.target.confirmPassword.value;

    if (newPassword !== confirmPassword) {
      alert("New passwords do not match.");
      return;
    }

    try {
      const res = await mutation(`user/changepassword`, {
        method: "PUT",
        body: {
          oldpassword: oldPassword,
          newpassword: newPassword,
        },
      });
      if (res?.results?.success) {
        mutate();
        Swal.fire({
          title: "Success",
          text: "",
          icon: "success",
          confirmButtonText: "ok",
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: error.message,
        icon: "Error",
        confirmButtonText: "ok",
      });
    }

    // Close the modal after submission
    closeModal();
  };

  return (
    <div className="dashboard">
      {isSidebarOpen && (
        <div className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
          <ul>
            <li>
              <Link to="/dashboard" className="sidebar-item">
                <FontAwesomeIcon icon={faChartLine} />
                <span>Dashboard</span>
              </Link>
            </li>
            <li>
              <Link to="/jobpost" className="sidebar-item">
                <FontAwesomeIcon icon={faBriefcase} />
                <span>Manage Jobs Post</span>
              </Link>
            </li>
            <li>
              <Link to="/managejob" className="sidebar-item">
                <FontAwesomeIcon icon={faTasks} />
                <span>Manage Jobs</span>
              </Link>
            </li>
            <li>
              <Link to="/manageresume" className="sidebar-item">
                <FontAwesomeIcon icon={faFileAlt} />
                <span>Manage Resume</span>
              </Link>
            </li>
            <li>
              <Link to="/manage-company" className="sidebar-item">
                <FontAwesomeIcon icon={faBuilding} />
                <span>Manage Company</span>
              </Link>
            </li>
            <li>
              <Link to="/applicant" className="sidebar-item">
                <FontAwesomeIcon icon={faUsers} />
                <span>Applicants</span>
              </Link>
            </li>
            <li>
              <Link to="/addblog" className="sidebar-item">
                <FontAwesomeIcon icon={faPen} />
                <span>Add Blogs</span>
              </Link>
            </li>
            <li>
              <Link to="/bloglist" className="sidebar-item">
                <FontAwesomeIcon icon={faList} />
                <span>Blog List</span>
              </Link>
            </li>
            <li>
              <Link to="/banner-update" className="sidebar-item">
                <FontAwesomeIcon icon={faImage} />
                <span>Update Banner</span>
              </Link>
            </li>
            <li>
              <Link to="/faq" className="sidebar-item">
                <FontAwesomeIcon icon={faQuestionCircle} />
                <span>Faq</span>
              </Link>
            </li>
            <li>
              <Link to="/adminprofile" className="sidebar-item">
                <FontAwesomeIcon icon={faUser} />
                <span>My Profile</span>
              </Link>
            </li>
            <li>
              <div className="sidebar-item" onClick={onLogout}>
                <FontAwesomeIcon icon={faSignOutAlt} />
                <span>Logout</span>
              </div>
            </li>
          </ul>
        </div>
      )}

      <div className="postjob-main-content">
        <div className="profile-section">
          <div className="profile-image">
            <img
              src={profileImg ? profileImg : profileimage}
              alt="Profile Image"
              id="profileImg"
              style={{ width: "150px", height: "150px", borderRadius: "5%" }}
            />
            <input
              type="file"
              id="profileImgInput"
              style={{ display: "none" }}
              onChange={handleImageChange}
            />
            <FontAwesomeIcon
              icon={faEdit}
              className="edit-icon"
              onClick={handleEditProfileImg}
            />
          </div>
          <form
            className="profile-details"
            style={{ display: "flex", flexWrap: "wrap" }}
          >
            <div style={{ flexBasis: "50%" }}>
              <div>
                <label htmlFor="username">Username:</label>
                <input
                  type="text"
                  id="username"
                  name="username"
                  defaultValue={currentUser?.name || `Not Provided`}
                  readOnly
                />
              </div>
              <div>
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  defaultValue={currentUser?.email}
                  readOnly
                />
              </div>
            </div>
            <div style={{ flexBasis: "50%" }}>
              <div style={{ marginLeft: "10px" }}>
                <label htmlFor="phone">Contact Number:</label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  defaultValue={currentUser?.phoneNumber || `Not Provided`}
                  readOnly
                />
              </div>
              <div style={{ marginLeft: "10px" }}>
                <label htmlFor="country">Country:</label>
                <input
                  type="text"
                  id="country"
                  name="country"
                  defaultValue={currentUser?.country || `Not Provided`}
                  readOnly
                />
              </div>
            </div>
            <button
              type="button"
              className="change-password-btn"
              onClick={openModal}
            >
              Change Password
            </button>
          </form>
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        onSubmit={handleSubmit}
      />

      <div className="bottom-nav">
        <ul>
          <li>
            <FontAwesomeIcon icon={faHome} />
            <span>Home</span>
          </li>
          <li onClick={toggleSidebar}>
            <FontAwesomeIcon icon={faBars} />
            <span>Menu</span>
          </li>
          <li>
            <FontAwesomeIcon icon={faBell} />
            <span>Notifications</span>
          </li>
          <li>
            <FontAwesomeIcon icon={faUser} />
            <span>Profile</span>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Adminprofile;

import React, { useState, useRef } from "react";
import styled, { createGlobalStyle } from "styled-components";
import pf from "./pf.jpeg";
import {
  FaEdit,
  FaSave,
  FaCheck,
  FaCaretDown,
  FaPlus,
  FaTrash,
  FaTimes,
  FaUserEdit,
  FaPhone,
  FaEnvelope,
  FaUser,
} from "react-icons/fa";
import { MdEdit, MdEditNote } from "react-icons/md";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SkillsComponent from "./skill";
import ResumeUploadComponent from "./resumeupload";
import Address from "./applicantaddress";
import ProfileProgressCircle from "./progresscircle";
import { useSelector, useDispatch } from "react-redux";
import useMutation from "../../../HOOKS/useMutation";
import Swal from "sweetalert2";
import useStore from "../../../ZUSTAND";
const GlobalStyle = createGlobalStyle`
  body {
    background-color: rgb(255, 251, 251);
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column; /* Default to column for small screens */
  width: 95%;
  height: auto;
  margin: 8vh auto;

  @media (min-width: 768px) {
    flex-direction: row; /* Change to row for larger screens */
  }
`;

const ProfileSection = styled.div`
  position: relative;
  width: 100%; /* Full width for small screens */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #002365;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px; /* Add margin-bottom for spacing on small screens */

  @media (min-width: 768px) {
    width: 30%; /* 30% width for larger screens */
    margin-bottom: 0;
  }
`;

const InfoSection = styled.div`
  width: 100%; /* Full width for small screens */
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  @media (min-width: 768px) {
    width: 70%; /* 70% width for larger screens */
    margin-left: 20px;
  }
`;
const Card = styled.div`
  width: 100%;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: relative;
`;

const StyledH3 = styled.h3`
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

const BoldLabel = styled.span`
  font-weight: bold;
  color: #002365;
`;

const ProfileImageContainer = styled.div`
  position: relative;
`;

const ProfileImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 20px;
`;

const ProfileName = styled.h2`
  margin: 5px 0;
  color: white;
  display: flex;
`;
const ProfileContact = styled.h2`
  margin: 5px 0;
  color: white;
  display: flex;
`;

const ProfileEmail = styled.p`
  margin: 5px 0;
  color: white;
  display: flex;
`;

const AboutMe = styled.p`
  margin: 20px 0;
  padding: 10px;
  text-align: justify;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: #002365;
  font-weight: 500;
`;

const EditIcon = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 50%;
  padding: 5px;
`;

const ProfileDetails = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  width: 100%;
`;

const Input = styled.input`
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  width: calc(100% - 10px);
`;

const TextArea = styled.textarea`
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
  height: 30vh;
`;

const EditDetailsIcon = styled.div`
  position: absolute;
  top: 2px;
  right: 1px;
  cursor: pointer;
  font-size: 22px;
  color: white;
  border-radius: 50%;
  padding: 5px;
`;
const EditDetails = styled.div`
  position: absolute;
  bottom: 10px;
  right: 13px;
  cursor: pointer;

  color: black;
  border-radius: 50%;
  padding: 5px;
`;

const SaveIcon = styled.div`
  position: absolute;
  bottom: -35px;
  right: 10px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 50%;
  padding: 5px;
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
  margin: 10px 0 0 0;
`;

const DropdownIcon = styled.div`
  position: absolute;
  top: 1px;
  right: -9px;
  cursor: pointer;
  color: black;
  border-radius: 50%;
  padding: 5px;
  z-index: 2;
  font-size: 20px;
`;

// const CheckIcon = styled(FaCheck)`
//   cursor: pointer;
//   color: green;
//   font-size: 20px;
//   margin-left: 5px;
// `;

const ExperienceCard = styled.div`
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DeleteIcon = styled(FaTrash)`
  cursor: pointer;
  color: red;
  font-size: 20px;
  margin-left: 10px;
`;

const DateInput = styled(DatePicker)`
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
`;

const DateInputWrapper = styled.div`
  display: flex;
  gap: 15px;
`;
const ExperienceDetails = styled.div`
  flex: 1;
`;
const DeleteIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  margin-top: -40px;
`;
const IconsWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
`;
const CheckIcon = styled.div`
  cursor: pointer;
  background-color: green; // Set background color to green
  color: white;
  border-radius: 50%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CancelIcon = styled.div`
  cursor: pointer;
  background-color: red; // Set background color to red
  color: white;
  border-radius: 50%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1px;

  input {
    margin-right: 5px;
  }

  label {
    display: flex;
    align-items: center;
  }
`;
const Button = styled.button`
  padding: 5px 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
`;

const currentDate = new Date();

function ApplicantProfile() {
  const { mutation } = useMutation();
  const { mutate } = useStore();
  const { currentUser } = useSelector((state) => state.user);
  const [contactNo, setContactNo] = useState(
    currentUser?.phoneNumber || "Not Provided"
  );
  const [tempContact, setTempContact] = useState(
    currentUser?.phoneNumber || "Not Provided"
  );
  const [editProfileImage, setEditProfileImage] = useState(false);
  const [profileImage, setProfileImage] = useState(currentUser?.avatar || pf);
  const [name, setName] = useState(currentUser?.name || "Not Provided");

  const [email, setEmail] = useState(currentUser?.email || "Not Provided");
  const [aboutMe, setAboutMe] = useState(currentUser?.description || "");

  const [isEditing, setIsEditing] = useState(false);
  const [tempName, setTempName] = useState(name);
  const [tempEmail, setTempEmail] = useState(email);
  const [tempAboutMe, setTempAboutMe] = useState(aboutMe);
  const [errorMessage, setErrorMessage] = useState("");
  const [wordCountError, setWordCountError] = useState("");
  const [isEducationOpen, setIsEducationOpen] = useState(false);
  const [experiences, setExperiences] = useState([]);
  const [validationError, setValidationError] = useState("");
  const [isPresentChecked, setIsPresentChecked] = useState(false);
  const [skills, setSkills] = useState([]);
  const [newSkill, setNewSkill] = useState("");

  const [tempExperience, setTempExperience] = useState({
    company: "",
    designation: "",
    startDate: null,
    endDate: null,
    isPresent: false,
  });
  const handleCancelExperienceClick = () => {
    setIsAddingExperience(false);
    setTempExperience({
      company: "",
      designation: "",
      startDate: null,
      endDate: null,
    });
  };
  const [isAddingExperience, setIsAddingExperience] = useState(false);

  const [education, setEducation] = useState({
    institute: currentUser?.education?.institutename || "",
    qualification: currentUser?.education?.qualification || "",
    passoutYear: currentUser?.education?.passoutyear || "",
    percentage: currentUser?.education?.percentage || "",
  });
  const [isEditingEducation, setIsEditingEducation] = useState(false);
  const [tempEducation, setTempEducation] = useState({ ...education });

  const inputRef = useRef(null);

  const handleEditProfileImageClick = () => {
    inputRef.current.click();
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfileImage(URL.createObjectURL(file));
      setEditProfileImage(false);
    }

    const formData = new FormData();
    formData.append("avatar", file);

    try {
      const res = await mutation(`user/updateinfo/${currentUser?._id}`, {
        method: "PUT",
        body: formData,
        isFormData: true,
        isAlert: true,
      });
      if (res?.results?.success) {
        mutate();
        Swal.fire({
          title: "Success",
          text: "",
          icon: "success",
          confirmButtonText: "ok",
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: error.message,
        icon: "Error",
        confirmButtonText: "ok",
      });
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = async (e) => {
    e.preventDefault();
    if (!tempName || !tempEmail || !tempAboutMe) {
      setErrorMessage("All fields are mandatory!");
      return;
    }
    const wordCount = tempAboutMe.split(/\s+/).filter(Boolean).length;
    if (wordCount > 50) {
      setWordCountError("About Me section cannot exceed 50 words.");
      return;
    }
    setName(tempName);
    setContactNo(tempContact);
    setEmail(tempEmail);
    setAboutMe(tempAboutMe);
    setIsEditing(false);
    setErrorMessage("");

    //Integrate

    try {
      const res = await mutation(`user/updateinfo/${currentUser?._id}`, {
        method: "PUT",
        body: {
          name: tempName,
          email: tempEmail,
          phoneNumber: tempContact,
          description: tempAboutMe,
        },
      });
      if (res?.results?.success) {
        mutate();
        Swal.fire({
          title: "Success",
          text: "",
          icon: "success",
          confirmButtonText: "ok",
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: error.message,
        icon: "Error",
        confirmButtonText: "ok",
      });
    }
  };

  const handleAboutMeChange = (e) => {
    const value = e.target.value;
    const wordCount = value.split(/\s+/).filter(Boolean).length;
    if (wordCount <= 50) {
      setTempAboutMe(value);
      setWordCountError("");
    } else {
      setWordCountError("About Me section cannot exceed 50 words.");
    }
  };

  const handleAddExperienceClick = () => {
    setIsAddingExperience(true);
  };

  const handleSaveExperienceClick = async (e) => {
    const value = e.target.value;
    if (
      !tempExperience.company ||
      !tempExperience.designation ||
      !tempExperience.startDate
    ) {
      alert("Please fill out all experience fields.");
      return;
    }
    setExperiences([
      ...experiences,
      { ...tempExperience, isPresent: isPresentChecked },
    ]);
    setTempExperience({
      company: "",
      designation: "",
      startDate: null,
      endDate: null,
    });
    setIsPresentChecked(false);
    setIsAddingExperience(false);

    try {
      const res = await mutation(`applicantexperience/addexperience`, {
        method: "POST",
        body: {
          companyname: tempExperience?.company,
          startdate: tempExperience?.startDate,
          enddate: tempExperience?.endDate,
          designation: tempExperience?.designation,
        },
      });
      if (res?.results?.success) {
        mutate();
        Swal.fire({
          title: "Success",
          text: "",
          icon: "success",
          confirmButtonText: "ok",
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: error.message,
        icon: "Error",
        confirmButtonText: "ok",
      });
    }
  };

  const handleDeleteExperienceClick = async (index, id) => {
    const newExperiences = experiences.filter((_, i) => i !== index);
    setExperiences(newExperiences);
    try {
      const res = await mutation(`applicantexperience/deleteexperience/${id}`, {
        method: "DELETE",
      });
      if (res?.results?.success) {
        mutate();
        Swal.fire({
          title: "Success",
          text: "Deleted",
          icon: "success",
          confirmButtonText: "ok",
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: error.message,
        icon: "Error",
        confirmButtonText: "ok",
      });
    }
  };

  const handleToggleEducation = () => {
    setIsEducationOpen(!isEducationOpen);
  };

  const handleEditEducation = () => {
    setIsEditingEducation(true);
  };

  const handleSaveEducation = async (e) => {
    e.preventDefault();
    const { institute, qualification, passoutYear, percentage } = tempEducation;
    if (!institute || !qualification || !passoutYear || !percentage) {
      setValidationError("All fields are required.");
      return;
    }
    setValidationError("");
    setEducation(tempEducation);
    setIsEditingEducation(false);
    try {
      const res = await mutation(
        `applicanteducation/updateeducation/${currentUser?._id}`,
        {
          method: "PUT",
          body: {
            qualification,
            institutename: institute,
            // trade,
            passoutyear: passoutYear,
            percentage,
          },
        }
      );
      if (res?.results?.success) {
        mutate();
        Swal.fire({
          title: "Success",
          text: "",
          icon: "success",
          confirmButtonText: "ok",
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: error.message,
        icon: "Error",
        confirmButtonText: "ok",
      });
    }
  };
  const handleCancelEducation = () => {
    setIsEditingEducation(false);
    setTempEducation({
      institute: "",
      qualification: "",
      passoutYear: "",
      percentage: "",
    });
  };

  const handleAddSkill = () => {
    if (newSkill.trim() !== "") {
      setSkills([...skills, newSkill.trim()]);
      setNewSkill("");
    }
  };

  // console.log(experiences);
  return (
    <>
      <GlobalStyle />
      <Container>
        <ProfileSection>
          <ProfileImageContainer>
            <ProfileImage src={profileImage} alt="Profile" />
            <EditIcon onClick={handleEditProfileImageClick}>
              <FaEdit />
            </EditIcon>
            <input
              type="file"
              ref={inputRef}
              style={{ display: "none" }}
              onChange={handleImageChange}
            />
          </ProfileImageContainer>
          <ProfileDetails>
            {isEditing ? (
              <>
                <Input
                  type="text"
                  value={tempName}
                  onChange={(e) => setTempName(e.target.value)}
                />
                <Input
                  type="text"
                  placeholder="Contact No"
                  value={tempContact}
                  onChange={(e) => setTempContact(e.target.value)}
                />
                <Input
                  type="email"
                  value={tempEmail}
                  onChange={(e) => setTempEmail(e.target.value)}
                />
                <TextArea
                  value={tempAboutMe}
                  onChange={handleAboutMeChange}
                  placeholder="Brief about yourself"
                />
                {wordCountError && (
                  <ErrorMessage>{wordCountError}</ErrorMessage>
                )}
                <SaveIcon onClick={handleSaveClick}>
                  <FaSave />
                </SaveIcon>
              </>
            ) : (
              <>
                <ProfileName>
                  <FaUser
                    style={{
                      marginRight: "5px",
                      marginTop: "5px",
                      marginRight: "10px",
                    }}
                  />
                  {name}
                </ProfileName>
                <ProfileContact>
                  <FaPhone
                    style={{
                      marginRight: "5px",
                      marginTop: "5px",
                      marginRight: "10px",
                    }}
                  />
                  {contactNo}
                </ProfileContact>
                <ProfileEmail>
                  <FaEnvelope
                    style={{
                      marginRight: "5px",
                      marginTop: "5px",
                      marginRight: "10px",
                    }}
                  />
                  {email}
                </ProfileEmail>
                <AboutMe>{aboutMe}</AboutMe>
                <ProfileProgressCircle data={currentUser} />
                <EditDetailsIcon onClick={handleEditClick}>
                  <MdEditNote />
                </EditDetailsIcon>
              </>
            )}
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          </ProfileDetails>
        </ProfileSection>
        <InfoSection>
          <Card>
            <StyledH3>
              Highest Qualification
              <DropdownIcon onClick={handleToggleEducation}>
                {isEducationOpen ? <FaCaretDown /> : <FaCaretDown />}
              </DropdownIcon>
            </StyledH3>
            {isEducationOpen && (
              <>
                {isEditingEducation ? (
                  <>
                    <Input
                      type="text"
                      placeholder="Institute"
                      value={tempEducation.institute}
                      onChange={(e) =>
                        setTempEducation({
                          ...tempEducation,
                          institute: e.target.value,
                        })
                      }
                    />
                    <Input
                      type="text"
                      placeholder="Qualification"
                      value={tempEducation.qualification}
                      onChange={(e) =>
                        setTempEducation({
                          ...tempEducation,
                          qualification: e.target.value,
                        })
                      }
                    />
                    <Input
                      type="text"
                      placeholder="Passout Year"
                      value={tempEducation.passoutYear}
                      onChange={(e) =>
                        setTempEducation({
                          ...tempEducation,
                          passoutYear: e.target.value,
                        })
                      }
                    />
                    <Input
                      type="text"
                      placeholder="Percentage"
                      value={tempEducation.percentage}
                      onChange={(e) =>
                        setTempEducation({
                          ...tempEducation,
                          percentage: e.target.value,
                        })
                      }
                    />
                    {validationError && (
                      <ErrorMessage>{validationError}</ErrorMessage>
                    )}
                    <IconsWrapper>
                      <CheckIcon onClick={handleSaveEducation}>
                        <FaCheck />
                      </CheckIcon>
                      <CancelIcon onClick={handleCancelEducation}>
                        <FaTimes />
                      </CancelIcon>
                    </IconsWrapper>
                  </>
                ) : (
                  <>
                    <p>
                      <BoldLabel>Institute:</BoldLabel> {education.institute}
                    </p>
                    <p>
                      <BoldLabel>Qualification:</BoldLabel>{" "}
                      {education.qualification}
                    </p>
                    <p>
                      <BoldLabel>Passout Year:</BoldLabel>{" "}
                      {education.passoutYear}
                    </p>
                    <p>
                      <BoldLabel>Percentage:</BoldLabel> {education.percentage}
                    </p>
                    <EditDetails onClick={handleEditEducation}>
                      <MdEdit />
                    </EditDetails>
                  </>
                )}
              </>
            )}
          </Card>

          <Card>
            <StyledH3>
              Experience
              <FaPlus
                onClick={handleAddExperienceClick}
                style={{ cursor: "pointer", right: 0 }}
              />
            </StyledH3>
            {isAddingExperience && (
              <>
                <Input
                  type="text"
                  placeholder="Company"
                  value={tempExperience.company}
                  onChange={(e) =>
                    setTempExperience({
                      ...tempExperience,
                      company: e.target.value,
                    })
                  }
                />
                <Input
                  type="text"
                  placeholder="Designation"
                  value={tempExperience.designation}
                  onChange={(e) =>
                    setTempExperience({
                      ...tempExperience,
                      designation: e.target.value,
                    })
                  }
                />
                <DateInputWrapper>
                  <DateInput
                    selected={tempExperience.startDate}
                    onChange={(date) =>
                      setTempExperience({
                        ...tempExperience,
                        startDate: new Date(date).toISOString(),
                      })
                    }
                    placeholderText="Start Date"
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    maxDate={new Date()}
                  />
                  <DateInput
                    selected={isPresentChecked ? "" : tempExperience.endDate}
                    onChange={(date) =>
                      setTempExperience({
                        ...tempExperience,
                        endDate: new Date(date).toISOString(),
                      })
                    }
                    placeholderText="End Date"
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    maxDate={new Date()}
                    disabled={isPresentChecked}
                  />
                  <CheckboxWrapper>
                    <label>
                      <input
                        type="checkbox"
                        checked={isPresentChecked}
                        onChange={(e) => {
                          setIsPresentChecked(e.target.checked);
                          setTempExperience({
                            ...tempExperience,
                            endDate: e.target.checked
                              ? new Date().toISOString()
                              : tempExperience.endDate,
                          });
                        }}
                      />
                      Currently Working Here
                    </label>
                  </CheckboxWrapper>
                </DateInputWrapper>
                <IconsWrapper>
                  <CheckIcon onClick={handleSaveExperienceClick}>
                    <FaCheck />
                  </CheckIcon>
                  <CancelIcon onClick={handleCancelExperienceClick}>
                    <FaTimes />
                  </CancelIcon>
                </IconsWrapper>
              </>
            )}
            {currentUser?.experience?.map((exp, index) => (
              <ExperienceCard key={index}>
                <div>
                  <p>
                    <BoldLabel>Company:</BoldLabel> {exp.companyname}
                  </p>
                  <p>
                    <BoldLabel>Designation:</BoldLabel> {exp.designation}
                  </p>
                  <p>
                    <BoldLabel>Start Date:</BoldLabel>{" "}
                    {exp.startdate
                      ? new Date(exp.startdate).toLocaleDateString("en-US", {
                          month: "long",
                          year: "numeric",
                        })
                      : "N/A"}
                  </p>
                  <p>
                    <BoldLabel>End Date:</BoldLabel>{" "}
                    {checkEmploymentStatus(exp)}
                  </p>
                </div>
                <DeleteIconWrapper>
                  <DeleteIcon
                    onClick={() => handleDeleteExperienceClick(index, exp._id)}
                  />
                </DeleteIconWrapper>
              </ExperienceCard>
            ))}
          </Card>

          <Card>
            <SkillsComponent user={currentUser} />
          </Card>
          <Card>
            <ResumeUploadComponent userResume={currentUser} />
          </Card>
          <Card>
            <Address userAddress={currentUser} />
          </Card>
        </InfoSection>
      </Container>
    </>
  );
}

export default ApplicantProfile;

function checkEmploymentStatus(exp) {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  const endDate = new Date(exp.enddate);
  const endMonth = endDate.getMonth();
  const endYear = endDate.getFullYear();

  let currentlyWorkHere = currentMonth === endMonth && currentYear === endYear;
  return currentlyWorkHere
    ? "Currently Work Here"
    : new Date(exp.enddate).toLocaleDateString("en-US", {
        month: "long",
        year: "numeric",
      });
}

import React from "react";
// import Hero from "../hero/Hero.jsx";
import Hero from "../hero/hero_four.jsx";
import Marketplace from "../marketplace/marketplace.jsx";
import Offer from "../offer/offer.jsx";
import Location from "../location/location.jsx";
import Category from "../category/category.jsx";
import Feature from "../features/Feature.jsx";
import Company from "../company/company.jsx";
import useSwr from "../../HOOKS/useSWR";
export default function Home() {
  const { data, isValidating } = useSwr(`jobcategory/getallcategory`);
  const category = data?.data?.data;
  return (
    <>
      <Hero />
      <Category categoryData={category} isValidating={isValidating} />
      <Company />
      <Feature />
      <Offer />
      <Marketplace />
      <Location />
    </>
  );
}

import React from "react";
import "./Footer.css";
import "@fortawesome/fontawesome-free/css/all.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import useSwr from "../../HOOKS/useSWR";
function Footer() {
  const { data, isValidating } = useSwr(`jobcategory/getallcategory`);

  const { data: jobsCountry, isValidating: loading } =
    useSwr("jobpost/getjobpost");

  const countries = [
    ...new Set(
      jobsCountry?.data?.data?.data.map((el) => el?.location?.country)
    ),
  ].splice(0, 12);
  const industries = data?.data?.data;

  return (
    <>
      <div className="footer-container">
        <div className="footer-column">
          <h3>Useful Links</h3>

          <ul className="footer-links">
            <li>
              <FontAwesomeIcon icon={faCaretRight} />{" "}
              <Link to="/about-us">About Us</Link>
            </li>
            <li>
              <FontAwesomeIcon icon={faCaretRight} />{" "}
              <Link to="/contact">Contact Us</Link>
            </li>
            <li>
              <FontAwesomeIcon icon={faCaretRight} />{" "}
              <Link to="/privacy-policies">Privacy Policy</Link>
            </li>
            <li>
              <FontAwesomeIcon icon={faCaretRight} />{" "}
              <Link to="/fair-use-policies">Fair Use Policy</Link>
            </li>
            <li>
              <FontAwesomeIcon icon={faCaretRight} />{" "}
              <Link to="/anti-spam-policies">Anti-Spam Policy</Link>
            </li>
            <li>
              <FontAwesomeIcon icon={faCaretRight} />{" "}
              <Link to="/terms-condition">Terms & Conditions</Link>
            </li>
            <li>
              <FontAwesomeIcon icon={faCaretRight} />{" "}
              <Link to="/accessibility">Accessibility</Link>
            </li>
            <li>
              <FontAwesomeIcon icon={faCaretRight} />{" "}
              <Link to="/acceptable-usage-policies">
                Acceptable Usage Policy
              </Link>
            </li>
            <li>
              <FontAwesomeIcon icon={faCaretRight} />{" "}
              <Link to="/misconduct-reporting">Misconduct Reporting</Link>
            </li>
            <li>
              <FontAwesomeIcon icon={faCaretRight} />{" "}
              <Link to="/mis-use-brand">Misuse of Brand</Link>
            </li>
            <li>
              <FontAwesomeIcon icon={faCaretRight} />{" "}
              <Link to="#">Applicant Login</Link>
            </li>
          </ul>
        </div>
        <div className="footer-column">
          <h3>Jobs by Country</h3>
          <ul className="footer-links">
            {loading ? (
              <p>Loading...</p>
            ) : (
              countries?.map((country) => (
                <li key={country}>
                  <FontAwesomeIcon icon={faCaretRight} />{" "}
                  <Link to={`/jobfilter?locn=${country}`}>
                    Jobs in {country}
                  </Link>
                </li>
              ))
            )}
          </ul>
        </div>
        <div className="footer-column">
          <h3>Jobs by Industry</h3>
          <ul className="footer-links">
            {isValidating ? (
              <p>Loading...</p>
            ) : (
              industries?.map((industry) => (
                <li key={industry._id}>
                  <FontAwesomeIcon icon={faCaretRight} />{" "}
                  <Link to={`/jobfilter?industryName=${industry._id}`}>
                    {industry.industryName}
                  </Link>
                </li>
              ))
            )}
          </ul>
        </div>
        <div className="footer-containerr">
          <div className="footer-columnn">
            <h4>Customer Care</h4>
            <ul className="footer-linkss">
              <li>+971-564041021</li>
            </ul>
            <div className="fimg">
              <img
                className="footerimg"
                src="./img/logo-footer.png"
                alt="Footer Image"
              />
            </div>
          </div>

          <div className="footer-columnn">
            <h4 className="sup">Need Support?</h4>
            <ul className="footer-linkss">
              <li className="sup">hello@talentfolder.com</li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <div className="copyright">
          <div className="copyright-text">
            <hr className="bar1" />
            &copy; 2024 Talentfolder. All rights reserved.
          </div>
          <div className="social-icons">
            <a
              href="https://www.facebook.com/profile.php?id=61555659095681"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-facebook-f fb-facebook"></i>
            </a>
            {/* Twitter and Instagram icons */}
            <a
              href="https://x.com/talent_folder"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-twitter fb-twt"></i>
            </a>
            <a
              href="https://www.instagram.com/talentfolder_livoso/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-instagram fb-insta"></i>
            </a>
            {/* Add more social icons with links as needed */}
            <a
              href="https://www.linkedin.com/company/talentfolderglobal/?viewAsMember=true"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-linkedin-in fb-linkedin"></i>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;

import React from 'react'
import Dashboard from './dashboard/dashboard'

function Main() {
  return (
    <div>
      <Dashboard />
    </div>
  )
}

export default Main;

import React from "react";
import "./blogdetails.css";
import section1 from "./section1.jpg";
import { useParams } from "react-router-dom";
import useSwr from "../../HOOKS/useSWR";
import moment from "moment/moment";
function BlogDetails() {
  const { id } = useParams();
  const { data: blogDetails } = useSwr(`blog?blogId=${id}`);
  const blog = blogDetails?.data?.data?.data[0];
  return (
    <div className="blog-details">
      <header className="blog-header">
        <h1 className="blog-title">{blog?.title || ""}</h1>
        <p className="blog-date">
          Published on {moment(blog?.createdAt).format("MMMM Do YYYY")}
        </p>
      </header>
      <div className="blog-content-container">
        <article className="blog-content">
          <div dangerouslySetInnerHTML={{ __html: blog?.content }}></div>
        </article>
        <div className="blog-image-container">
          <img src={blog?.image} alt="Blog Post" className="blog-image" />
        </div>
      </div>
      <section className="blog-highlight-section">
        <blockquote className="blog-quote">
          {blog?.blogQuoteHeading || ""}
        </blockquote>
        <p className="blog-highlight-content">{blog?.blogQuoteContent}</p>
      </section>
      <section className="blog-section-2">
        <div className="blog-section-2-content">
          <div className="blog-section-2-item">
            <img
              src={blog?.imageOne}
              alt="Section 3 Image"
              className="blog-section-2-image"
            />
            <div className="blog-section-2-text">
              <h3>{blog?.image1Heading || ""}</h3>
              <p>{blog?.image1Content || ""}</p>
            </div>
          </div>
          <div className="blog-section-2-item">
            <img
              src={blog?.imageTwo}
              alt="Section 4 Image"
              className="blog-section-2-image"
            />
            <div className="blog-section-2-text">
              <h3>{blog?.image2Heading || ""}</h3>
              <p>{blog?.image2Content || ""}</p>
            </div>
          </div>
          <div className="blog-section-2-item">
            <img
              src={blog?.imageThree}
              alt="Section 5 Image"
              className="blog-section-2-image"
            />
            <div className="blog-section-2-text">
              <h3>{blog?.image3Heading || ""}</h3>
              <p>{blog?.image3Content || ""}</p>
            </div>
          </div>
          <div className="blog-section-2-item">
            <img
              src={blog?.imageFour}
              alt="Section 6 Image"
              className="blog-section-2-image"
            />
            <div className="blog-section-2-text">
              <h3>{blog?.image4Heading || ""}</h3>
              <p>{blog?.image4Content || ""}</p>
            </div>
          </div>
        </div> 
      </section>
      <section className="blog-section-3">
        <div className="blog-section-3-content">
          <img
            src={blog?.lastSectionImageData}
            alt="Section 2 Image"
            className="blog-section-3-image"
          />
          <div className="blog-section-3-text">
            <h2>{blog?.lastSectionHeading || ""}</h2>
            <p>{blog?.lastSectionContent || ""}</p>
          </div>
        </div>
      </section> 
    </div>
  );
}

export default BlogDetails;

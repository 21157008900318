import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

export default function Feature() {
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      // console.log(scrollTop, "vvv");
      const triggerPoint = 1400;

      if (scrollTop > triggerPoint) {
        setScroll(true);
      }
    };

    // Add scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Remove scroll event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <section className="bg-category p-5">
      <div className="flex flex-col justify-center items-center px-9 py-10 gap-7">
        <AnimatePresence>
          {scroll && (
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: -20 }}
              exit={{ opacity: 0, y: 50 }}
              transition={{ duration: 1 }}
              className="flex flex-col justify-center items-center gap-4"
            >
              <h2 className="text-[25px] lg:text-4xl font-semibold text-center">
              Why The Best Talents Choose TalentFolder?
              </h2>
              <p className="text-slate-700 text-center">
                All the features of Talentfolder below
              </p>
            </motion.div>
          )}
        </AnimatePresence>
        <div className="flex gap-8 flex-col w-full lg:flex-row">
          <div className="bg-white border border-slate-300 w-full lg:w-1/4 h-45 rounded-md py-4">
            <div className="flex flex-col p-4 ">
              <div className="  bg-category w-16 h-16 rounded-full flex justify-center items-center">
                <img src="/img/icon/great1.svg" className="" alt="Img" />
              </div>
              <div className="">
                <h3 className="text-xl font-bold">Career Development</h3>
                <p className="text-slate-500">
                  Expert career counseling and a personalized approach to
                  skilling and talent development.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-white border border-slate-300 w-full lg:w-1/4 h-45 rounded-md py-4">
            <div className="lex flex-col p-4 ">
              <div className=" bg-category w-16 h-16 rounded-full flex justify-center items-center">
                <img
                  src="/img/icon/great2.svg"
                  className="img-fluid"
                  alt="Img"
                />
              </div>
              <div className="feature-content course-count">
                <h3 className="text-xl font-bold">International Careers</h3>
                <p className="text-slate-500">
                  Opportunity to work with some of the biggest Indian and global
                  brands.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-white border border-slate-300 w-full lg:w-1/4 h-45 rounded-md py-4">
            <div className="lex flex-col p-4  ">
              <div className=" bg-category w-16 h-16 rounded-full flex justify-center items-center">
                <img src="/img/icon/great3.svg" className="" alt="Img" />
              </div>
              <div className="feature-content course-count">
                <h3 className="text-xl font-bold">Guaranteed Privacy</h3>
                <p className="text-slate-500">
                We are committed to protecting your privacy and respecting your interests.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-white border border-slate-300 w-full lg:w-1/4 h-45 rounded-md py-4">
            <div className="lex flex-col p-4  ">
              <div className=" bg-category w-16 h-16 rounded-full flex justify-center items-center">
                <img
                  src="/img/icon/great4.svg"
                  className="img-fluid"
                  alt="Img"
                />
              </div>
              <div className="feature-content course-count">
                <h3 className="text-xl font-bold">Statutory Benifits</h3>
                <p className="text-slate-500">
                  ESIC, Insurance, Workers' Compensation, Employees Provident
                  Fund.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

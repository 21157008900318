import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  faChartLine,
  faBriefcase,
  faTasks,
  faFileAlt,
  faStar,
  faUser,
  faSignOutAlt,
  faHome,
  faBars,
  faBell,
  faUsers,
  faBuilding,
  faPen,
  faImage,
  faList,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import "./dashboard.css";
import "./postjob.css";
import { useDispatch } from "react-redux";
import { SignoutuserSuccess } from "../../../../redux/users/UserSlice";
import useMutation from "../../../../HOOKS/useMutation";

import { IoCloudUploadOutline } from "react-icons/io5";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

function AddBanner() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [imagePreview, setImagePreview] = useState(null);

  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const { mutation, isLoading } = useMutation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toggleSidebar = () => {
    if (windowWidth <= 768) {
      setIsSidebarOpen(!isSidebarOpen);
    }
  };

  const onLogout = () => {
    dispatch(SignoutuserSuccess());
    localStorage.removeItem("token");
    navigate("/signin");
  };

  const formik = useFormik({
    initialValues: {
      companyLogo: "",
    },
    onSubmit: async (values, props) => {
      const formData = new FormData();

      setLoading(true);

      if (image) {
        formData.append("eventimage", image);
      }

      try {
        const res = await mutation(`events/addevents`, {
          method: "POST",
          body: formData,
          isFormData: true,
          isAlert: true,
        });
        if (res?.results?.success) {
          setLoading(false);
          Swal.fire({
            title: "Success",
            text: "",
            icon: "success",
            confirmButtonText: "ok",
          });
          props?.resetForm();
          setImagePreview(null);
        }
      } catch (error) {
        console.error("Error:", error);
        Swal.fire({
          title: "Error",
          text: error.message,
          icon: "error",
          confirmButtonText: "ok",
        });
      }
    },
  });

  const handleFileChange = (event) => {
    setImage(event.target.files ? event.target.files[0] : null);
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        formik.setFieldValue("companyLogo", file);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="dashboard">
      {isSidebarOpen && (
        <div className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
          <ul>
            <li>
              <Link to="/dashboard" className="sidebar-item">
                <FontAwesomeIcon icon={faChartLine} />
                <span>Dashboard</span>
              </Link>
            </li>
            <li>
              <Link to="/jobpost" className="sidebar-item">
                <FontAwesomeIcon icon={faBriefcase} />
                <span>Manage Jobs Post</span>
              </Link>
            </li>
            <li>
              <Link to="/managejob" className="sidebar-item">
                <FontAwesomeIcon icon={faTasks} />
                <span>Manage Jobs</span>
              </Link>
            </li>
            <li>
              <Link to="/manageresume" className="sidebar-item">
                <FontAwesomeIcon icon={faFileAlt} />
                <span>Manage Resume</span>
              </Link>
            </li>
            <li>
              <Link to="/manage-company" className="sidebar-item">
                <FontAwesomeIcon icon={faBuilding} />
                <span>Manage Company</span>
              </Link>
            </li>
            <li>
              <Link to="/applicant" className="sidebar-item">
                <FontAwesomeIcon icon={faUsers} />
                <span>Applicants</span>
              </Link>
            </li>
            <li>
              <Link to="/addblog" className="sidebar-item">
                <FontAwesomeIcon icon={faPen} />
                <span>Add Blogs</span>
              </Link>
            </li>
            <li>
              <Link to="/bloglist" className="sidebar-item">
                <FontAwesomeIcon icon={faList} />
                <span>Blog List</span>
              </Link>
            </li>
            <li>
              <Link to="/banner-update" className="sidebar-item">
                <FontAwesomeIcon icon={faImage} />
                <span>Update Banner</span>
              </Link>
            </li>
            <li>
              <Link to="/faq" className="sidebar-item">
                <FontAwesomeIcon icon={faQuestionCircle} />
                <span>Faq</span>
              </Link>
            </li>
            <li>
              <Link to="/adminprofile" className="sidebar-item">
                <FontAwesomeIcon icon={faUser} />
                <span>My Profile</span>
              </Link>
            </li>
            <li>
              <div className="sidebar-item" onClick={onLogout}>
                <FontAwesomeIcon icon={faSignOutAlt} />
                <span>Logout</span>
              </div>
            </li>
          </ul>
        </div>
      )}
      <div className="postjob-main-content">
        <form className="job-posting-form" onSubmit={formik.handleSubmit}>
          <h2>Update Banner</h2>

          <div className="">
            <label htmlFor="company-logo">Banner Image</label>
            <div className="flex flex-col justify-center items-center h-[200px] border border-slate-400 rounded-md">
              <input
                type="file"
                id="company-logo"
                name="companyLogo"
                accept="image/*"
                onChange={handleFileChange}
                className="!hidden"
              />
              <label
                htmlFor="company-logo"
                className="cursor-pointer flex flex-col items-center"
              >
                {imagePreview ? (
                  <img
                    src={imagePreview}
                    alt="Company Logo"
                    className="h-32 w-56 object-cover rounded-md"
                  />
                ) : (
                  <IoCloudUploadOutline className="h-20 w-20 text-gray-500 hover:text-gray-700" />
                )}
              </label>
              {imagePreview && (
                <button type="button" onClick={() => setImagePreview(null)}>
                  Delete
                </button>
              )}
            </div>
          </div>

          <div className="form-group">
            <button type="submit">{loading ? "...Loading " : "save"}</button>
          </div>
        </form>
      </div>
      <div className="bottom-nav">
        <ul>
          <li>
            <FontAwesomeIcon icon={faHome} />
            <span>Home</span>
          </li>
          <li onClick={toggleSidebar}>
            <FontAwesomeIcon icon={faBars} />
            <span>Menu</span>
          </li>
          <li>
            <FontAwesomeIcon icon={faBell} />
            <span>Notifications</span>
          </li>
          <li>
            <FontAwesomeIcon icon={faUser} />
            <span>Profile</span>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default AddBanner;

import React from "react";
import "./about.css";
import { Link } from "react-router-dom";
import aboutimg from "./section1.jpg";
import reg from "./reg.png";
import update from "./update.png";
import search from "./search.png";
import apply from "./apply.png";
import bgImage from "./calltoaction.jpg";
import why from "./preview.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import recrutment from "./img/recruitment.jpg";
import payroll from "./img/payroll.jpg";
import staffing from "./img/staffing.jpg";
import workforce from "./img/work-management.jpg";
import Training from "./img/training.jpg";
import manageservice from "./img/standard-quality-control-concept-m.jpg";
import Career from "./img/career.jpg";
import Executive from "./img/Executive.jpg";
import Compensation from "./img/benefits.jpg";
import Benefits from "./img/manageservice.jpg";
import Rewards from "./img/reward.jpg";
import HR from "./img/hr.jpg";
import Slider from "react-slick";

const PrevArrow = (props) => (
  <div className="about-prev-arrow" onClick={props.onClick}>
    &#9664;
  </div>
);

const NextArrow = (props) => (
  <div className="about-next-arrow" onClick={props.onClick}>
    &#9654;
  </div>
);

function About() {
  const services = [
    {
      heading: "Recruitment",
      content:
        "Leverage technology and data to identify top talent across industries. Efficient and targeted recruitment solutions for diverse roles.",
      image: recrutment,
    },
    {
      heading: "Payroll Management",
      content:
        "Expertise in India’s labor laws ensures accurate and timely payroll. Reduces administrative burdens with precise processing.",
      image: payroll,
    },
    {
      heading: "Staffing Solutions",
      content:
        "Comprehensive staffing for temporary and permanent needs. Extensive network of associates to meet diverse staffing requirements.",
      image: staffing,
    },
    {
      heading: "Workforce Management",
      content:
        "Optimize workforce and enhance productivity with data-driven insights. Foster positive work culture and good management strategies.",
      image: workforce,
    },
    {
      heading: "Training and Development",
      content:
        "Customized training programs to up-skill talent. Prepare your workforce for future challenges with tailored development solutions.",
      image: Training,
    },
    {
      heading: "Managed Services",
      content:
        "Integrated talent acquisition approach. Efficient and cost-effective workforce management through Managed Services Program.",
      image: manageservice,
    },
    {
      heading: "Career Transition Services",
      content:
        "Support for career transitions with outplacement services and coaching. Upskill programs to aid organizational & individual growth.",
      image: Career,
    },
    {
      heading: "Executive Search",
      content:
        "Specializes in executive search for leadership roles. Extensive network and expertise to attract top-tier talent for critical positions.",
      image: Executive,
    },
    {
      heading: "Compensation and Benefits",
      content:
        "Design competitive compensation and benefits packages. Attract and retain top talent with well-structured reward strategies.",
      image: Compensation,
    },
    {
      heading: "Employee Benefits",
      content:
        "Design and administer employee benefits programs. Includes retirement, health, and wellness plans to support talent retention.",
      image: Benefits,
    },
    {
      heading: "Rewards & Compensation",
      content:
        "Expertise in designing compensation and rewards strategies. Aligns with business objectives to drive employee performance.",
      image: Rewards,
    },
    {
      heading: "HR Technology Solutions",
      content:
        "Innovative HR technology solutions including cloud-based platforms. Streamline HR processes and enable data-driven decision-making.",
      image: HR,
    },
  ];
  const headingText = "About Us";

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="about-container">
        <h1>
          {headingText.split("").map((char, index) => (
            <span
              key={index}
              className={char === " " ? "space" : ""}
              style={{ animationDelay: `${index * 0.1}s` }}
            >
              {char}
            </span>
          ))}
        </h1>
      </div>
      <div className="about-content-section">
        <img src={aboutimg} alt="About Us" className="about-content-image" />
        <div className="about-content-text">
          <h2>About Talent Folder</h2>
          <p>
            Talent Folder is widely regarded as one of the top HR firms in UAE &
            Singapore, renowned for its comprehensive suite of HR services and
            solutions. Established in 2013, this company has consistently been
            at the forefront of the Asian HR industry, catering to the diverse
            needs of businesses across various sectors. Our extensive clientele
            includes enterprise organizations across 4+ countries worldwide.Our
            story is one driven by passion &amp; dedication. We&#39;ve overcome
            challenges, advanced our expertise in our industry, and maintained a
            happy client; but that&#39;s just the tip of the iceberg! Our
            history is one we&#39;re proud to share today, a history filled with
            success stories, achievements and moments of pure joy! We Create. We
            Deliver. We Innovate. Discover more about Talent Folder &#39;s
            founding, and some of our key milestones and achievements through
            the years, below.
          </p>
          <Link to="/careeradvice" className="about-blog-link">
            <button className="about-blog-button">
              Check Our Latest Blog <i className="fas fa-arrow-right"></i>
            </button>
          </Link>
        </div>
      </div>
      <div className="about-image-grid-heading">
        <h2>How It Works</h2>
        <p>Giving your dream a reason to live, by making it a reality!</p>
        <div className="image-grid">
          <div className="grid-item">
            <img src={reg} alt="Image 1" className="about-grid-image" />
            <h3>Register Account</h3>
            <p>Sign up now for more job oppertunity</p>
          </div>
          <div className="grid-item">
            <img src={update} alt="Image 2" className="about-grid-image" />
            <h3>Update Profile</h3>
            <p>Update your details for personalized experience.</p>
          </div>
          <div className="grid-item">
            <img src={search} alt="Image 3" className="about-grid-image" />
            <h3>Search dream Job</h3>
            <p>Find your dream job opportunity now.</p>
          </div>
          <div className="grid-item">
            <img src={apply} alt="Image 4" className="about-grid-image" />
            <h3>Apply For Job</h3>
            <p>Submit application for your dream role.</p>
          </div>
        </div>
      </div>
      <div
        className="about-section2"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <div className="about-section2-content">
          <h2>Your Dream Jobs Are Waiting</h2>
          <p>
            Over 1 million interactions, 50,000 success stories Make yours now.
          </p>
          <div className="about-section2-buttons">
            <Link
              to="/jobsearch"
              className="about-section2-button about-read-more-button"
            >
              Search Jobs
            </Link>
            <Link
              to="/jobfilter"
              className="about-section2-button about-contact-us-button"
            >
              Apply Job Now
            </Link>
          </div>
        </div>
      </div>
      <div className="about-image-grid-heading">
        <h2>Our Services</h2>
        <p>
          Transforming ideas into reality with innovative, top-quality services.
        </p>
      </div>
      <div className="service-slider">
        <Slider {...settings}>
          {services.map((service, index) => (
            <div className="service-box" key={index}>
              <img
                src={service.image}
                alt={service.heading}
                className="service-image"
              />
              <h3>{service.heading}</h3>
              <p>{service.content}</p>
            </div>
          ))}
        </Slider>
      </div>
      <div className="about-image-grid-heading">
        <h2>Why Choose us</h2>
      </div>
      <div className="about-content-with-image">
        <div className="about-content-left">
          <h3>Trusted Technology Partner</h3>
          <p>
            When you choose us, you’re selecting a dependable technology partner
            and services ally, dedicated to supporting you at every step of your
            business journey.
          </p>
          <h3>Fostering a Brighter Tomorrow</h3>
          <p>
            Our mission is to create a better future by fostering innovation,
            collaboration and the sharing of knowledge. We believe that by
            harnessing the power of collective wisdom and embracing
            groundbreaking ideas, we can build a brighter tomorrow.
          </p>
          <h3>Talent Folder At A Glance</h3>
          <p>
            Our rich design and technology expertise delivers top brands and
            digital experiences.
          </p>
          <h3>With you, For you</h3>
          <p>
            Our commitment goes beyond delivering exceptional products and
            services. It&#39;s about building a lasting partnership that extends
            far into the future. Together, We Thrive!
          </p>
        </div>
        <div className="about-image-right">
          <img src={why} alt="Image Description" />
        </div>
      </div>
    </>
  );
}

export default About;

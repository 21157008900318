import React from "react";
import styled from "styled-components";
import backgroundImage from "./antispam.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const PageWrapper = styled.div`
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const PrivacyContainer = styled.div`
  padding: 40px 20px;
  max-width: 900px;
  margin: 40px auto;
  background: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-family: "Roboto", sans-serif;
  color: #333;

  @media (max-width: 768px) {
    padding: 20px 15px;
    margin: 20px auto;
  }
`;

const PrivacyHeader = styled.h1`
  font-size: 2.5em;
  text-align: center;
  color: #2c3e50;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    font-size: 2em;
    margin-bottom: 20px;
  }
`;

const PrivacySection = styled.section`
  margin-bottom: 30px;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const SectionTitle = styled.h2`
  font-size: 1.8em;
  color: #34495e;
  margin-bottom: 15px;
  position: relative;
  padding-bottom: 10px;

  &::after {
    content: "";
    width: 50px;
    height: 3px;
    background: #fe4324;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  @media (max-width: 768px) {
    font-size: 1.5em;
    margin-bottom: 10px;
    padding-bottom: 5px;

    &::after {
      width: 40px;
      height: 2px;
    }
  }
`;

const SectionContent = styled.p`
  color: #7f8c8d;
  font-size: 1em;
  line-height: 1.8;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 0.9em;
  }
`;

const TickMark = styled(FontAwesomeIcon)`
  color: #dc143c;
  margin-right: 10px;
  margin-top: 6px;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start;

  @media (max-width: 768px) {
    font-size: 0.9em;
  }
`;

function Antispam() {
  return (
    <>
      <PageWrapper>
        <PrivacyContainer>
          <PrivacyHeader>Anti-Spam Policy</PrivacyHeader>
          <PrivacySection>
            <SectionTitle>Anti Spam Policies</SectionTitle>
            <SectionContent>
              <ul>
                <ListItem>
                  <TickMark icon={faCheck} />
                  <span>
                    The Anti-Spam Policy applies to Services involving
                    electronic messaging provided directly by Talent Folder LLC
                    (“TALENT FOLDER” or “Company”) or indirectly through the
                    Customer’s use of system or software provided for use by
                    TALENT FOLDER. By using our services customers/clients/users
                    agree to comply with our Anti-Spam Policy.
                  </span>
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  <span>
                    In the context of electronic messaging, “spam” also known as
                    junk email means unsolicited commercial email messages,
                    especially (but not only) bulk email messages. We have a
                    zero- tolerance spam policy.
                  </span>
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  <span>
                    A customer/client/user must not use our messaging facility
                    or any of our other Services to store, copy, send, relay or
                    distribute spam.
                  </span>
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  <span>
                    A customer/client/user warrants through use of the Services
                    that, in relation to any electronic message sent, the
                    intended recipient has given the express or clearly implied
                    permission to receive an electronic message including one
                    containing the subject matter of the electronic message
                    being sent.
                  </span>
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  <span>
                    A customer/client/user agrees and warrants through use of
                    the Services that they have taken all reasonable steps to
                    obtain the express permission of the intended recipient and
                    have their consent to receive an electronic message
                    including one containing the subject matter of the
                    electronic message being sent.
                  </span>
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  <span>
                    A customer/client/user shall ensure that the routing and
                    header information including the emails “From” and “To,” the
                    originating domain name and email address are true and
                    accurate.
                  </span>
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  <span>
                    A customer/client/user shall not use subject lines that
                    mislead the recipient about the contents or subject matter
                    of the message.
                  </span>
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  <span>
                    A customer/client/user shall provide a one-click unsubscribe
                    option in all emails and refrain from sending emails to
                    persons who have opted out or unsubscribed from our mailing
                    list. The request to opt-out from the mailing list should be
                    honored within 10 days from the date of request.
                  </span>
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  <span>
                    A customer/client/user’s message must include a valid
                    physical postal address. This can be a current street
                    address, or a registered post office box registered, or a
                    private mailbox registered with a commercial mail receiving
                    agency established under Postal Service regulations.
                  </span>
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  <span>
                    A customer/client/user’s message must include a clear and
                    conspicuous explanation of how the recipient can opt out of
                    getting email in the future. The notice must be crafted in a
                    way that’s easy for an ordinary person to recognize, read,
                    and understand. Creative use of type size, color, and
                    location can improve clarity.
                  </span>
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  <span>
                    TALENT FOLDER ’s messaging systems automatically scan all
                    outgoing and incoming email messages, and will alert TALENT
                    FOLDER if a customer/client/user’s message appear to be
                    spam.
                  </span>
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  <span>
                    TALENT FOLDER reserves the right, exercisable at its sole
                    discretion, to inactivate a customer/client/user’s
                    electronic account/s or terminate the Services without any
                    refund to the customer/client/user if the latter continues
                    to use lists that have been advised as constituting spam, or
                    regularly sends spam using the Services. TALENT FOLDER also
                    reserves the right to take legal action against a
                    customer/client/user whose spam directly or indirectly
                    interferes, interrupts or frustrates with the services
                    provided by the Company to other customer/client/user’s
                    services.
                  </span>
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  <span>
                    The Company reviews all imported email lists that are deemed
                    to be large by TALENT FOLDER and shall refuse or revoke
                    permission for a customer/client/user to use those lists at
                    its absolute discretion.
                  </span>
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  <span>
                    TALENT FOLDER may also report incoming email as spam. In
                    addition, TALENT FOLDER reserves the right, exercisable at
                    its sole discretion, to blacklist a customer/client/user’s
                    IP addresses and domain names.
                  </span>
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  <span>
                    TALENT FOLDER reserves the right to inactivate the
                    customer/client/user’s electronic account/s or terminate the
                    Services if the complaint rate for a customer/client/user’s
                    electronic messaging is greater than the average rate of
                    complaint for all customer/client/user of TALENT FOLDER .
                  </span>
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  <span>
                    A customer/client/user agrees and acknowledges through use
                    of the Services that no message filtering system is 100%
                    accurate, and from time-to-time legitimate messages will be
                    filtered out by TALENT FOLDER ’s systems.
                  </span>
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  <span>
                    The Company does not guarantee or warrant that all
                    electronic messages sent or received through the website,
                    system, services, or network will be received by the
                    intended recipient, and accepts no responsibility for the
                    non-receipt of the electronic message by the intended
                    recipient.<br></br>
                    If a customer/client/user believes that a legitimate message
                    it has sent has been filtered out by the TALENT FOLDER ’s
                    systems, please advise the message recipient by another
                    means.
                  </span>
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  <span>
                    In the unlikely event that a customer/client/user receives
                    any message from TALENT FOLDER or sent using its system that
                    may be considered to be spam, please contact immediately
                    TALENT FOLDER . The company will be investigating the
                    matter.
                  </span>
                </ListItem>
              </ul>
            </SectionContent>
          </PrivacySection>
          <PrivacySection>
            <SectionContent>
              A customer/client/user must take all reasonable steps to reduce
              the risk of a message being caught by the spam filters by
              implementing (without limitation) the following:
              <ul>
                <ListItem>
                  <TickMark icon={faCheck} />
                  sending the message in plain text (instead of, or in addition
                  to, HTML);
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  removing any message attachments.
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  avoiding the terminology and text styling typically used by
                  spammers; and/or
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  ensuring a customer’s messages are scanned for malware before
                  dispatch.
                </ListItem>
              </ul>
            </SectionContent>
          </PrivacySection>
          <PrivacySection>
            <SectionContent>
              A customer/client/user must include the following in any
              electronic messaging sent using the Services provided by the
              Company:
              <ul>
                <ListItem>
                  <TickMark icon={faCheck} />
                  An opt-out mechanism that instantly removes the
                  customer/client/user from a subscriber list and ensures that
                  they do not receive another electronic message again. The
                  mechanism must remain functional for at least 30 days after
                  the original message was sent, must allow the unsubscribe
                  message to be sent to whoever authorized the sending of the
                  message, not necessarily any third party that sent it on their
                  behalf, must be presented in a clear and conspicuous way, and
                  be at low cost, or no cost, to the user.
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  The name and telephone contact details of the
                  customer/client/user or sender (whichever is applicable).
                </ListItem>
              </ul>
            </SectionContent>
          </PrivacySection>
        </PrivacyContainer>
      </PageWrapper>
    </>
  );
}

export default Antispam;

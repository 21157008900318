import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import "./jobDetailsModal.css";
import eztech from "./img/eztechlogo.png";
import useMutation from "../../HOOKS/useMutation";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import useSwr from "../../HOOKS/useSWR";
function JobDetailsModal({ isOpen, job, onClose }) {
  const { currentUser } = useSelector((state) => state.user);
  // const [totalExperience, setTotalExperience] = useState("");
  // const [currentSalary, setCurrentSalary] = useState("");
  // const [expectedSalary, setExpectedSalary] = useState("");
  // const [error, setError] = useState("");
  const { mutation } = useMutation();
  const navigate = useNavigate();

  const { data } = useSwr(
    `applyjobs?jobpost_id=${job?._id}&applicant_id=${currentUser?._id}`
  );

  const handleApply = async (e) => {
    e.preventDefault();
    onClose();

    if (!currentUser?._id) {
      navigate("/signin?role=candidate");
      return;
    }
    // if (!totalExperience || !currentSalary || !expectedSalary) {
    //   setError("Please fill all the required fields.");
    //   return;
    // } else {
    //   setError("");
    // }

    try {
      const res = await mutation(`applyjobs/jobapply`, {
        method: "POST",
        body: {
          jobpost_id: job?._id,
          // currentSalary: currentSalary,
          // expectedSalary: expectedSalary,
          // experience: totalExperience,
        },
      });
      if (res?.results?.success) {
        Swal.fire({
          title: "Success",
          text: "Job Applied Successfully.",
          icon: "success",
          confirmButtonText: "ok",
        });
        // setTotalExperience("");
        // setExpectedSalary("");
        // setCurrentSalary("");
      } else {
        Swal.fire({
          title: "Error",
          text: res.results.error.message,
          icon: "error",
          confirmButtonText: "ok",
        }).then(() => {
          navigate("/applicantprofile");
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: error.message,
        icon: "error",
        confirmButtonText: "ok",
      });
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <div className="close-icon" onClick={onClose}>
            <FaTimes />
          </div>
          <h2>{job.title || "Not Provided"}</h2>
          <img
            src={job?.companyId?.logo || eztech}
            alt="Company Logo"
            className="company-logo"
          />
        </div>
        <h3>{job.companyName || "Not Provided"}</h3>
        <p>
          <strong>Experience:</strong> {job.experience.min || 0} -{" "}
          {job.experience.max || 0} Years
        </p>
        <p>
          <strong>Salary:</strong> {job.salary.min || 0} - {job.salary.max || 0}
        </p>
        <p>
          <strong>Location:</strong>{" "}
          {`${job.location.city || "Not Provided"}, ${
            job.location.state || "Not Provided"
          }, ${job.location.country || "Not Provided"}`}
        </p>
        <p>
          <strong>Eligibility:</strong> {job.description || "Not Provided"}
        </p>
        {/* {!data?.data?.data && (
          <div className="input-fields">
            <div className="input-field">
              <label htmlFor="totalExperience">Experience (Years):</label>
              <input
                type="number"
                id="totalExperience"
                value={totalExperience}
                onChange={(e) => setTotalExperience(e.target.value)}
              />
            </div>
            <div className="input-field">
              <label htmlFor="currentSalary">Current Salary:</label>
              <input
                type="number"
                id="currentSalary"
                value={currentSalary}
                onChange={(e) => setCurrentSalary(e.target.value)}
              />
            </div>
            <div className="input-field">
              <label htmlFor="expectedSalary">Expected Salary:</label>
              <input
                type="number"
                id="expectedSalary"
                value={expectedSalary}
                onChange={(e) => setExpectedSalary(e.target.value)}
              />
            </div>
          </div>
        )}
        {error && <p className="error-message">{error}</p>} */}
        {!currentUser?._id && (
          <div className="filter-note">
            <h2>
              Note: Before apply please login and complete your profile more
              than 50% and upload your CV is mandatory for applying the job.
            </h2>
          </div>
        )}
        <button
          className={`apply-button ${data?.data?.data ? "disabled" : ""}`}
          onClick={handleApply}
          disabled={data?.data?.data}
        >
          {data?.data?.data ? "Applied" : "Apply"}
        </button>
      </div>
    </div>
  );
}

export default JobDetailsModal;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Swal from "sweetalert2";
import useStore from "../../../ZUSTAND";
import useMutation from "../../../HOOKS/useMutation";
const Card = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledH3 = styled.h3`
  margin-bottom: 10px;
  font-weight: bold;
`;

const Button = styled.button`
  padding: 5px 10px;
  background-color: #002365;
  color: #fff;
  border: none;
  cursor: pointer;
  margin-right: 10px;
`;

const Input = styled.input`
  margin-right: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
`;

const SkillList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const SkillItem = styled.div`
  position: relative; /* Make the skill item position relative */
  background-color: #f0f0f0;
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
`;

const SkillText = styled.span`
  margin-right: 5px;
`;

const RemoveIcon = styled.span`
  position: absolute; /* Position the icon absolutely */
  top: 2px; /* Adjust the top position */
  right: 2px; /* Adjust the right position */
  cursor: pointer;
  font-size: 10px;
`;

const SkillsComponent = (user) => {
  const { mutate } = useStore();
  const { mutation } = useMutation();
  const [showInput, setShowInput] = useState(false);
  const [newSkill, setNewSkill] = useState("");
  const [skills, setSkills] = useState(
    user?.user?.skills ? [...user.user.skills] : []
  );
  useEffect(() => {}, [skills]);
  const handleAddSkill = () => {
    setShowInput(true);
  };
  const handleSaveSkill = async (e) => {
    e.preventDefault();
    setSkills([...skills, newSkill]);

    setShowInput(false);
    try {
      const res = await mutation(`user/updateinfo/${user?.user._id}`, {
        method: "PUT",
        body: {
          skills: [...skills, newSkill],
        },
      });
      mutate();
      setNewSkill("");
      // if (res?.results?.success) {
      //   mutate();
      //   Swal.fire({
      //     title: "Success",
      //     text: "",
      //     icon: "success",
      //     confirmButtonText: "ok",
      //   });
      // }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: error.message,
        icon: "Error",
        confirmButtonText: "ok",
      });
    }
  };

  const handleCancelSkill = () => {
    setNewSkill("");
    setShowInput(false);
  };

  const handleRemoveSkill = async (indexToRemove) => {
    setSkills(skills.filter((_, index) => index !== indexToRemove));
    const item = skills.filter((_, index) => index !== indexToRemove);
    try {
      const res = await mutation(`user/updateinfo/${user?.user._id}`, {
        method: "PUT",
        body: {
          skills: item,
        },
      });
      mutate();

      if (res.results.success) {
        await mutation(`user/percentage/${user?.user._id}`, {
          method: "DELETE",
        });
        mutate();
      }
      // if (res?.results?.success) {
      //   mutate();
      //   Swal.fire({
      //     title: "Success",
      //     text: "",
      //     icon: "success",
      //     confirmButtonText: "ok",
      //   });
      // }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: error.message,
        icon: "Error",
        confirmButtonText: "ok",
      });
    }
  };

  return (
    <Card>
      <StyledH3>Skills</StyledH3>
      <SkillList>
        {skills.map((skill, index) => (
          <SkillItem key={index}>
            <SkillText>{skill}</SkillText>
            <RemoveIcon onClick={() => handleRemoveSkill(index)}>❌</RemoveIcon>
          </SkillItem>
        ))}
      </SkillList>
      {showInput && (
        <div>
          <Input
            type="text"
            placeholder="Enter skill"
            value={newSkill}
            onChange={(e) => setNewSkill(e.target.value)}
          />
          <Button onClick={handleSaveSkill}>Add</Button>
          <Button onClick={handleCancelSkill}>Cancel</Button>
        </div>
      )}
      {!showInput && <Button onClick={handleAddSkill}>Add Skill</Button>}
    </Card>
  );
};

export default SkillsComponent;

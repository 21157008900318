// src/components/Modal.js
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faTimes } from "@fortawesome/free-solid-svg-icons";
import "./changepasswordmodal.css";

const Modal = ({ isOpen, onClose, onSubmit }) => {
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  if (!isOpen) return null;

  return (
    <div className="change-password-modal-overlay">
      <div className="change-password-modal-content">
        <FontAwesomeIcon
          icon={faTimes}
          onClick={onClose}
          className="change-password-close-icon"
        />
        <h2>Change Password</h2>
        <form onSubmit={onSubmit}>
          <div className="form-group">
            <label htmlFor="oldPassword">Old Password:</label>
            <input type="text" id="oldPassword" name="oldPassword" required />
          </div>
          <div className="form-group">
            <label htmlFor="newPassword">New Password:</label>
            <div className="password-input-container">
              <input
                type={showNewPassword ? "text" : "password"}
                id="newPassword"
                name="newPassword"
                required
              />
              <FontAwesomeIcon
                icon={showNewPassword ? faEyeSlash : faEye}
                onClick={() => setShowNewPassword(!showNewPassword)}
                className="eye-icon"
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="confirmPassword">Confirm Password:</label>
            <div className="password-input-container">
              <input
                type={showConfirmPassword ? "text" : "password"}
                id="confirmPassword"
                name="confirmPassword"
                required
              />
              <FontAwesomeIcon
                icon={showConfirmPassword ? faEyeSlash : faEye}
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                className="eye-icon"
              />
            </div>
          </div>
          <button type="submit" className="update-button">
            Update
          </button>
        </form>
      </div>
    </div>
  );
};

export default Modal;

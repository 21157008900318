import React, { useEffect } from "react";
import "react-circular-progressbar/dist/styles.css";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import { useSpring, animated } from "react-spring";
import _ from "lodash";

function ProgressCircle(currentUser) {
  const percentage = currentUser?.data?.updateProfile;
  const color = percentage <= 50 ? "#ff0000" : "#228B22"; // Red for <= 50%, Green for > 50%
  const animationProps = useSpring({ value: percentage, from: { value: 0 } });
  useEffect(() => {}, [percentage]);
  return (
    <div style={{ width: 200, height: 200, position: "relative" }}>
      <CircularProgressbarWithChildren
        value={percentage}
        strokeWidth={10}
        styles={buildStyles({
          pathTransition: "stroke-dashoffset 2s ease 0s",
          pathColor: "url(#animated-gradient)",
          strokeLinecap: "butt",
        })}
      >
        <RadialSeparators
          count={12}
          style={{
            background: "#fff",
            width: "2px",
            height: "10%",
          }}
        />
        <animated.div
          style={{
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            fontSize: "32px",
            fontWeight: "bold",
            color: color,
          }}
        >
          {animationProps.value.to((val) => `${Math.round(val)}%`)}
        </animated.div>
        <svg width="0" height="0">
          <defs>
            <linearGradient id="animated-gradient" x1="0" y1="0" x2="1" y2="1">
              <stop offset="0%" stopColor={color}>
                <animate
                  attributeName="stop-color"
                  values={`${color};#f7d627;${color};${color}`}
                  dur="4s"
                  repeatCount="indefinite"
                />
              </stop>
              <stop offset="50%" stopColor={color}>
                <animate
                  attributeName="stop-color"
                  values={`${color};#f7d627;${color};${color}`}
                  dur="4s"
                  repeatCount="indefinite"
                />
              </stop>
              <stop offset="100%" stopColor={color}>
                <animate
                  attributeName="stop-color"
                  values={`${color};#f7d627;${color};${color}`}
                  dur="4s"
                  repeatCount="indefinite"
                />
              </stop>
            </linearGradient>
          </defs>
        </svg>
      </CircularProgressbarWithChildren>
    </div>
  );
}

const styles = {
  floatingWater: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    background: "rgba(79, 172, 254, 0.5)",
    animation: "float 4s infinite ease-in-out",
    borderRadius: "50%",
  },
  "@keyframes float": {
    "0%": { transform: "translateY(0)" },
    "50%": { transform: "translateY(20px)" },
    "100%": { transform: "translateY(0)" },
  },
};

export default ProgressCircle;

function Separator(props) {
  return (
    <div
      style={{
        position: "absolute",
        height: "100%",
        transform: `rotate(${props.turns}turn)`,
      }}
    >
      <div style={props.style} />
    </div>
  );
}

function RadialSeparators(props) {
  const turns = 1 / props.count;
  return _.range(props.count).map((index) => (
    <Separator key={index} turns={index * turns} style={props.style} />
  ));
}

import React from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";

export default function Company() {
  const array = [
    {
      key: 1,
      img: "img/company/company-1.png",
    },
    {
      key: 2,
      img: "img/company/company-2.png",
    },
    {
      key: 3,
      img: "img/company/company-3.png",
    },
    {
      key: 4,
      img: "img/company/company-4.png",
    },
    {
      key: 5,
      img: "img/company/company-5.png",
    },
    {
      key: 6,
      img: "img/company/company-6.png",
    },
    // {
    //   key: 7,
    //   img: "img/company/company-7.png",
    // },
    // {
    //   key: 8,
    //   img: "img/company/company-8.png",
    // },
    {
      key: 9,
      img: "img/company/company-9.png",
    },
    {
      key: 10,
      img: "img/company/company-10.png",
    },
    {
      key: 11,
      img: "img/company/company-11.png",
    },
    {
      key: 12,
      img: "img/company/company-12.png",
    },
    {
      key: 13,
      img: "img/company/company-13.png",
    },
    {
      key: 14,
      img: "img/company/company-14.png",
    },
    {
      key: 15,
      img: "img/company/company-15.png",
    },
    // {
    //   key: 16,
    //   img: "img/company/company-16.png",
    // },
    // {
    //   key: 17,
    //   img: "img/company/company-17.png",
    // },
    {
      key: 18,
      img: "img/company/company-18.png",
    },
    {
      key: 19,
      img: "img/company/company-19.png",
    },
    {
      key: 20,
      img: "img/company/company-20.png",
    },
    {
      key: 21,
      img: "img/company/company-21.png",
    },
    // {
    //   key: 22,
    //   img: "img/company/company-22.png",
    // },
    {
      key: 23,
      img: "img/company/company-23.png",
    },
    {
      key: 24,
      img: "img/company/company-24.png",
    },
    {
      key: 25,
      img: "img/company/company-25.png",
    },
    {
      key: 26,
      img: "img/company/company-26.png",
    },
    {
      key: 27,
      img: "img/company/company-27.png",
    },
    {
      key: 28,
      img: "img/company/company-28.png",
    },
    {
      key: 29,
      img: "img/company/company-29.png",
    },
    {
      key: 30,
      img: "img/company/company-30.png",
    },
    {
      key: 31,
      img: "img/company/company-31.png",
    },
    {
      key: 32,
      img: "img/company/company-32.png",
    },
    {
      key: 33,
      img: "img/company/company-33.png",
    },
    {
      key: 34,
      img: "img/company/company-34.png",
    },
    {
      key: 35,
      img: "img/company/company-35.png",
    },
    {
      key: 36,
      img: "img/company/company-36.png",
    },
    {
      key: 37,
      img: "img/company/company-37.png",
    },
    {
      key: 38,
      img: "img/company/company-38.png",
    },
    {
      key: 39,
      img: "img/company/company-39.png",
    },
  ];
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1, // Scroll one slide at a time
    autoplay: true,
    speed: 4000,
    cssEase: "linear",
    autoplaySpeed: 0, // Adjust autoplay speed as needed
    pauseOnHover: true, // Ensure autoplay doesn't pause on hover
    arrows: false,
    responsive: [
      {
        breakpoint: 940,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          speed: 5000,
          infinite: true,
          arrows: false,
        },
      },
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          arrows: false,
        },
      },
    ],
  };

  const navigate = useNavigate();
  return (
    <>
      <section className="flex flex-col lg:flex-row justify-center items-center px-12 md:px-24 lg:px-24 lg:translate-y-[25%]  gap-5 lg:gap-10">
        <div className="flex justify-center items-center bg-white border border-slate-300 shadow-lg gap-2 py-3 lg:py-1 rounded-md">
          <aside className="flex flex-col items-start justify-center gap-5 ml-7">
            <img
              src="img/icon/developer1.png"
              alt="img 1"
              className="flex md:hidden justify-center items-center w-full"
            />
            <h1 className="text-lg md:text-3xl lg:text-3xl font-bold">
              Hire The Top-notch Talents!
            </h1>
            <h2 className="text-slate-500 w-[90%] md:w-full">
              Get the perfect employee for your budget from our   
              community.
            </h2>
            <button
              onClick={() => navigate("/signin")}
              className="text-white bg-redTalent px-6 py-2 rounded-md hover:bg-slate-800 w-[90%] md:w-[30%]"
            >
              Browse
            </button>
          </aside>
          <aside className="hidden md:flex lg:flex flex-col">
            <div className="-translate-y-1">
              <img src="/img/icon/bg1.png" alt="img" />
            </div>
            <div className="translate-y-[-50%]">
              <img src="img/icon/developer1.png" alt="img 1" />
            </div>
          </aside>
        </div>

        <div className="flex justify-center items-center bg-white border border-slate-300 shadow-lg py-5 rounded-md mb-10 md:mb-10 lg:mb-0">
          <aside className="flex flex-col items-start justify-center gap-5 ml-7">
            <img
              src="img/icon/developer2.png"
              alt="img 2"
              className="flex md:hidden justify-center items-center w-full"
            />
            <h1 className="text-lg md:text-3xl lg:text-3xl font-bold">
              Find Your Next Great Job Opportunity!
            </h1>
            <h2 className="text-slate-500 w-[90%] md:w-full">
              Discover ideal jobs, assess skills, build resume,
              <br /> search listings, access resources for success!
            </h2>
            <button
              onClick={() => navigate("/jobsearch")}
              className="text-white  bg-redTalent px-6 py-2 rounded-md hover:bg-slate-800 w-[90%] md:w-[30%]"
            >
              Browse
            </button>
          </aside>
          <aside className="hidden md:flex lg:flex flex-col">
            <div className="-translate-y-5">
              <img src="/img/icon/bg2.png" alt="img" />
            </div>
            <div className="translate-y-[-50%]">
              <img src="img/icon/developer2.png" alt="img 1" />
            </div>
          </aside>
        </div>
      </section>
      <section className="bg-category sm:p-0 lg:p-10"></section>
      <section className="bg-category p-10">
        <div className="flex flex-col justify-center items-center px-0 md:px-9 py-10 gap-7">
          <div className="flex flex-col justify-center items-center gap-4">
            <h2 className="text-[25px] lg:text-4xl font-semibold text-center">
              Trusted By The World's Leading MNCs
            </h2>
            {/* <p className="text-slate-500 font-semibold text-xl">
              Top companies{" "}
            </p> */}
          </div>
          <div className="">
            <div className="hidden md:grid grid-cols-1 !gap-8">
              <Slider {...settings}>
                {array.map((item) => (
                  <div
                    className="flex !justify-center items-center py-4 px-2"
                    key={item.key}
                  >
                    <img src={item.img} alt="Img" />
                  </div>
                ))}
              </Slider>
            </div>

            <div className="md:hidden grid grid-cols-1 !gap-3">
              <Slider {...settings}>
                {array.map((item) => (
                  <div
                    key={item.key}
                    className="flex justify-center items-center py-4 px-1"
                  >
                    <img
                      className="h-12 w-28"
                      style={{ margin: "0 8px" }}
                      src={item.img}
                      alt="Img"
                    />
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import TextField from "@mui/material/TextField";
import TablePagination from "@mui/material/TablePagination";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import DialogContentText from "@mui/material/DialogContentText";
import useSwr from "../../../../HOOKS/useSWR";
import useMutation from "../../../../HOOKS/useMutation";
import Swal from "sweetalert2";

function createData(question, answer) {
  return { question, answer };
}

const demoData = [
  createData(
    "What is Lorem Ipsum?",
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
  ),
  createData(
    "Why do we use it?",
    "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout."
  ),
  createData(
    "Where does it come from?",
    "Contrary to popular belief, Lorem Ipsum is not simply random text."
  ),
  createData(
    "Where can I get some?",
    "There are many variations of passages of Lorem Ipsum available."
  ),
];

function Row(props) {
  const { row, onDelete, onEdit } = props;

  return (
    <TableRow>
      <TableCell>{row.question}</TableCell>
      <TableCell>{row.answer}</TableCell>
      <TableCell align="right">
        <IconButton
          aria-label="edit"
          size="small"
          onClick={() => onEdit(row)}
          style={{ color: "blue" }}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          aria-label="delete"
          size="small"
          onClick={() => onDelete(row._id)}
          style={{ color: "red" }}
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    question: PropTypes.string.isRequired,
    answer: PropTypes.string.isRequired,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default function CollapsibleFaqTable() {
  const { mutation } = useMutation();
  const [id, setId] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [editQuestion, setEditQuestion] = useState("");
  const [editAnswer, setEditAnswer] = useState("");
  const { data: faqs, isValidating, mutate: fetureMutate } = useSwr(`faq`);

  const faqList = faqs?.data?.data?.data || [];

  useEffect(() => {
    if (faqList) {
      setRows(faqList);
    }
  }, [faqList]);

  const [rows, setRows] = useState(faqList);

  const handleDelete = (id) => {
    setDeleteConfirmationOpen(true);
    setDeleteId(id);
  };

  const handleDeleteConfirmed = async () => {
    setDeleteConfirmationOpen(false);
    try {
      const res = await mutation(`faq/${deleteId}`, {
        method: "DELETE",
      });
      fetureMutate();
      if (res) {
        Swal.fire({
          title: "Success",
          text: res.results.message,
          icon: "success",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: error.message,
        icon: "Error",
        confirmButtonText: "OK",
      });
    }
  };

  const handleDeleteCanceled = () => {
    setDeleteConfirmationOpen(false);
  };

  const handleEdit = (row) => {
    setRowToEdit(row);
    setId(row._id);
    setEditQuestion(row.question);
    setEditAnswer(row.answer);
    setEditDialogOpen(true);
  };

  const handleEditConfirmed = () => {
    setEditDialogOpen(false);
  };

  const handleEditCanceled = () => {
    setEditDialogOpen(false);
  };

  const handleAdd = async () => {
    // Perform API call or mutation to add new FAQ
    // Close the add dialog and possibly refresh data
    setAddDialogOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const data = { question: editQuestion, answer: editAnswer };
      const res = await mutation(`faq/${id}`, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });
      fetureMutate();
      if (res) {
        Swal.fire({
          title: "Success",
          text: res.results.message,
          icon: "success",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: error.message,
        icon: "Error",
        confirmButtonText: "OK",
      });
    }
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredRows = rows?.length
    ? rows.filter((row) =>
        row.question.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : demoData; // Fallback to demoData if rows is empty

  return (
    <Paper>
      <Box sx={{ padding: 2 }}>
        <Button
          style={{ marginBottom: "16px" }}
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setAddDialogOpen(true)}
        >
          Add FAQ
        </Button>
        <TextField
          label="Search"
          variant="outlined"
          value={searchQuery}
          onChange={handleSearch}
          fullWidth
        />
      </Box>
      <TableContainer>
        <Table aria-label="faq table">
          <TableHead>
            <TableRow>
              <TableCell>Question</TableCell>
              <TableCell>Answer</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <Row
                  key={row._id}
                  row={row}
                  onEdit={handleEdit}
                  onDelete={handleDelete}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isValidating ? (
        <TableRow>
          <TableCell colSpan={3} align="center">
            Loading...
          </TableCell>
        </TableRow>
      ) : (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
      <Dialog
        open={deleteConfirmationOpen}
        onClose={handleDeleteCanceled}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this FAQ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCanceled} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirmed} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={editDialogOpen}
        onClose={handleEditCanceled}
        aria-labelledby="edit-dialog-title"
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle id="edit-dialog-title">Edit FAQ</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              label="Question"
              fullWidth
              variant="outlined"
              value={editQuestion}
              onChange={(e) => setEditQuestion(e.target.value)}
            />
            <TextField
              margin="dense"
              label="Answer"
              fullWidth
              variant="outlined"
              multiline
              rows={4}
              value={editAnswer}
              onChange={(e) => setEditAnswer(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditCanceled} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary" autoFocus>
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        open={addDialogOpen}
        onClose={() => setAddDialogOpen(false)}
        aria-labelledby="add-dialog-title"
      >
        <form onSubmit={handleAdd}>
          <DialogTitle id="add-dialog-title">Add FAQ</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              label="Question"
              fullWidth
              variant="outlined"
              value={editQuestion}
              onChange={(e) => setEditQuestion(e.target.value)}
            />
            <TextField
              margin="dense"
              label="Answer"
              fullWidth
              variant="outlined"
              multiline
              rows={4}
              value={editAnswer}
              onChange={(e) => setEditAnswer(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setAddDialogOpen(false)} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary" autoFocus>
              Add
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Paper>
  );
}

import React, { useState, useEffect } from "react";
import "./contact.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { BASE_URL } from "../../DATA/category";
import Swal from "sweetalert2";
import Loader from "react-loader-spinner";

function Contact() {
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [country, setCountry] = useState("");
  const [charCount, setCharCount] = useState(500);

  const handleOnSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    // alert(JSON.stringify({ phone, email, message, name, country }));

    fetch(`${BASE_URL}/contactus`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        email,
        message,
        phoneNumber: phone,
        countryCode: phone,
        country,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setTimeout(() => {
          setLoading(false); // Set loading state back to false
          Swal.fire({
            title: "Success",
            text: "Your message has been delivered successfully.",
            icon: "success",
            confirmButtonText: "Ok",
          });
        }); // 5000 milliseconds = 5 seconds
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          title: "Error",
          text: "",
          icon: "error",
          confirmButtonText: "ok",
        });
      });
    setCountry("");
    setPhone("");
    setName("");
    setEmail("");
    setMessage("");
  };
  const phoneNumber = "+971-564041021"; // Replace with your phone number

  const handleWhatsappClick = () => {
    // Replace the placeholder number with your WhatsApp number in international format
    window.open("https://wa.me/564041021", "_blank");
  };

  const handleMailClick = () => {
    const yourEmail = "hello@talentfolder.com";
    const mailtoLink = `mailto:${yourEmail}`;

    window.location.href = mailtoLink;
  };

  return (
    <>
      <div className="contact-container">
        <div className="contacts">
          <div className="company-details">
            <h1>Have a Question in Mind?</h1>
            <h3>Reach us with Ease</h3>
            <p>
              We'd love to guide you on how you can get your dream career
              opportunity, provide better career advice, and hire productive &
              talented employees. Here's how you can reach out to our team.
            </p>

            <div className="button-containerr">
              <button className="btn1 whatsapp" onClick={handleWhatsappClick}>
                <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
              </button>
              <button className="btn1 mail-us" onClick={handleMailClick}>
                <FontAwesomeIcon icon={faEnvelope} /> Mail Us
              </button>
            </div>

            <div className="headofc">
              <h4>Head Office</h4>
              <p>
                {" "}
                Talent Folder LLC, Business Center, SPC Free Zone, Sharjh, UAE
              </p>
            </div>
            <div className="customersupport">
              <h4>Customer Support</h4>
              <p>
                <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                  Calling Hours -
                </span>{" "}
                <span style={{ color: "#fc2722", fontWeight: "bold" }}>
                  09:00 AM to 06:00 PM (Monday to Friday)
                </span>
              </p>

              <p>
                <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                  Contact Us-
                </span>{" "}
                <span style={{ color: "#fc2722", fontWeight: "bold" }}>
                  +971-564041021
                </span>
              </p>
            </div>

            <div className="headofc">
              <h4>Branches accross world</h4>
              <p>INDIA | UAE | SINGAPORE | MIDDLE-EAST | UK</p>
            </div>

            <div className="mail">
              <p style={{ marginTop: "20px" }}>
                <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                  Mail Us :
                </span>{" "}
                hello@talentfolder.com
              </p>
            </div>
          </div>
          <div className="contact-form">
            <h2>Submit Your Request</h2>
            <form onSubmit={handleOnSubmit}>
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  className="contact-formm"
                  type="text"
                  id="name"
                  name="name"
                  required
                  title="Please enter only letters and spaces for the name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  className="contact-formm"
                  type="email"
                  id="email"
                  name="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="country">Country</label>
                <input
                  className="contact-formm"
                  type="text"
                  id="country"
                  name="country"
                  required
                  title="Please enter only letters and spaces for the name"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="phone">Phone Number</label>
                <PhoneInput
                  country={"ae"} // Default country (optional)
                  value={phone}
                  onChange={(e) => setPhone(e)}
                  inputProps={{
                    id: "phone",
                    name: "phone",
                    required: true,
                  }}
                  inputStyle={{
                    width: "100%",
                    fontSize: "16px",
                    borderRadius: "4px",
                  }}
                />
              </div>
              <div className="form-group">
                <label htmlFor="message">
                  Message:{" "}
                  <span
                    style={{
                      color: charCount >= 250 ? "green" : "red",
                      fontWeight: "500",
                    }}
                  >
                    ({charCount} characters remaining)
                  </span>
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows="4"
                  required
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                    const text = e.target.value;
                    const slicedText = text.slice(0, 500);
                    setMessage(slicedText);
                    setCharCount(500 - slicedText.length);
                  }}
                ></textarea>
              </div>
              <button type="submit" className="submit-button">
                {loading ? "Submitting..." : "Submit"}
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className="location">
        <div className="map-container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3609.3462061808514!2d55.28120757444267!3d25.225261730565144!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f4349905a0921%3A0x41ca51cd0146be59!2sTalent%20Folder%20LLC!5e0!3m2!1sen!2sin!4v1722925840812!5m2!1sen!2sin"
            width="600"
            height="450"
            style={{
              border: 0,
              width: "100%",
              height: "400px",
              marginBottom: "20px",
              borderRadius: "20px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </>
  );
}

export default Contact;

import React, { useEffect, useState } from "react";
import "./Location.css";
import { Link } from "react-router-dom";

function Location() {
  const [isAnimationTriggered, setIsAnimationTriggered] = useState(false);
  const [isScrolledIntoView, setIsScrolledIntoView] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const locationSection = document.querySelector(".header-title");
      if (locationSection && !isAnimationTriggered) {
        const rect = locationSection.getBoundingClientRect();
        const isVisibleFromTop =
          rect.top <= window.innerHeight && rect.bottom >= 0;
        const isScrolledFromTop = window.scrollY <= locationSection.offsetTop;
        if (isVisibleFromTop && isScrolledFromTop) {
          setIsScrolledIntoView(true);
          setIsAnimationTriggered(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isAnimationTriggered]);

  return (
    <div>
      <div className={`header-title ${isScrolledIntoView ? "animate" : ""}`}>
        <h2>Jobs by Location</h2>
        <p>Find your favorite jobs and get the benefits of yourself</p>
      </div>
      <div className="card-container">
        <div className={`card ${isScrolledIntoView ? "animate" : ""}`}>
          <Link to={`/jobfilter?locn=india`}>
            <img src="./img/location/india.png" alt="Image 1" />
            <div className="card-text">
              <div className="country-name">INDIA</div>
              <a href="#" className="second-line">
                40 Companies
              </a>
            </div>
          </Link>
        </div>

        <div className={`card ${isScrolledIntoView ? "animate" : ""}`}>
          <Link to={`/jobfilter?locn=uae`}>
            <img src="./img/location/uae.png" alt="Image 2" />
            <div className="card-text">
              <div className="country-name">UAE / MIDDLE EAST</div>
              <a href="#" className="second-line">
                52 Companies
              </a>
            </div>
          </Link>
        </div>

        <div className={`card ${isScrolledIntoView ? "animate" : ""}`}>
          <Link to={`jobfilter?locn=singapore`}>
            <img src="./img/location/singapore.png" alt="Image 3" />
            <div className="card-text">
              <div className="country-name">SINGAPORE</div>
              <a href="#" className="second-line">
                77 Companies
              </a>
            </div>
          </Link>
        </div>

        <div className={`card ${isScrolledIntoView ? "animate" : ""}`}>
          <Link to={`/jobfilter?locn=united kingdom`}>
            <img src="./img/location/middle-east.png" alt="Image 4" />
            <div className="card-text">
              <div className="country-name">United Kingdom</div>
              <a href="#" className="second-line">
                85 Companies
              </a>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Location;

import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import { BarChart } from "@mui/x-charts";
import {
  faChartLine,
  faBriefcase,
  faTasks,
  faFileAlt,
  faStar,
  faUser,
  faSignOutAlt,
  faHome,
  faBars,
  faBell,
  faSuitcase,
  faTimes,
  faUsers,
  faBuilding,
  faPen,
  faImage,
  faList,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import "./dashboard.css";
import { useDispatch, useSelector } from "react-redux";
import { SignoutuserSuccess } from "../../../../redux/users/UserSlice";
import { useNavigate } from "react-router-dom";
import useSwr from "../../../../HOOKS/useSWR";

function Dashboard() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  //   const [sidebarOpen, setSidebarOpen] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    // if (windowWidth > 768) setIsSidebarOpen(false);
    if (windowWidth < 768) setIsSidebarOpen(false);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const toggleSidebar = () => {
    if (windowWidth <= 768) {
      //   setIsSidebarOpen(false);
      setIsSidebarOpen(!isSidebarOpen);
    }
  };
  const onLogout = () => {
    dispatch(SignoutuserSuccess());
    localStorage.removeItem("token");
    navigate("/signin");
    // Additional logout logic (e.g., clearing tokens, redirecting) can be added here
  };
  const { data } = useSwr("dashboard");
  const dashboardData = data?.data?.data;
  return (
    <div className="dashboard">
      {/* Sidebar */}
      {isSidebarOpen && (
        <div className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
          <ul>
            <li>
              <Link to="/dashboard" className="sidebar-item">
                <FontAwesomeIcon icon={faChartLine} />
                <span>Dashboard</span>
              </Link>
            </li>
            <li>
              <Link to="/jobpost" className="sidebar-item">
                <FontAwesomeIcon icon={faBriefcase} />
                <span>Manage Jobs Post</span>
              </Link>
            </li>
            <li>
              <Link to="/managejob" className="sidebar-item">
                <FontAwesomeIcon icon={faTasks} />
                <span>Manage Jobs</span>
              </Link>
            </li>
            <li>
              <Link to="/manageresume" className="sidebar-item">
                <FontAwesomeIcon icon={faFileAlt} />
                <span>Manage Resume</span>
              </Link>
            </li>
            <li>
              <Link to="/manage-company" className="sidebar-item">
                <FontAwesomeIcon icon={faBuilding} />
                <span>Manage Company</span>
              </Link>
            </li>
            <li>
              <Link to="/applicant" className="sidebar-item">
                <FontAwesomeIcon icon={faUsers} />
                <span>Applicants</span>
              </Link>
            </li>
            <li>
              <Link to="/addblog" className="sidebar-item">
                <FontAwesomeIcon icon={faPen} />
                <span>Add Blogs</span>
              </Link>
            </li>
            <li>
              <Link to="/bloglist" className="sidebar-item">
                <FontAwesomeIcon icon={faList} />
                <span>Blog List</span>
              </Link>
            </li>
            <li>
              <Link to="/banner-update" className="sidebar-item">
                <FontAwesomeIcon icon={faImage} />
                <span>Update Banner</span>
              </Link>
            </li>
            <li>
              <Link to="/faq" className="sidebar-item">
                <FontAwesomeIcon icon={faQuestionCircle} />
                <span>Faq</span>
              </Link>
            </li>
            <li>
              <Link to="/adminprofile" className="sidebar-item">
                <FontAwesomeIcon icon={faUser} />
                <span>My Profile</span>
              </Link>
            </li>
            <li>
              <div className="sidebar-item" onClick={onLogout}>
                <FontAwesomeIcon icon={faSignOutAlt} />
                <span>Logout</span>
              </div>
            </li>
          </ul>
        </div>
      )}

      {/* Main content */}
      <div className="dashboard-main-content">
        <div className="dashboard-card-container">
          <div className="dashboard-card">
            <div className="dashboard-icon-container-user">
              <FontAwesomeIcon icon={faUser} className="dashboard-icon-user" />
            </div>
            <h3>Active Users</h3>
            <h1>{dashboardData?.userNumber || 0}</h1>
          </div>
          <div className="dashboard-card">
            <div className="dashboard-icon-container-user">
              <FontAwesomeIcon icon={faUser} className="dashboard-icon-user" />
            </div>
            <h3>Total Applicants Visits</h3>
            <h1>{dashboardData?.visit || 0}</h1>
          </div>

          <div className="dashboard-card">
            <div className="dashboard-icon-container">
              <FontAwesomeIcon icon={faSuitcase} className="dashboard-icon" />
            </div>
            <h3>Active Jobs</h3>
            <h1>{dashboardData?.totalActiveJob || 0}</h1>
          </div>

          <div className="dashboard-card">
            <div className="dashboard-icon-container-expire">
              <FontAwesomeIcon
                icon={faTimes}
                className="dashboard-icon-expire"
              />
            </div>

            <h3>Expired Job</h3>
            <h1>{dashboardData?.totalExpireJob || 0}</h1>
          </div>
        </div>
        <div className="dashboard-bar-chart">
          <div className="1stchart">
            <BarChart
              xAxis={[
                {
                  scaleType: "band",
                  data: [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                  ],
                },
              ]}
              series={[
                {
                  data: [6, 4, 3, 4, 5, 6, 2, 8, 9, 7, 11, 12],
                },
              ]}
              sx={{
                "& .MuiBarElement-root:nth-child(1)": {
                  fill: "red",
                },
                "& .MuiBarElement-root:nth-child(2)": {
                  fill: "blue",
                },
                "& .MuiBarElement-root:nth-child(3)": {
                  fill: "green",
                },
                "& .MuiBarElement-root:nth-child(4)": {
                  fill: "lavander",
                },
                "& .MuiBarElement-root:nth-child(5)": {
                  fill: "orange",
                },
                "& .MuiBarElement-root:nth-child(6)": {
                  fill: "#5e8fa8",
                },
                "& .MuiBarElement-root:nth-child(7)": {
                  fill: "orange",
                },
                "& .MuiBarElement-root:nth-child(8)": {
                  fill: "lavander",
                },
                "& .MuiBarElement-root:nth-child(9)": {
                  fill: "green",
                },
                "& .MuiBarElement-root:nth-child(10)": {
                  fill: "blue",
                },
                "& .MuiBarElement-root:nth-child(11)": {
                  fill: "#5e8fa8",
                },
                "& .MuiBarElement-root:nth-child(12)": {
                  fill: "#orange",
                },
              }}
              width={550}
              height={400}
            />
            <h2 className="dashgraph">Yearly Job Post</h2>
          </div>
          <div className="2ndchart">
            <BarChart
              xAxis={[
                {
                  scaleType: "band",
                  data: [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                  ],
                },
              ]}
              series={[
                {
                  data: [8, 5, 3, 6, 5, 11, 4, 7, 9, 2, 5, 10],
                },
              ]}
              sx={{
                "& .MuiBarElement-root:nth-child(1)": {
                  fill: "#B0BF1A",
                },
                "& .MuiBarElement-root:nth-child(2)": {
                  fill: "#DB2D43",
                },
                "& .MuiBarElement-root:nth-child(3)": {
                  fill: "#007FFF",
                },
                "& .MuiBarElement-root:nth-child(4)": {
                  fill: "#9400D3",
                },
                "& .MuiBarElement-root:nth-child(5)": {
                  fill: "#FF9933",
                },
                "& .MuiBarElement-root:nth-child(6)": {
                  fill: "#5e8fa8",
                },
                "& .MuiBarElement-root:nth-child(7)": {
                  fill: "orange",
                },
                "& .MuiBarElement-root:nth-child(8)": {
                  fill: "lavander",
                },
                "& .MuiBarElement-root:nth-child(9)": {
                  fill: "#DB2D43",
                },
                "& .MuiBarElement-root:nth-child(10)": {
                  fill: "#007FFF",
                },
                "& .MuiBarElement-root:nth-child(11)": {
                  fill: "#E68FAC",
                },
                "& .MuiBarElement-root:nth-child(12)": {
                  fill: "#5e8fa8",
                },
              }}
              width={520}
              height={400}
            />
            <h2 className="dashgraph">Yearly User Comes</h2>
          </div>
        </div>
      </div>

      {/* Bottom Navigation */}
      <div className="bottom-nav">
        <ul>
          <li>
            <FontAwesomeIcon icon={faHome} />
            <span>Home</span>
          </li>
          <li onClick={toggleSidebar}>
            <FontAwesomeIcon icon={faBars} />
            <span>Menu</span>
          </li>
          <li>
            <FontAwesomeIcon icon={faBell} />
            <span>Notifications</span>
          </li>
          <li>
            <FontAwesomeIcon icon={faUser} />
            <span>Profile</span>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Dashboard;

import React from "react";
import styled from "styled-components";
import backgroundImage from "./privacybg.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const PageWrapper = styled.div`
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const PrivacyContainer = styled.div`
  padding: 40px 20px;
  max-width: 900px;
  margin: 40px auto;
  background: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-family: "Roboto", sans-serif;
  color: #333;

  @media (max-width: 768px) {
    padding: 20px 15px;
    margin: 20px auto;
  }
`;

const PrivacyHeader = styled.h1`
  font-size: 2.5em;
  text-align: center;
  color: #2c3e50;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    font-size: 2em;
    margin-bottom: 20px;
  }
`;

const PrivacySection = styled.section`
  margin-bottom: 30px;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const SectionTitle = styled.h2`
  font-size: 1.8em;
  color: #34495e;
  margin-bottom: 15px;
  position: relative;
  padding-bottom: 10px;

  &::after {
    content: "";
    width: 50px;
    height: 3px;
    background: #fe4324;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  @media (max-width: 768px) {
    font-size: 1.5em;
    margin-bottom: 10px;
    padding-bottom: 5px;

    &::after {
      width: 40px;
      height: 2px;
    }
  }
`;

const SectionContent = styled.p`
  color: #7f8c8d;
  font-size: 1em;
  line-height: 1.8;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 0.9em;
  }
`;

const TickMark = styled(FontAwesomeIcon)`
  color: #dc143c;
  margin-right: 10px;
  margin-top: 6px;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start;

  @media (max-width: 768px) {
    font-size: 0.9em;
  }
`;

function Misconduct() {
  return (
    <>
      <PageWrapper>
        <PrivacyContainer>
          <PrivacyHeader>Misconduct Reporting Procedure.</PrivacyHeader>
          <PrivacySection>
            <SectionContent>
              The Misconduct Reporting Procedure outlines the steps to follow if
              you suspect or have evidence of any serious misconduct within or
              relating to Talent Folder LLC.
            </SectionContent>
          </PrivacySection>
          <PrivacySection>
            <SectionTitle>Reporting a misconduct</SectionTitle>
            <SectionContent>
              <ul>
                <ListItem>
                  <TickMark icon={faCheck} />
                  <span>
                    Talent Folder LLC is an international organization with a
                    global emphasis on excellence. As such, we expect all our
                    representatives to behave ethically, lawfully and in
                    accordance with our core values: to know, to serve, to
                    trust, simultaneous promotion of all interests, and striving
                    for perfection. As part of our culture of openness and
                    accountability, we require all stakeholders to report any
                    conduct that falls short of these values.
                  </span>
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  <span>
                    To assist the reporting of serious misconduct (including
                    illegal activities, health and safety failures, and breaches
                    of Talent Folder LLC’s policies on appropriate behavior), we
                    have established the Misconduct Reporting Procedure.
                    Employees are encouraged first to raise their concerns with
                    their manager or through another appropriate local reporting
                    facility. If this is likely to be ineffective or
                    inappropriate, they should submit a report through the
                    Talent Folder LLC reporting facility, which consists of a
                    secure webpage and a 24-hour telephone hotline, see contact
                    information. Reports received through this channel are
                    forwarded to the relevant Local Integrity Officer, who
                    ensures that any report is dealt with quickly, fairly and
                    lawfully. For more information, please view our FAQ.
                  </span>
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  <span>
                    All concerns are treated with strict confidentiality, and
                    with the complete assurance that there will be no
                    retaliation against any employee filing a complaint in good
                    faith. Persons under investigation will be notified and have
                    the right both to respond to the allegations and to appeal
                    against any adverse findings. Where serious misconduct is
                    proven, management will take prompt and appropriate action.
                  </span>
                </ListItem>
              </ul>
            </SectionContent>
          </PrivacySection>
        </PrivacyContainer>
      </PageWrapper>
    </>
  );
}

export default Misconduct;

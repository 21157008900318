import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import useMutation from "../../HOOKS/useMutation";
import Swal from "sweetalert2";
const ForgetPassword = () => {
  const { mutation } = useMutation();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is Required"),
    }),
    onSubmit: async (values) => {
      const res = await mutation(`user/forgotpassword`, {
        method: "POST",
        body: {
          email: values.email,
        },
      });
      if (res?.results?.success) {
        Swal.fire({
          title: "Success!",
          text: "Check Your Mail",
          icon: "success",
          confirmButtonText: "ok",
        });
      }
      if (res?.results?.status === 404) {
        Swal.fire({
          title: "Error!",
          text: res?.results?.error?.message,
          icon: "error",
          confirmButtonText: "ok",
        });
      }
    },
  });

  return (
    <>
      <section
        className="w-full bg-center bg-cover bg-no-repeat h-screen "
        style={{ backgroundImage: `url('/img/login-bg.png')` }}
      >
        <div className="flex flex-col justify-center items-center px-6 py-12 lg:px-8">
          <div className="  bg-white border rounded-xl border-slate-300 w-full md:w-[40%] ld:w-[39%] py-10 px-5 md:px-5 lg:px-0">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
              <img
                className="mx-auto h-16 w-60"
                src="/img/logo.png"
                alt="Your Company"
              />
              <h2 className="mt-4 text-center text-lg font-bold leading-9 tracking-tight text-slate-600">
                Welcome! Nice to see you again
              </h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
              <form onSubmit={formik.handleSubmit} className="space-y-6">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Email Address<span className="text-redTalent">*</span>
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      className="block w-full rounded-md border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring--600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  {formik.touched.email && formik.errors.email ? (
                    <div className="text-redTalent">{formik.errors.email}</div>
                  ) : null}
                </div>

                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-redTalent px-3 py-1.5 text-md font-semibold leading-6 text-white shadow-sm hover:bg-white hover:border hover:border-redTalent hover:text-redTalent focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Reset Password
                  </button>
                </div>
              </form>
            </div>
            <div className="flex flex-col lg:flex-row items-center justify-between px-2 lg:px-16 pt-10 ">
              <span className="font-medium">Already Have Password</span>{" "}
              <div className="mt-5 lg:mt-0 font-medium hover:text-redTalent rounded-md  text-gray-900">
                <button
                  onClick={(e) => {
                    navigate("/signin");
                  }}
                >
                  Login Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ForgetPassword;

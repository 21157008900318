import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import ReactCountryFlag from "react-country-flag";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import {
  CitySelect,
  CountrySelect,
  StateSelect,
  LanguageSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import useMutation from "../../HOOKS/useMutation";
const countries = [
  { code: "+91", flag: <ReactCountryFlag countryCode="IN" svg /> },
  { code: "+93", flag: <ReactCountryFlag countryCode="AF" svg /> },
  { code: "+355", flag: <ReactCountryFlag countryCode="AL" svg /> },
  { code: "+213", flag: <ReactCountryFlag countryCode="DZ" svg /> },
  { code: "+1", flag: <ReactCountryFlag countryCode="US" svg /> },
  { code: "+971", flag: <ReactCountryFlag countryCode="AE" svg /> },

  // Add more countries with flags using ReactCountryFlag component
];

const Candidatesignup = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [inputLength, setInputLength] = useState(0);

  const [countryid, setCountryid] = useState(0);
  const [stateid, setstateid] = useState(0);

  const { mutation, isLoading } = useMutation();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      password: "",
      email: "",
      companyname: "",
      fullname: "",
      confirmPassword: "",
      contactno: "",
    },
    validationSchema: Yup.object({
      fullname: Yup.string().required("Full name is required."),
      contactno: Yup.string().required("Contact Number is required."),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is Required"),
      password: Yup.string().required("Password is required"),
      confirmPassword: Yup.string().required("Confirm Password is required."),
    }),
    onSubmit: async (values) => {
      if (values.password !== values.confirmPassword) {
        return Swal.fire({
          title: "Error!",
          text: "Paaword Does not match",
          icon: "error",
          confirmButtonText: "Try Again",
        });
      }
      const signup = await mutation("user/signup", {
        method: "POST",
        body: {
          password: values.password,
          email: values.email,
          name: values.fullname,
          phoneNumber: String(values.contactno),
          countryCode: selectedCountry.code,
        },
      });
      if (signup?.status === 200) {
        Swal.fire({
          title: "Success!",
          text: "Account created successfully",
          icon: "success",
          confirmButtonText: "Go to Login",
        }).then(() => {
          navigate("/signin?role=candidate");
        });
      }
      if (signup?.status !== 200) {
        Swal.fire({
          title: "Error!",
          text: signup.results.error.message,
          icon: "error",
          confirmButtonText: "ok",
        });
      }
    },
  });

  const togglePasswordVisibility = () => {
    inputLength && setShowPassword(!showPassword);
  };

  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
    setDropdownOpen(false);
  };

  const handleDropdownClick = () => {
    setDropdownOpen(!dropdownOpen);
  };
  return (
    <>
      <section
        className="w-full bg-center bg-cover bg-no-repeat h-auto sticky"
        style={{
          backgroundImage: `url('/img/login-bg.png')`,
          backgroundAttachment: "fixed",
        }}
      >
        <div className="flex flex-col justify-center items-center px-6 py-12 lg:px-8">
          <div className="  bg-white border rounded-xl border-slate-300 w-full md:w-[40%] ld:w-[39%] py-10 px-5 md:px-5 lg:px-0">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
              <img
                className="mx-auto h-16 w-60"
                src="/img/logo.png"
                alt="Your Company"
              />
              <h2 className="mt-4 text-center text-lg font-semibold leading-9 tracking-tight text-black-600">
                Find your dream job now
              </h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
              <form onSubmit={formik.handleSubmit} className="space-y-6">
                <div>
                  <label
                    htmlFor="fullname"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Full Name<span className="text-redTalent">*</span>
                  </label>
                  <div className="mt-2">
                    <input
                      id="fullname"
                      name="fullname"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.fullname}
                      className="block w-full rounded-md border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring--600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  {formik.touched.fullname && formik.errors.fullname ? (
                    <div className="text-redTalent">
                      {formik.errors.fullname}
                    </div>
                  ) : null}

                  <label
                    htmlFor="contactno"
                    className="block text-sm font-medium leading-6 text-gray-900 mt-2"
                  >
                    Contact No.<span className="text-redTalent">*</span>
                  </label>
                  <div className="mt-2 flex flex-col sm:flex-row items-start lg:items-center">
                    <div className="relative mb-2 sm:mb-0 sm:mr-2 ">
                      <div
                        className=" w-24 h-8 border border-gray-300 rounded-md flex items-center justify-center"
                        onClick={() => setDropdownOpen(!dropdownOpen)}
                      >
                        <div className="flex items-center justify-between overfolw-y-auto">
                          <span>{selectedCountry.flag}</span>
                          <span className="ml-2">{selectedCountry.code}</span>
                        </div>
                        <FontAwesomeIcon
                          icon={dropdownOpen ? faChevronUp : faChevronDown}
                          className="ml-2 cursor-pointer"
                          style={{ color: "#808080" }}
                          onClick={handleDropdownClick}
                        />
                      </div>
                      {dropdownOpen && (
                        <div className="absolute top-full left-0 right-0 bg-white border border-gray-300 mt-1 rounded-md shadow-md z-10 h-32 overflow-y-auto w-40 ">
                          {countries.map((country) => (
                            <div
                              key={country.code}
                              className="flex items-center px-3 py-2 cursor-pointer hover:bg-gray-100"
                              onClick={() => handleCountrySelect(country)}
                            >
                              <span>{country.flag}</span>
                              <span className="ml-2">
                                {country.name} ({country.code})
                              </span>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    <input
                      id="contactno"
                      name="contactno"
                      type="number"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.contactno}
                      className="flex-1 block rounded-md border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring--600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  {formik.touched.contactno && formik.errors.contactno ? (
                    <div className="text-redTalent">
                      {formik.errors.contactno}
                    </div>
                  ) : null}

                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900 mt-2"
                  >
                    Email Address<span className="text-redTalent">*</span>
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      className="block w-full rounded-md border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring--600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  {formik.touched.email && formik.errors.email ? (
                    <div className="text-redTalent">{formik.errors.email}</div>
                  ) : null}
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-gray-900 mt-2"
                  >
                    Password<span className="text-redTalent">*</span>
                  </label>
                  <div className="mt-2 relative">
                    <input
                      id="password"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      onChange={(e) => {
                        formik.handleChange(e);
                        setInputLength(e.target.value.length);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                      className="block w-full rounded-md px-4 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    <button
                      type="button"
                      className="absolute inset-y-0 right-0 pr-3 flex items-center"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? (
                        <FontAwesomeIcon icon={faEye} />
                      ) : (
                        <FontAwesomeIcon icon={faEyeSlash} />
                      )}
                    </button>
                  </div>
                  {formik.touched.password && formik.errors.password ? (
                    <div className="text-redTalent">
                      {formik.errors.password}
                    </div>
                  ) : null}

                  <label
                    htmlFor="confirmPassword"
                    className="block text-sm font-medium leading-6 text-gray-900 mt-2"
                  >
                    Confirm Password<span className="text-redTalent">*</span>
                  </label>
                  <div className="mt-2 relative">
                    <input
                      id="confirmPassword"
                      name="confirmPassword"
                      type={showPassword ? "text" : "password"}
                      onChange={(e) => {
                        formik.handleChange(e);
                        setInputLength(e.target.value.length);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.confirmPassword}
                      className="block w-full rounded-md px-4 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    <button
                      type="button"
                      className="absolute inset-y-0 right-0 pr-3 flex items-center"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? (
                        <FontAwesomeIcon icon={faEye} />
                      ) : (
                        <FontAwesomeIcon icon={faEyeSlash} />
                      )}
                    </button>
                  </div>
                  {formik.touched.confirmPassword &&
                  formik.errors.confirmPassword ? (
                    <div className="text-redTalent">
                      {formik.errors.confirmPassword}
                    </div>
                  ) : null}

                  {/* <label>Country</label>
                  <CountrySelect
                    onChange={(e) => {
                      setCountryid(e.id);
                    }}
                    placeHolder="Select Country"
                  />
                  <label>State</label>
                  <StateSelect
                    countryid={countryid}
                    onChange={(e) => {
                      setstateid(e.id);
                    }}
                    placeHolder="Select State"
                  />
                  <label>City</label>
                  <CitySelect
                    countryid={countryid}
                    stateid={stateid}
                    onChange={(e) => {
                    }}
                    placeHolder="Select City"
                  /> */}
                </div>

                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-redTalent px-3 py-1.5 text-md font-semibold leading-6 text-white shadow-sm hover:bg-white hover:border hover:border-redTalent hover:text-redTalent focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Register
                  </button>
                </div>
              </form>
            </div>
            <div className="flex flex-col lg:flex-row items-center justify-between px-2 lg:px-16 pt-10 ">
              <div className="flex gap-2">
                <span className="font-medium">Already Have Password</span>
                <Link
                  to="/signin"
                  className="text-redTalent hover:underline font-semibold"
                >
                  Sign in?
                </Link>
              </div>
              <div className="hover:text-redTalent mt-5 lg:mt-0 font-medium"></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Candidatesignup;

import { CloseFullscreen, Fullscreen, Send } from "@mui/icons-material";
import { useEffect, useRef, useState } from "react";
import HTMLFlipBook from "react-pageflip";
import backgroundImage from "./theme.jpg";
import firstpage from "./firstpage.png";
function BookModal(item, props) {
  const imageData = [
    backgroundImage,
    "/img/banner.png",
    "/img/firstpage.png",
    "/img/page_one.png",
    "/img/book-1.png",
    "/books/book3.jpeg",
    "/books/book1.jpg",
    "/books/book2.webp",
    "/books/book3.jpeg",
  ];
  // console.log("item---->", item);
  const data = item?.item?.book;
  // console.log("image data->>", data);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  //const [isFullscreen, setIsFullscreen] = useState(false);

  const toggleFullscreen = () => {
    document?.querySelector("#fs")?.requestFullscreen();
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const flipBookWidth = Math.min(window.innerWidth - 40, 500);
  const flipBookHeight = (flipBookWidth / 5) * 6;

  return (
    <div
      className="w-full bg-center bg-cover bg-no-repeat bg-white h-auto"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="py-14">
        <div id="fs" className="relative">
          <div className="h-full grid place-items-center overflow-hidden">
            <button
              onClick={toggleFullscreen}
              className="absolute bottom-2  right-64 z-50   p-2 focus:outline-none"
            >
              <Fullscreen />
            </button>
            <HTMLFlipBook
              width={flipBookWidth}
              height={flipBookHeight}
              className="!w-full"
              {...props}
            >
              {imageData.map((page, index) => (
                <div className="shadow-[inset_-12px_-8px_40px_#46464620] bg-gray-200 p-4 flex flex-col relative cursor-pointer">
                  <div className="flex-1">
                    <img
                      src={page}
                      alt="Book Page"
                      className="w-full lg:h-[33rem] md:h-[28rem] h-[20rem] object-fit"
                    />
                  </div>
                  {/* <span className="text-lg text-center absolute bottom-4 inset-x-0 font-bold">
                Page {index + 1}
              </span> */}
                </div>
              ))}
            </HTMLFlipBook>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookModal;

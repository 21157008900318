import React, { useEffect, useState } from "react";
import "./Marketplace.css";
import { useNavigate } from "react-router-dom";
function Marketplace() {
  const [isScrolledIntoView, setIsScrolledIntoView] = useState(false);
  const [isAnimationTriggered, setIsAnimationTriggered] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const handleScroll = () => {
      const marketplaceSection = document.querySelector(
        ".marketplace-container"
      );
      if (marketplaceSection && !isAnimationTriggered) {
        const rect = marketplaceSection.getBoundingClientRect();
        const isVisible = rect.top <= window.innerHeight && rect.bottom >= 0;
        setIsScrolledIntoView(isVisible);

        if (isVisible) {
          setIsAnimationTriggered(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isAnimationTriggered]);

  return (
    <div>
      <div
        className={`marketplace-container ${
          isScrolledIntoView ? "animate" : ""
        }`}
      >
        <div
          className={`image-container ${isScrolledIntoView ? "animate" : ""}`}
        >
          <img src="./img/plat-form1.png" alt="Image" className="image" />
        </div>
        <div className={`content ${isScrolledIntoView ? "animate" : ""}`}>
          <h2>World's Best Talent Marketplace For Companies</h2>
          <p>
            We bridge the gap between top recruiters and talented candidates.
          </p>
          <div>
            <ul className="point-list">
              <li>It’s free and easy to post a job.</li>
              <li>
                We've got freelancers for jobs of any size or budget, across
                1800+ skills.
              </li>
              <li>
                Only pay for work when it has been completed and you're 100%
                satisfied.
              </li>
              <li>
                Our talented team of recruiters can help you find the best
                freelancer.
              </li>
            </ul>
            <div className="buttons-container">
              <button
                onClick={() => {
                  navigate("/signin");
                }}
                className="button-primary"
              >
                Post a Project
              </button>
              <button
                onClick={() => {
                  navigate("/signin");
                }}
                className="button-secondary"
              >
                Find Developers
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Marketplace;

import React from "react";
// import CustomCategory from "../../UI/CategoryCustom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { array } from "../../DATA/category/index.js";
import useSwr from "../../HOOKS/useSWR";
import { useNavigate } from "react-router-dom";
export default function Category({ categoryData, isValidating }) {
  const navigate = useNavigate();

  return (
    <section className="flex flex-col  justify-center items-center gap-10 py-10 md:py-20  mx-[40px]  md:mt-0">
      <div className="flex flex-col justify-center items-center gap-5">
        <p className="text-[21px] md:text-5xl lg:text-5xl font-semibold">
          Find Talents By Category
        </p>
        <p className="text-slate-700 text-[14px] md:text-lg lg:text-lg text-center ">
          Browse from thousands of categories that meet your business.
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 w-full px-2 gap-10 ">
        {isValidating ? (
          <p>Loading...</p>
        ) : (
          categoryData?.map((item) => (
            <div
              onClick={() => {
                navigate(`/jobfilter?industryName=${item._id}`);
              }}
              key={item._id}
              className="cursor-pointer flex lg:justify-evenly items-center gap-4 md:gap-0 w-full bg-white border border-slate-300 hover:border-redTalent rounded-lg lg:p-4 group"
            >
              {/* Parent div */}
              <div className="bg-category w-16 h-16 rounded-full flex items-center justify-center transition-transform duration-500 group-hover:rotate-180 group-hover:bg-redTalent">
                {/* Circular part */}
                <div className="w-10 h-10 rounded-full flex items-center justify-center hover:bg-redTalent">
                  <img
                    src={item?.icon}
                    alt="img-category"
                    className="filter group-hover:brightness-0 group-hover:invert"
                  />
                </div>
              </div>
              <div className="flex flex-row md:flex-col justify-center items-center gap-5 md:gap-0">
                <div>{`${item.industryName}`.split(",")[0].split(" ")[0]}</div>
                <div className="flex items-center justify-center gap-4">
                  <p>{`${item.totalJobPostCount} jobs`}</p>
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    className="text-sm text-slate-400"
                  />
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </section>
  );
}

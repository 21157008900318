import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import CollapsibleResumeTable from "./manageresumetable";

import {
  faChartLine,
  faBriefcase,
  faTasks,
  faFileAlt,
  faStar,
  faUser,
  faSignOutAlt,
  faHome,
  faBars,
  faBell,
  faUsers,
  faBuilding,
  faPen,
  faImage,
  faList,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import "./dashboard.css";
import "./managejob.css";

import { useDispatch, useSelector } from "react-redux";
import { SignoutuserSuccess } from "../../../../redux/users/UserSlice";
import { useNavigate } from "react-router-dom";

function Manageresume() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  //   const [sidebarOpen, setSidebarOpen] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    // if (windowWidth > 768) setIsSidebarOpen(false);
    if (windowWidth < 768) setIsSidebarOpen(false);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleSidebar = () => {
    if (windowWidth <= 768) {
      //   setIsSidebarOpen(false);
      setIsSidebarOpen(!isSidebarOpen);
    }
  };
  const onLogout = () => {
    dispatch(SignoutuserSuccess());
    localStorage.removeItem("token");
    navigate("/signin");
    // Additional logout logic (e.g., clearing tokens, redirecting) can be added here
  };

  return (
    <div className="dashboard">
      {/* Sidebar */}
      {isSidebarOpen && (
        <div className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
          <ul>
            <li>
              <Link to="/dashboard" className="sidebar-item">
                <FontAwesomeIcon icon={faChartLine} />
                <span>Dashboard</span>
              </Link>
            </li>
            <li>
              <Link to="/jobpost" className="sidebar-item">
                <FontAwesomeIcon icon={faBriefcase} />
                <span>Manage Jobs Post</span>
              </Link>
            </li>
            <li>
              <Link to="/managejob" className="sidebar-item">
                <FontAwesomeIcon icon={faTasks} />
                <span>Manage Jobs</span>
              </Link>
            </li>
            <li>
              <Link to="/manageresume" className="sidebar-item">
                <FontAwesomeIcon icon={faFileAlt} />
                <span>Manage Resume</span>
              </Link>
            </li>
            <li>
              <Link to="/manage-company" className="sidebar-item">
                <FontAwesomeIcon icon={faBuilding} />
                <span>Manage Company</span>
              </Link>
            </li>
            <li>
              <Link to="/applicant" className="sidebar-item">
                <FontAwesomeIcon icon={faUsers} />
                <span>Applicants</span>
              </Link>
            </li>
            <li>
              <Link to="/addblog" className="sidebar-item">
                <FontAwesomeIcon icon={faPen} />
                <span>Add Blogs</span>
              </Link>
            </li>
            <li>
              <Link to="/addblog" className="sidebar-item">
                <FontAwesomeIcon icon={faList} />
                <span>Blog List</span>
              </Link>
            </li>
            <li>
              <Link to="/banner-update" className="sidebar-item">
                <FontAwesomeIcon icon={faImage} />
                <span>Update Banner</span>
              </Link>
            </li>
            <li>
              <Link to="/faq" className="sidebar-item">
                <FontAwesomeIcon icon={faQuestionCircle} />
                <span>Faq</span>
              </Link>
            </li>
            <li>
              <Link to="/adminprofile" className="sidebar-item">
                <FontAwesomeIcon icon={faUser} />
                <span>My Profile</span>
              </Link>
            </li>
            <li>
              <div className="sidebar-item" onClick={onLogout}>
                <FontAwesomeIcon icon={faSignOutAlt} />
                <span>Logout</span>
              </div>
            </li>
          </ul>
        </div>
      )}

      {/* Main content */}
      <div className="postjob-main-content">
        <CollapsibleResumeTable />
      </div>
      {/* Bottom Navigation */}
      <div className="bottom-nav">
        <ul>
          <li>
            <FontAwesomeIcon icon={faHome} />
            <span>Home</span>
          </li>
          <li onClick={toggleSidebar}>
            <FontAwesomeIcon icon={faBars} />
            <span>Menu</span>
          </li>
          <li>
            <FontAwesomeIcon icon={faBell} />
            <span>Notifications</span>
          </li>
          <li>
            <FontAwesomeIcon icon={faUser} />
            <span>Profile</span>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Manageresume;

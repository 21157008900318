import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import TablePagination from "@mui/material/TablePagination";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import DialogContentText from "@mui/material/DialogContentText";
import useSwr from "../../../../HOOKS/useSWR";
import useMutation from "../../../../HOOKS/useMutation";
import Swal from "sweetalert2";

function Row(props) {
  const { mutation } = useMutation();
  const {
    row,
    onDelete,
    onEdit,
    onFeatureToggle,
    onSponsoredToggle,
    fetureMutate,
  } = props;
  const [open, setOpen] = useState(false);

  const handleFeatureToggle = async () => {
    try {
      await mutation(`company/updatecompany/${row._id}`, {
        method: "PUT",
        body: {
          isFeature: !row.isFeature,
        },
      });
      fetureMutate();
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: error.message,
        icon: "error",
        confirmButtonText: "ok",
      });
    }
    onFeatureToggle(!row.isFeature);
  };

  const handleSponsoredToggle = async () => {
    try {
      await mutation(`company/updatecompany/${row._id}`, {
        method: "PUT",
        body: {
          isSponsor: !row.isSponsor,
        },
      });
      fetureMutate();
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: error.message,
        icon: "error",
        confirmButtonText: "ok",
      });
    }
    onSponsoredToggle(!row.isSponsor);
  };

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.companyName}
        </TableCell>
        <TableCell>
          <img
            src={row.logo}
            alt={row.companyName}
            style={{ maxWidth: "15%", maxHeight: "5%" }}
          />
        </TableCell>
        <TableCell align="right">
          <Switch
            checked={row.isFeature}
            onChange={handleFeatureToggle}
            color="primary"
            name="featured-switch"
            inputProps={{ "aria-label": "featured checkbox" }}
          />
        </TableCell>
        <TableCell align="right">
          <Switch
            checked={row.isSponsor}
            onChange={handleSponsoredToggle}
            color="primary"
            name="sponsored-switch"
            inputProps={{ "aria-label": "sponsored checkbox" }}
          />
        </TableCell>
        <TableCell align="right">
          <IconButton
            aria-label="edit"
            size="small"
            onClick={() => onEdit(row)}
            style={{ color: "blue" }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            size="small"
            onClick={() => onDelete(row._id)}
            style={{ color: "red" }}
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <div>Company Info: {row.companyInfo}</div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    companyName: PropTypes.string.isRequired,
    companyDetails: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    featured: PropTypes.bool.isRequired,
    sponsored: PropTypes.bool.isRequired,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onFeatureToggle: PropTypes.func.isRequired,
  onSponsoredToggle: PropTypes.func.isRequired,
};

export default function CollapsibleCompanyTable() {
  const { mutation } = useMutation();

  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [editCompanyName, setEditCompanyName] = useState("");
  const [editCompanyDetails, setEditCompanyDetails] = useState("");
  const [editImageUrl, setEditImageUrl] = useState("");
  const [editImageFile, setEditImageFile] = useState(null);
  const [deleteId, setDeleteId] = useState("");

  const generateUrl = (page, rowsPerPage, searchQuery) => {
    const params = new URLSearchParams();
    params.append("pageNo", page);
    params.append("perPage", rowsPerPage);
    if (searchQuery) {
      params.append("search", searchQuery);
    }
    return `company/getallcompany?${params.toString()}`;
  };

  const url = generateUrl(page, rowsPerPage, searchQuery);

  const { data: companies, isValidating, mutate: fetureMutate } = useSwr(url);
  const companyList = companies?.data?.data?.data || [];
  const count = companies?.data?.data?.totalCount || 0;

  const handleDelete = (id) => {
    setRowToDelete(rowToDelete);
    setDeleteConfirmationOpen(true);
    setDeleteId(id);
  };

  const handleDeleteConfirmed = async () => {
    setDeleteConfirmationOpen(false);
    try {
      const res = await mutation(`company/delete/${deleteId}`, {
        method: "DELETE",
      });
      fetureMutate();
      if (res) {
        Swal.fire({
          title: "Success",
          text: res.results.message,
          icon: "success",
          confirmButtonText: "ok",
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: error.message,
        icon: "error",
        confirmButtonText: "ok",
      });
    }
  };

  const handleDeleteCanceled = () => {
    setRowToDelete(null);
    setDeleteConfirmationOpen(false);
  };

  const handleEdit = (row) => {
    setRowToEdit(row);
    setEditCompanyName(row.companyName);
    setEditCompanyDetails(row.companyInfo);
    setEditImageUrl(row.logo);
    setEditImageFile(null);
    setEditDialogOpen(true);
  };

  const handleEditConfirmed = async () => {
    setEditDialogOpen(false);
    try {
      await mutation(`company/updatecompany/${rowToEdit._id}`, {
        method: "PUT",
        body: {
          companyName: editCompanyName,
          companyInfo: editCompanyDetails,
          logo: editImageUrl,
        },
      });
      fetureMutate();
      Swal.fire({
        title: "Success",
        text: "Company updated successfully",
        icon: "success",
        confirmButtonText: "ok",
      });
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: error.message,
        icon: "error",
        confirmButtonText: "ok",
      });
    }
  };

  const handleEditCanceled = () => {
    setRowToEdit(null);
    setEditDialogOpen(false);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setEditImageFile(file);
      setEditImageUrl(URL.createObjectURL(file));
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const debounce = (callback, wait = 500) => {
    let timeoutId = null;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        callback(...args);
      }, wait);
    };
  };
  const debounceSetSearchQuery = useCallback(
    debounce((searchTerm) => {
      setSearchQuery(searchTerm);
    }, 500),
    []
  );

  useEffect(() => {
    const url = generateUrl(page, rowsPerPage, searchQuery);
    fetureMutate(url);
  }, [page, rowsPerPage, searchQuery]);
  return (
    <TableContainer component={Paper}>
      <TextField
        label="Search"
        value={searchQuery}
        onChange={(e) => {
          setSearchQuery(e.target.value);
          debounceSetSearchQuery(e.target.value);
        }}
        fullWidth
        margin="normal"
      />
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Company Name</TableCell>
            <TableCell>Company Logo</TableCell>
            <TableCell>Feature</TableCell>
            <TableCell>Sponsored</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {companyList.map((row) => (
            <Row
              key={row._id}
              row={row}
              onDelete={handleDelete}
              onEdit={handleEdit}
              onFeatureToggle={() => fetureMutate()}
              onSponsoredToggle={() => fetureMutate()}
            />
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Dialog
        open={deleteConfirmationOpen}
        onClose={handleDeleteCanceled}
        aria-labelledby="delete-confirmation-dialog"
      >
        <DialogTitle id="delete-confirmation-dialog">
          Confirm Delete
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this company?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCanceled} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirmed} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={editDialogOpen}
        onClose={handleEditCanceled}
        aria-labelledby="edit-company-dialog"
      >
        <DialogTitle id="edit-company-dialog">Edit Company</DialogTitle>
        <DialogContent>
          <TextField
            label="Company Name"
            value={editCompanyName}
            onChange={(e) => setEditCompanyName(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Company Details"
            value={editCompanyDetails}
            onChange={(e) => setEditCompanyDetails(e.target.value)}
            fullWidth
            margin="normal"
          />
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="image-upload"
            type="file"
            onChange={handleImageChange}
          />
          <label htmlFor="image-upload">
            <Button variant="contained" color="primary" component="span">
              Upload Image
            </Button>
          </label>
          {editImageUrl && (
            <img
              src={editImageUrl}
              alt="Company Logo"
              style={{
                maxWidth: "100%",
                maxHeight: "200px",
                marginTop: "10px",
              }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditCanceled} color="primary">
            Cancel
          </Button>
          <Button onClick={handleEditConfirmed} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </TableContainer>
  );
}

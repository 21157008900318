import React, { useState } from "react";
import "./careeradvice.css"; // Import the CSS file
import great1 from "./icon/great1.svg";
import great2 from "./icon/great2.svg";
import great3 from "./icon/great3.svg";
import great4 from "./icon/great4.svg";
import blogcard1 from "./icon/blogcard1.png";
import blogcard2 from "./icon/blogcard2.png";
import blogcard3 from "./icon/blogcard3.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import BlogCard from "./blogCard";
import useSwr from "../../HOOKS/useSWR";
function Careeradvice() {
  const [activeIndex, setActiveIndex] = useState(0);

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    { question: "How can I effectively leverage my existing skills?",
       answer: 
       "Effectively leverage your skills by aligning them with job market demands. Tailor your resume, gain certifications, and showcase relevant experiences to stand out to employers."
      },
    { question: "What are Career growth opportunities available?",
       answer:
        "Our agency facilitates diverse career opportunities, connecting talented individuals with growth-focused roles across industries, domestically and internationally." 
      },
    { question: "Optimize salary, for the personal/career  growth?",
       answer: 
       "Optimizing salary involves research, negotiation, and market understanding. Our agency guides salary negotiation, industry insights, and maximizes earnings."
       },
    { question: "What are the support, your agency will provide?",
       answer: 
       "Our agency offers continuous support for your career journey, including resume writing, interview prep, coaching, and professional development resources."
       },
    { question: "How to stand out in the competitive job market?",
        answer: 
        "Standing out in a competitive job market requires showcasing unique strengths, tailoring application materials, and staying updated on industry trends."
        },
  ];

  

  const { data } = useSwr("blog?perPage=3&pageNo=0");
  const blogCardsData = data?.data?.data?.data || [];

  return (
    <>
      <div className="career-section-container">
        <h1>Career Advice</h1>

        <p>
          Unlock your full potential with our career advice content. From resume
          hacks to interview strategies, we offer expert insights tailored to
          your success. Explore articles on networking, professional
          development, and industry trends. Elevate your career journey with us!
          <br />
        </p>
      </div>
      <div className="career-card-container">
        <div className="career-card">
          <img src={great1} alt="Coffee Icon" className="career-icon" />
          <h3>Career Development</h3>
          <p>
            Expert career counseling and a personalized approach to skilling and
            talent development.
          </p>
        </div>
        <div className="career-card">
          <img src={great2} alt="Coffee Icon" className="career-icon" />
          <h3>Finding a Job</h3>
          <p>
            talentfolder: Find dream job. Explore, update resume, connect with
            employers, start career!
          </p>
        </div>
        <div className="career-card">
          <img src={great3} alt="Coffee Icon" className="career-icon" />
          <h3>Skills Enhancement</h3>
          <p>
            Empower your career journey with our Skills Enhancement resources,
            tailored for professional growth.
          </p>
        </div>
        <div className="career-card">
          <img src={great4} alt="great4" className="career-icon" />
          <h3>Professional Development</h3>
          <p>
            Elevate expertise, expand horizons with comprehensive Professional
            Development resources.
          </p>
        </div>
      </div>

      <div className="qa-section">
        <div className="qa-content">
          <div className="qa-heading">
            <p>FAQ's :</p>
            <h3>Frequently asked questions</h3>
            <div className="search-bar">
              <input type="text" placeholder="Search..." />
              <i className="fa fa-search"></i>
            </div>
          </div>
          <div className="infront-faq-right-side">
        {faqData.map((item, index) => (
          <div key={index} className="infront-faq-item">
            <div className="infront-faq-question" onClick={() => toggleAnswer(index)}>
              <span>{item.question}</span>
              {/* Arrow icon that rotates */}
              <span className={`infront-faq-icon ${activeIndex === index ? 'rotate' : ''}`}>
                ▼
              </span>
            </div>
            {activeIndex === index && (
              <p className="infront-faq-answer">{item.answer}</p>
            )}
          </div> 
        ))}
      </div>
    </div>
    </div>
      <div className="latest-blog-section">
        <div className="latest-blog-content">
          <h2>Latest Blog</h2>
          <p>
          Welcome to our blog, where we share insights, stories, and knowledge to inform, engage, and entertain you!
          </p>
        </div>
      </div>

      <div className="three-cards-section">
        {blogCardsData.map((card, index) => (
          <BlogCard key={index} data={card} />
        ))}
      </div>
    </>
  );
}

export default Careeradvice;

import React, { useEffect, useState } from "react";
import "./Offer.css";
import useMutation from "../../HOOKS/useMutation";
import Swal from "sweetalert2";

function Offer() {
  const [isScrolledIntoView, setIsScrolledIntoView] = useState(false);
  const [isAnimationTriggered, setIsAnimationTriggered] = useState(false);
  const [email, setEmail] = useState("");
  const [isSubscribed, setIsSubscribed] = useState("");
  const { mutation, isLoading } = useMutation();
  useEffect(() => {
    const handleScroll = () => {
      const offerSection = document.querySelector(".offer-container");
      if (offerSection && !isAnimationTriggered) {
        const rect = offerSection.getBoundingClientRect();
        const isVisible = rect.top <= window.innerHeight && rect.bottom >= 0;
        setIsScrolledIntoView(isVisible);

        if (isVisible) {
          setIsAnimationTriggered(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isAnimationTriggered]);
  const handleSubscribe = async () => {
    try {
      setIsSubscribed(true);
      const res = await mutation(`newsletter/subscribe`, {
        method: "POST",
        isAlert: true,
        body: {
          email: email,
        },
      });

      if (res?.results?.success) {
        Swal.fire({
          title: "Successfully Subscribed",
          text: "",
          icon: "success",
          confirmButtonText: "ok",
        });
        setEmail("");
        setIsSubscribed(false);
      }
    } catch (error) {
      throw error;
    }
  };
  return (
    <div className="offer-container">
      <div className={`offer ${isScrolledIntoView ? "animate" : ""}`}>
        <h2 className={`offer-heading ${isScrolledIntoView ? "animate" : ""}`}>
          Get Special Offers & Updates
        </h2>
        <h4 className={`paragraph ${isScrolledIntoView ? "animate" : ""}`}>
          Subscribe for job updates, discounts, and offers from TalentFolder.
          Enter your email below!
        </h4>
        <div
          className={`button-container ${isScrolledIntoView ? "animate" : ""}`}
        >
          <input
            type="email"
            className="email-holder"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button onClick={handleSubscribe} className="btn2">
            {isSubscribed ? "...Loading" : "Subscribe Now"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Offer;

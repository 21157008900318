import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet, Navigate } from "react-router-dom";
import { signInSuccess } from "../../redux/users/UserSlice";
import useSwr from "../../HOOKS/useSWR";
import useStore from "../../ZUSTAND";

const Privateroute = () => {
  const [loading, setLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const dispatch = useDispatch();
  const { data: user, isValidating, mutate } = useSwr(`user/currentuser`);
  const setMutate = useStore((state) => state.setMutate);
  useEffect(() => {
    if (user) {
      const userInfo = user.data.data.user;
      setIsLoggedIn(userInfo.role === "APPLICANT");
      dispatch(signInSuccess(userInfo));
      setMutate(mutate);
      setLoading(false);
    } else if (!isValidating) {
      setLoading(false);
    }
  }, [user, isValidating, dispatch]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return isLoggedIn ? <Outlet /> : <Navigate to="/signin" />;
};

export default Privateroute;

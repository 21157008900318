import Slider from "react-slick";
import React, { useState } from "react";
import { FaHome } from "react-icons/fa";
import { FaBuilding } from "react-icons/fa";
import { IoAnalyticsOutline } from "react-icons/io5";
import { GiChainLightning } from "react-icons/gi";
import { BsBank2 } from "react-icons/bs";
import { MdManageAccounts } from "react-icons/md";
import { MdSchool } from "react-icons/md";
import { FaRocket } from "react-icons/fa";
import { IoStatsChartSharp } from "react-icons/io5";
import { SiGoogledataproc } from "react-icons/si";
import { FaGlobeEurope } from "react-icons/fa";
import { SlArrowRight } from "react-icons/sl";
import { BiAccessibility } from "react-icons/bi";
import Swal from "sweetalert2";
import apple from "./Logo/apple.png";
import microsoft from "./Logo/microsoft.png";
import meta from "./Logo/meta.png";
import netflix from "./Logo/netflix.png";
import amazon from "./Logo/amazon.png";
import imageone from "./Logo/imageone.png";
import imagetwo from "./Logo/imagetwo.png";
import imagethree from "./Logo/imagethree.png";
import imagefour from "./Logo/imagefour.png";
import imagefive from "./Logo/imagefive.png";
import imagesix from "./Logo/imagesix.png";
import imageseven from "./Logo/imageseven.png";
import imageeight from "./Logo/imageeight.png";
import imagenine from "./Logo/imagenine.png";
import imageten from "./Logo/imageten.png";
import imageeleven from "./Logo/imageeleven.png";
import imagetwelve from "./Logo/imagetwelve.png";
import imagethirteen from "./Logo/imagethirteen.png";
import imagefourteen from "./Logo/imagefourteen.png";
import imagefifteen from "./Logo/imagefifteen.png";
import imagesixteen from "./Logo/imagesixteen.png";
import imageseventeen from "./Logo/imageseventeen.png";
import imageeighteen from "./Logo/imageeighteen.png";
import imagenineteen from "./Logo/imagenineteen.png";
import imagetwenty from "./Logo/imagetwenty.png";
import imagetwentyone from "./Logo/imagetwentyone.png";
import imagetwentytwo from "./Logo/imagetwentytwo.png";
import imagetwentythree from "./Logo/imagetwentythree.png";
import imagetwentyfour from "./Logo/imagetwentyfour.png";
import imagetwentyfive from "./Logo/imagetwentyfive.png";
import imagetwentysix from "./Logo/imagetwentysix.png";
import imagetwentyseven from "./Logo/imagetwentyseven.png";
import imagetwentyeight from "./Logo/imagetwentyeight.png";
import imagetwentynine from "./Logo/imagetwentynine.png";
import imagethirty from "./Logo/imagethirty.png";
import imagethirtyone from "./Logo/imagethirtyone.png";
import imagethirtytwo from "./Logo/imagethirtytwo.png";
import imagethirtythree from "./Logo/imagethirtythree.png";
import imagethirtyfour from "./Logo/imagethirtyfour.png";
import imagethirtyfive from "./Logo/imagethirtyfive.png";
import imagethirtysix from "./Logo/imagethirtysix.png";
import imagethirtyseven from "./Logo/imagethirtyseven.png";
import imagethirtyeight from "./Logo/imagethirtyeight.png";
import imagethirtynine from "./Logo/imagethirtynine.png";
import mobilebanner from "./Logo/mobilebanner.jpg";
import imagefourty from "./Logo/imagefourty.png";
import boy from "./Logo/boy.png";
import artify from "./Logo/artify.png";
import { icon } from "@fortawesome/fontawesome-svg-core";
import { useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../DATA/category";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ClickAwayListener } from "@mui/base";
import { LuRefreshCw } from "react-icons/lu";
import useSwr from "../../HOOKS/useSWR";
import useMutation from "../../HOOKS/useMutation";
let icons = [
  <FaHome />,
  <FaBuilding />,
  <IoAnalyticsOutline />,
  <GiChainLightning />,
  <BsBank2 />,
  <MdManageAccounts />,
  <MdSchool />,
  <FaRocket />,
  <IoStatsChartSharp />,
  <SiGoogledataproc />,
  <FaGlobeEurope />,
  <BiAccessibility />,
];

const Jobsearch = () => {
  const { mutation } = useMutation();
  const [skills, setSkills] = useState("");
  const [experience, setExperience] = useState("");
  const [location, setLocation] = useState("");
  const [filteredSkills, setFilteredSkills] = useState([]);
  const [filterlocation, setFilterlocation] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isskillOpen, setIsskillOpen] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const handleInputChange = (e) => {
    const input = e.target.value;
    setSkills(input);
  };
  const jobpostskills = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${BASE_URL}/jobpost/getjobpost?search=${skills}`,
        {
          // headers:{
          //   "Authorization":`Bearer ${token}`
          // }
        }
      );
      const data = response.data.data.data;
      if (skills === "") {
        setFilteredSkills([]);
      } else {
        const filteredData = data.filter((item) => {
          return !item.skills.includes(skills.toLowerCase());
        });
        setFilteredSkills(filteredData);
        setIsskillOpen(true);
      }
    } catch (error) {
      // console.log("unable to fetch");
    }
  };
  const { data } = useSwr(`jobcategory/getallcategory`);
  const industries = data?.data?.data || [];
  // const [industriess, setIndustriess] = useState(industries);

  // useEffect(() => {
  //   const industriesWithIcons = industries.map((industry, index) => ({
  //     ...industry,
  //     icon: icons[index],
  //   }));
  //   setIndustriess(industriesWithIcons);
  // }, []);
  industries.forEach((industry, index) => {
    industry.icon = icons[index];
  });
  // console.log(industries);
  const {
    data: banner,
    error,
    isValidating,
    mutate,
  } = useSwr(`events/getevents`);

  const {
    data: featureCompany,
    error: featureError,
    isValidating: featureLoading,
    mutate: featureMutate,
  } = useSwr(`company/getallcompany?feature=true`);

  const featureCompanydata = featureCompany?.data?.data?.data || [];
  const {
    data: sponsorCompany,
    error: sponsorError,
    isValidating: sponsorLoading,
    mutate: sponsorMutate,
  } = useSwr(`company/getallcompany?sponsor=true`);
  const sponsorCompanydata = sponsorCompany?.data?.data?.data || [];
  useEffect(() => {
    jobpostskills();
  }, [skills]);

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      const context = this;
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func.apply(context, args), delay);
    };
  };
  const Searchlocation = async (location, setFilterLocation) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${BASE_URL}/jobpost/getjobpost?location=${location}`,
        {
          // headers:{
          //   "Authorization":`Bearer ${token}`
          // }
        }
      );
      const data = response.data.data.data || [];
      if (location === "") {
        setFilterlocation("");
      } else {
        location.length && setFilterlocation(data);
        setIsOpen(true);
      }
    } catch (error) {}
  };

  useEffect(() => {
    const delayedSearch = debounce(Searchlocation, 1000);
    delayedSearch(location, setFilterlocation);
  }, [location]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let url = "/jobfilter";
    if (skills || location || experience) {
      if (skills && location && experience) {
        navigate(`${url}?sear=${skills}&locn=${location}&expr=${experience}`);
      } else if (skills && location) {
        navigate(`${url}?sear=${skills}&locn=${location}`);
      } else if (skills && experience) {
        navigate(`${url}?sear=${skills}&expr=${experience}`);
      } else if (location && experience) {
        navigate(`${url}?locn=${location}&expr=${experience}`);
      } else if (skills) {
        navigate(`${url}?sear=${skills}`);
      } else if (location) {
        navigate(`${url}?locn=${location}`);
      } else if (experience) {
        navigate(`${url}?expr=${experience}`);
      }
    }
  };
  const midpoint = !isValidating && Math.ceil(industries.length / 2);
  const buttons = !isValidating && industries.slice(0, midpoint);
  const buttons2 = !isValidating && industries.slice(midpoint);

  // industries
  // let buttons = [
  //   { name: "Remote", icon: <FaHome /> },
  //   { name: "MNC", icon: <FaBuilding /> },
  //   { name: "Analytics", icon: <IoAnalyticsOutline /> },
  //   { name: "Supply Chain", icon: <GiChainLightning /> },
  //   { name: "Banking", icon: <BsBank2 /> },
  //   { name: "Project Mgmt", icon: <MdManageAccounts /> },
  // ];
  // let buttons2 = [
  //   { name: "Internship", icon: <MdSchool /> },
  //   { name: "Startup", icon: <FaRocket /> },
  //   { name: "Sales", icon: <IoStatsChartSharp /> },
  //   { name: "DataScience", icon: <SiGoogledataproc /> },
  //   { name: "Fortune 500", icon: <FaGlobeEurope /> },
  // ];

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    speed: 400,
    cssEase: "linear",
    autoplaySpeed: 3000,
    pauseOnHover: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 940,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
        },
      },
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
        },
      },
    ],
  };
  let buttons3 = [
    { label: "All" },
    { label: "IT Services" },
    { label: "Technology" },
    { label: "BFSI" },
    { label: "BPM" },
    { label: "Professional Services" },
    { label: "Healthcare & Life Sciences" },
    { label: "Infrastructure, Transport & Real Estate" },
  ];
  const companyLogos = [
    {
      name: "Microsoft",
      logoUrl: microsoft,
      description:
        "Multinational technology company known for its internet-related services and products.",
      rating: 4.8,
      reviews: "19k",
    },
    {
      name: "Apple",
      logoUrl: apple,
      description:
        "American multinational technology company famous for its consumer electronics and software.",
      rating: 4.6,
      reviews: "22k",
    },
    {
      name: "Netflix",
      logoUrl: netflix,
      description:
        "American multinational technology company known for its software and consumer electronics.",
      rating: 4.2,
      reviews: "29k",
    },
    {
      name: "Amazon",
      logoUrl: amazon,
      description:
        "American multinational technology company specializing in e-commerce, cloud computing.",
      rating: 4.6,
      reviews: "19k",
    },
    {
      name: "Meta",
      logoUrl: meta,
      description:
        "American social media conglomerate corporation known for being the largest social media.",
      rating: 4.7,
      reviews: "29k",
    },
  ];
  const startupCompanies = [
    {
      name: "TechLaunch",
      logoUrl:
        "https://techlaunch.com/wp-content/uploads/2020/06/TL-Logo-1.png",
      description:
        "TechLaunch is a cutting-edge startup specializing in innovative technology solutions for businesses.",
      department: "Technology",
      rating: 4.3,
    },
    {
      name: "EcoTech",
      logoUrl:
        "https://www.ecotechservices.com/wp-content/uploads/2023/04/ecotech-weiss-logo.png",
      description:
        "EcoTech is dedicated to developing eco-friendly technology to promote sustainability and environmental conservation.",
      department: "Green Tech",
      rating: 4.5,
    },
    {
      name: "Healthify",
      logoUrl: "https://www.healthifyme.com/in/images/Logo.png",
      description:
        "Healthify is a healthcare startup focused on revolutionizing the way people manage their health and wellness.",
      department: "Healthcare",
      rating: 4.2,
    },
    {
      name: "Artify",
      logoUrl: artify,
      description:
        "Artify is a creative startup dedicated to empowering artists and making art accessible to all.",
      department: "Art & Design",
      rating: 4.4,
    },
    {
      name: "Travelify",
      logoUrl:
        "https://s3.ap-south-1.amazonaws.com/com.travclan.b2b2c/logo/5463/1709059818.780836/0.977482434195486/803328BB-CE81-4A32-90E4-E49CE092504C.jpeg",
      description:
        "Travelify is a travel-tech startup committed to simplifying travel planning and enhancing the travel experience.",
      department: "Travel & Tourism",
      rating: 4.7,
    },
    {
      name: "EdTech Solutions",
      logoUrl:
        "https://edtechsolutions.com/wp-content/uploads/ed-tech-header-logo.svg",
      description:
        "EdTech Solutions is an educational technology startup focused on providing innovative learning solutions for students and educators.",
      department: "Education",
      rating: 4.3,
    },
    {
      name: "FinTech Innovate",
      logoUrl:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRSniEfbQH2EuUH707F_uFCicu2UYTWprjVee97ZRRolA&s",
      description:
        "FinTech Innovate is disrupting the financial industry with its cutting-edge financial technology solutions.",
      department: "Finance",
      rating: 4.5,
    },
    {
      name: "FoodFusion",
      logoUrl: "/img/fusion.png",
      description:
        "FoodFusion aims to disrupt the food industry with its innovative fusion cuisine and meal delivery service.",
      department: "Food & Beverage",
      rating: 4.6,
    },
  ];
  const itRoles = [
    {
      role: "Full Stack Developer",
      vacancy: "1k+ jobs",
    },
    {
      role: "Data Analyst",
      vacancy: "2k+ jobs",
    },
    {
      role: "Cybersecurity Analyst",
      vacancy: "2.2k+ jobs",
    },
    {
      role: "IT Support Specialist",
      vacancy: "3k+ jobs",
    },
    {
      role: "Cloud Solutions Architect",
      vacancy: "1.8k+ jobs",
    },
    {
      role: "IT Project Manager",
      vacancy: "2.5k+ jobs",
    },
  ];
  const intervieqn = [
    {
      name: "TechLaunch",
      vacancy: "2k+ interviews",
    },
    {
      name: "Accenture",
      vacancy: "1.5k+ interviews",
    },
    {
      name: "TCS",
      vacancy: "2k+ interviews",
    },
    {
      name: "Infosys",
      vacancy: "2.2k+ interviews",
    },
    {
      name: "Tech Mahindra",
      vacancy: "2k+ interviews",
    },
    {
      name: "E-clerx",
      vacancy: "2.8k+ interviews",
    },
  ];
  // Array to store company information
  const companies = [
    {
      name: "Tech Innovators Inc.",
      currentlyHiring: " 104 are actively hiring",
      imageUrls: [imageone, imagetwo, imagethree, imagefour],
    },
    {
      name: "Creative Solutions Ltd.",
      currentlyHiring: " 109 are actively hiring",
      imageUrls: [imagefive, imagesix, imageseven, imageeight],
    },
    {
      name: "Innovative Enterprises",
      currentlyHiring: " 101 are actively hiring",
      imageUrls: [imagenine, imageten, imageeleven, imagetwelve],
    },
    {
      name: "Future Tech Corp.",
      currentlyHiring: " 124 are actively hiring",
      imageUrls: [imagethirteen, imagefourteen, imagefifteen, imagesixteen],
    },
    {
      name: "Smart Solutions LLC",
      currentlyHiring: " 114 are actively hiring",
      imageUrls: [imageseventeen, imageeighteen, imagenineteen, imagetwenty],
    },
    {
      name: "Global Innovators",
      currentlyHiring: " 150 are actively hiring",
      imageUrls: [
        imagetwentyone,
        imagetwentytwo,
        imagetwentythree,
        imagetwentyfour,
      ],
    },
    {
      name: "Tech Pioneers",
      currentlyHiring: " 184 are actively hiring",
      imageUrls: [
        imagetwentyfive,
        imagetwentysix,
        imagetwentyseven,
        imagetwentyeight,
      ],
    },
    {
      name: "Innovative Solutions Co.",
      currentlyHiring: " 144 are actively hiring",
      imageUrls: [imagetwentynine, imagethirty, imagethirtyone, imagethirtytwo],
    },
    {
      name: "Tech Visionaries",
      currentlyHiring: " 174 are actively hiring",
      imageUrls: [
        imagethirtythree,
        imagethirtyfour,
        imagethirtyfive,
        imagethirtysix,
      ],
    },
    {
      name: "Future Innovations",
      currentlyHiring: " 109 are actively hiring",
      imageUrls: [
        imagethirtyseven,
        imagethirtyeight,
        imagethirtynine,
        imagefourty,
      ],
    },
  ];
  const handleSubscribe = async () => {
    try {
      setIsSubscribed(true);
      const res = await mutation(`newsletter/subscribe`, {
        method: "POST",
        isAlert: true,
        body: {
          email: email,
        },
      });

      if (res?.results?.success) {
        Swal.fire({
          title: "Successfully Subscribed",
          text: "",
          icon: "success",
          confirmButtonText: "ok",
        });
        setEmail("");
        setIsSubscribed(false);
      }
    } catch (error) {
      setIsSubscribed(false);

      throw error;
    }
  };
  return (
    <div className="h-auto mt-20">
      <div className="container text-center flex flex-col items-center">
        <h1 className="font-medium text-4xl mx-1 md:mx-0 mb-3">
          Find your dream job now
        </h1>
        <div className="font-medium text-xl">
          5 lakh+ jobs for you to explore
        </div>
      </div>
      <div className="text-center mt-11">
        <div className="max-w-screen-lg mx-4 lg:mx-auto ">
          <form
            onSubmit={handleSubmit}
            className="px-16 md:px-20 flex flex-col md:flex-row items-center justify-between border md:rounded-full py-6 md:py-1 shadow-lg "
          >
            <div className="mb-4 flex justify-center items-center w-full md:w-auto relative ">
              <input
                type="text"
                id="skills"
                value={skills}
                onChange={handleInputChange}
                placeholder="Enter Skills/Designations"
                className="border-none  mt-0 md:mt-3 py-2 px:0 md:px-4 text-lg md:text-xl text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full"
              />
              {filteredSkills.length > 0 && isskillOpen && (
                <ClickAwayListener onClickAway={() => setIsskillOpen(false)}>
                  <ul className="mt-2 absolute top-20 left-0 md:-left-7 right-0 md:-right-11 bg-white shadow-lg rounded-xl z-10">
                    {filteredSkills.map((skill, index) => (
                      <li
                        onClick={() => setSkills(skill.title)}
                        key={index}
                        // key={index}
                        className="py-2 px-4 text-gray-800 border-b border-gray-200 hover:bg-gray-100 cursor-pointer last:border-none"
                      >
                        {skill.skills}
                      </li>
                    ))}
                  </ul>
                </ClickAwayListener>
              )}
            </div>

            <span class="text-gray-400 invisible lg:visible h-8 md:h-auto text-xl hidden md:inline">
              |
            </span>

            <div className="mb-4 md:mr-4 flex justify-center items-center w-full md:w-auto ">
              <select
                id="experience"
                value={experience}
                onChange={(e) => setExperience(e.target.value)}
                className="w-full  mt-0 lg:mt-3 bg-white py-2 text-lg md:text-xl text-gray-400 focus:outline-none"
              >
                <option value="">Select Experience:</option>
                <option value="1">0-1 years</option>
                <option value="3">1-3 years</option>
                <option value="5">3-5 years</option>
                <option value="10">5+ years</option>
              </select>
            </div>
            <span class="text-gray-400 invisible lg:visible h-8 md:h-auto text-xl hidden md:inline">
              |
            </span>

            <div className="mb-4 w-full md:w-auto relative">
              <input
                type="text"
                id="location"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                placeholder="Enter Location"
                className="border-none rounded-full py-2 px-0 md:px-4 mt-0 md:mt-3 text-lg md:text-xl text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full"
              />
              {filterlocation?.length > 0 &&
                isOpen &&
                filterlocation.map((el) => (
                  <ClickAwayListener onClickAway={() => setIsOpen(false)}>
                    <ul
                      key={el._id}
                      className="mt-2 absolute top-20 left-0 md:left-8 bg-white shadow-lg rounded-xl z-10 w-40"
                    >
                      <li
                        className="py-4 text-gray-800 border-b border-gray-200 hover:bg-gray-100 cursor-pointer last:border-none"
                        onClick={() => setLocation(el?.location?.city)}
                      >
                        {el?.location?.city}
                      </li>
                    </ul>
                  </ClickAwayListener>
                ))}
            </div>
            {/* <span class="text-gray-400 invisible lg:visible h-8 md:h-auto text-xl hidden md:inline">
              |
            </span> */}
            <div className="mb-4">
              <button
                onClick={handleSubmit}
                type="submit"
                className="bg-redTalent text-white font-bold py-3 px-28 md:px-8  mt-0 md:mt-3 rounded-lg md:rounded-full"
              >
                Search
              </button>
            </div>
          </form>
        </div>
      </div>

      {banner && (
        <div className="object-contain flex justify-center items-center md:justify-center mt-6 lg:mt-16 mb-6 lg:mx-auto mx-3 md:mx-0">
          <img
            src={banner?.data?.data?.eventimage}
            className="h-22 md:h-22 hidden md:block md:max-w-screen-lg"
          />
          <img
            src={banner?.data?.data?.eventimage || mobilebanner}
            className="h-22 md:h-22 block md:hidden md:max-w-screen-lg"
          />
        </div>
      )}
      <div className="flex justify-start lg:justify-center items-center flex-col mx-1  md:mx-10 gap-3 ml-3 md:ml-0">
        <div>
          <div className=" grid grid-cols-2 lg:flex lg:flex-wrap lg:justify-center mr-0 lg:mr-7">
            {isValidating ? (
              <p>...Loading</p>
            ) : (
              buttons.map((button, index) => (
                <Link
                  to={`/jobfilter?industryName=${button._id}`}
                  className="bg-white border shadow-lg rounded-lg px-4 text-center md:px-4 py-5 w-44 md:py-4 text-sm md:text-xs text-black flex justify-start md:justify-center items-center mr-3 mt-3 transition duration-300 ease-in-out transform hover:scale-105"
                >
                  <div className="mr-2 md:mr-2 md:inline text-2xl">
                    {button.icon}
                  </div>
                  {/* <{button.icon} className="mr-2 md:mr-2 md:inline" /> */}
                  <span className="text-[120%]">
                    {" "}
                    {button.industryName.split(" ")[0].split(",")[0]}
                  </span>
                  <SlArrowRight className="ml-0 md:ml-2 hidden md:inline" />
                </Link>
              ))
            )}
          </div>
          <div className="grid grid-cols-2 lg:flex lg:flex-wrap lg:justify-center mr-0 lg:mr-7">
            {isValidating ? (
              <p>...Loading</p>
            ) : (
              buttons2.map((button) => (
                <Link
                  to={`/jobfilter?industryName=${button._id}`}
                  className="bg-white border shadow-lg rounded-lg  px-4 md:px-4 py-5 md:py-4 text-sm md:text-xs text-black flex justify-start md:justify-center items-center mr-3 mt-3 transition duration-300 ease-in-out transform hover:scale-105"
                  // style={{ padding: "0.9rem 1rem" }}
                >
                  <div className="mr-2 md:mr-2 md:inline text-2xl">
                    {button.icon}
                  </div>
                  <span className="text-[120%]">
                    {" "}
                    {button.industryName.split(" ")[0].split(",")[0]}
                  </span>
                  <SlArrowRight className="ml-0 md:ml-2 hidden md:inline" />
                </Link>
              ))
            )}
          </div>
        </div>
      </div>
      <div className="mt-16 text-center ">
        <div>
          <h2 className="font-bold text-xl md:text-3xl mb-5 mx-4 md:mx-10">
            Top Companies Hiring Now
          </h2>
          <div className="container flex"></div>
        </div>
      </div>
      <div className="mx-4 md:mx-10">
        <Slider {...settings}>
          {companies.map((company) => (
            <div className="max-w-xs md:max-w-72 rounded overflow-hidden shadow-lg  bg-white mt-8 w-44 ">
              <div className="px-4 md:px-6 py-4 flex flex-col">
                <div className="font-bold text-xl flex items-center gap-2">
                  <small>{company.name}</small>
                  <SlArrowRight className="text-xs" />
                </div>
                <div className="text-gray-700 text-base text-start">
                  {company.currentlyHiring}
                </div>
              </div>
              <div className="px-4 md:px-6 py-4">
                <div className="grid grid-cols-4 gap-5">
                  <img
                    className="w-11 h-auto"
                    src={company.imageUrls[0]}
                    alt="first"
                  />
                  <img
                    className="w-11 h-auto"
                    src={company.imageUrls[1]}
                    alt="Second"
                  />
                  <img
                    className="w-11 h-auto"
                    src={company.imageUrls[2]}
                    alt="Third"
                  />
                  <img
                    className="w-11 h-auto"
                    src={company.imageUrls[3]}
                    alt="fourth"
                  />
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      {/* need to improve */}
      <div className="mt-8 md:mt-16 text-center mx-4 md:mx-10">
        <div className="">
          <h1 className="font-bold  text-xl md:text-3xl mb-5">
            Featured Companies Actively Hiring
          </h1>
          <div className="flex flex-col md:flex-row gap-2 md:gap-4">
            {featureLoading ? (
              <p>Loading...</p>
            ) : (
              featureCompanydata.map((company) => (
                <div className="bg-white border rounded-lg overflow-hidden mx-5 md::ml-0 md:w-96">
                  <div
                    className="text-center pt-0 md:pt-4"
                    style={{ aspectRatio: "3/2" }}
                  >
                    <img
                      src={company.logo}
                      alt="Company Logo"
                      className="w-24 mx-auto"
                    />
                  </div>
                  <div className="px-6 py-4">
                    <div className="font-bold text-xl mb-2 -mt-16 md:-mt-0">
                      {company.companyName}
                    </div>
                    <div className="flex items-center justify-center mb-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-yellow-500 mr-1"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 3.4l-1.647 3.961L4.4 7.227l3.961 1.647L8.6 14 10 12.198l1.4 1.802 1.239-4.125L15.6 7.227l-3.953.134L10 3.4zm0 12.466L4 16.6V8.4l2-1 4-10 4 10 2 1v8.2l-6-2.2z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className="text-gray-700">3.6*</span>
                      <span className="ml-1">|</span>
                      <span className="text-gray-500 ml-2">15k reviews</span>
                    </div>
                    <div className="text-gray-700 text-xs">
                      {company.companyInfo}
                    </div>
                  </div>
                  <div className="px-6 py-4">
                    <button
                      onClick={() =>
                        navigate(
                          `/jobfilter?companyName=${company.companyName}`
                        )
                      }
                      className="bg-redTalent text-white font-bold py-2 px-4 rounded hover:transition-transform transform hover:scale-110"
                    >
                      View Job
                    </button>
                  </div>
                </div>
              ))
            )}
          </div>
          {/* <button className="px-2 py-2 w-52 border rounded-2xl font-medium mt-6 text-redTalent mx-4 md:mx-10 hover:transition-transform transform hover:scale-110">
            View All Companies
          </button> */}
        </div>
      </div>
      {/* end */}
      {/* need improve */}
      <div className="mt-8 md:mt-16 mx-4 md:mx-10 shadow-lg">
        <div className="bg-redTalent border flex flex-col md:flex-row justify-center items-center gap-2 p-5 md:py-6">
          <div className="flex justify-center items-center h-32 w-32 md:ml-0 bg-gray-200 rounded-full overflow-hidden">
            <img
              src="https://talentfolder.com/img/logo.png"
              alt="logo"
              className=""
            />
          </div>

          <div className="md:px-8 flex flex-col justify-center items-center md:items-start text-center">
            <p className="text-xl font-bold mb-2 md:text-2xl text-white">
              Introducing a career platform for college students & fresh grads
            </p>
            <p className="text-base md:text-base text-white">
              Explore contests, webinars, take aptitude tests, prepare for your
              dream career & find jobs & internships
            </p>
          </div>

          <div className="flex justify-center items-center md:items-center">
            <Link
              to={"/careeradvice"}
              className="p-2 md:px-4 md:py-2 border rounded-2xl font-medium bg-white text-black hover:bg-redTalent hover:text-white transition-colors duration-300 ease-in-out"
            >
              Learn More
            </Link>
          </div>
        </div>
      </div>
      {/* end */}
      <div
        className="flex flex-col lg:flex-row justify-center items-center md:justify-between mt-16 mb-16 mx-4 md:mx-10 border rounded-lg shadow-lg"
        style={{
          background:
            "linear-gradient(180deg, rgba(255, 240, 226, 0) 0%, #FFF0E2 100%)",
        }}
      >
        <img
          src={boy}
          className="block md:hidden h-32 md:h-72  mt-2"
          alt="Role Collection Image"
        />
        <img
          src="https://static.naukimg.com/s/0/0/i/role-collection-ot.png"
          className=" hidden md:block h-32 md:h-72 ml-8 mt-2"
          alt="Role Collection Image"
        />
        <div className=" p-4 md:p-6 mr-0 lg:mr-8 grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-6 cursor-pointer">
          {itRoles.map((it) => (
            <div
              onClick={() => navigate(`/jobfilter?sear=${it.role}`)}
              className="bg-white p-4 w-64 shadow-lg rounded-lg border hover:transition-transform transform hover:scale-110"
            >
              <h2 className="text-lg font-semibold mb-2">{it.role}</h2>
              <p className="text-gray-600">{it.vacancy}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="text-center">
        <h2 className="text-center font-bold text-xl md:text-3xl mb-5">
          Sponsored Companies
        </h2>
        {/* need improve */}
        {/* <div className="flex flex-wrap flex-col font-serif md:flex-row gap-1 md:gap-2 mx-4 md:mx-10 my-5 md:my-10">
          {buttons3.map((button, index) => (
            <button
              key={index}
              className="text-sm text-amber-800 py-1 md:py-2 px-1 md:px-6 rounded-full border hover:transition-transform transform hover:scale-110"
            >
              {button.label}
            </button>
          ))}
        </div> */}
        {/* end */}

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mx-10 ">
          {sponsorLoading ? (
            <p>...Loading</p>
          ) : (
            sponsorCompanydata.map((company) => (
              <div className="border rounded-lg overflow-hidden w- h-56 container shadow-xl transition-transform transform hover:rotate-1">
                <div className="text-center pt-4">
                  <img
                    src={company.logo}
                    alt="Company Logo"
                    className="w-24 mx-auto transition-transform transform hover:scale-110 h-[40px]"
                  />
                </div>
                <div className="px-6 py-4 text-center">
                  <div className="font-bold text-xl mb-2">
                    {company.companyName}
                  </div>
                  <div className="flex items-center justify-center mb-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 text-yellow-500 mr-1"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 3.4l-1.647 3.961L4.4 7.227l3.961 1.647L8.6 14 10 12.198l1.4 1.802 1.239-4.125L15.6 7.227l-3.953.134L10 3.4zm0 12.466L4 16.6V8.4l2-1 4-10 4 10 2 1v8.2l-6-2.2z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="text-gray-700">4.9*</span>
                    <span className="ml-1">|</span>
                    <span className="text-gray-500 ml-2">14k+ reviews</span>
                  </div>
                  <div className="px-6 py-4">
                    <button
                      onClick={() =>
                        navigate(
                          `/jobfilter?companyName=${company.companyName}`
                        )
                      }
                      className="bg-redTalent text-white font-bold py-2 px-4 rounded hover:transition-transform transform hover:scale-110"
                    >
                      View Job
                    </button>
                  </div>
                  {/* <div className="text-white text-xs border rounded-full flex items-center justify-center mt-7 shadow-md bg-redTalent">
                  <button className="p-1">IT service & Consulting</button>
                </div>
                <div className="text-gray-700 text-xs border rounded-full flex items-center justify-center mt-2 shadow-md">
                  <button className="p-1">Hardware & Networking</button>
                </div> */}
                </div>
              </div>
            ))
          )}
        </div>
        {/* <button className="px-2 py-2 w-52 border rounded-2xl font-medium mt-6 text-redTalent mx-10 hover:transition-transform transform hover:scale-110">
          View All Companies
        </button> */}
      </div>
      <div
        className="relative flex flex-col lg:flex-row justify-center items-center md:justify-between mt-16 mb-16 mx-4 md:mx-10 border rounded-lg shadow-lg"
        style={{
          background:
            "linear-gradient(180deg, rgba(255, 240, 226, 0) 0%, #FFF0E2 100%)",
        }}
      >
        <img
          src="https://static.naukimg.com/s/0/0/i/ab-interview-ot.png"
          className="h-32 md:h-72 ml-8 mt-2"
          alt="Role Collection Image"
        />
        <div className=" p-4 md:p-6 grid grid-cols-1 ml-11 md:ml-0 md:grid-cols-2 gap-4 md:gap-6 mr-11 cursor-pointer">
          {intervieqn.map((comapany) => (
            <div
              onClick={() =>
                navigate(`/jobfilter?companyName=${comapany.name}`)
              }
              className="bg-white p-4 shadow-lg rounded-lg border w-52 hover:transition-transform transform hover:scale-110"
            >
              <h2 className="text-lg font-semibold mb-2">{comapany.name}</h2>
              <p className="text-gray-600">{comapany.vacancy}</p>
            </div>
          ))}
        </div>
      </div>
      {/* need improve */}
      <div className="mt-8 md:mt-16 mx-4 md:mx-10 shadow-lg">
        <div className="bg-redTalent border flex flex-col md:flex-row justify-center items-center gap-2 p-5 md:py-6">
          <div className="flex justify-center items-center h-32 w-32 md:ml-0 bg-gray-200 rounded-full overflow-hidden">
            <img
              src="https://talentfolder.com/img/logo.png"
              alt="logo"
              className=""
            />
          </div>

          <div className="md:px-8 flex flex-col justify-center items-center md:items-start text-center">
            <p className="text-xl font-bold mb-2 md:text-2xl text-white">
              Accelerate your job search with our services
            </p>
            <p className="text-base md:text-base text-white">
              Services to help you get hired, faster: from preparing your CV,
              getting recruiter attention, finding the right jobs, and more!
            </p>
          </div>

          <div className="flex justify-center items-center md:items-center">
            <Link
              to={`/jobfilter`}
              className="p-2 md:px-4 md:py-2 border rounded-2xl font-medium bg-white text-black hover:bg-redTalent hover:text-white transition-colors duration-300 ease-in-out"
            >
              Learn more
            </Link>
          </div>
        </div>
      </div>
      {/* end */}
      <div
        className="flex flex-col lg:flex-row justify-between md:justify-stretch mt-16 mb-5 md:mb-16 border pl-4 lg:pl-0 rounded-lg shadow-lg mx-4 lg:mx-10"
        style={{
          background:
            "linear-gradient(180deg, rgba(255, 240, 226, 0) 0%, #FFF0E2 100%)",
        }}
      >
        <div className="lg:w-3/4 p-1 lg:p-8 flex flex-col justify-center mt-5 md:mt-0">
          <h2 className="text-2xl md:text-3xl font-bold mb-4">
            10M+ Happy Applicants
          </h2>
          <p className="text-base text-justify mr-3 md:text-lg mb-4">
            Join our ever-growing family today and discover why millions have
            chosen us. Experience the benefits of being part of a vibrant,
            supportive community that thrives on innovation and excellence.
          </p>
          <div className="flex flex-col gap-3 md:gap-3 lg:flex-row">
            <input
              type="email"
              placeholder="Enter your email"
              className="border border-gray-400 rounded-lg px-4 md:py-1 py-2 focus:outline-none focus:border-blue-500 w-auto md:w-72"
              onChange={(e) => setEmail(e.target.value)}
            />
            <button
              onClick={handleSubscribe}
              className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:outline-none"
            >
              {isSubscribed ? "Loading..." : "Subscribe"}
            </button>
          </div>
        </div>
        {/* <div className="mr-2"> */}
        <img
          src="/img/adver.png"
          className="h-auto  w-full mr-0 md:mr-10 md:w-[50%] object-cover"
          alt="Role Collection Image"
        />
        {/* </div> */}
      </div>
    </div>
  );
};

export default Jobsearch;

import React from 'react';
import loaderImg from './construction.png'; // Import the PNG image file
import './loader.css'; // Import CSS file for styling (optional)

function Loader() {
  return (
    <div className="loader-container">
      <img src={loaderImg} alt="Loader" className="loader-img" />
    </div>
  );
}
export default Loader;
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faEye } from "@fortawesome/free-regular-svg-icons";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../DATA/category";
import { useDispatch, useSelector } from "react-redux";
import {
  signinstart,
  signInFailure,
  signInSuccess,
  updateUserSuccess,
} from "../../redux/users/UserSlice";
import Swal from "sweetalert2";

// const arrayCounty = ["India", "UAE", "Singapore", "Middle East"];
const Signin = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [inputLength, setInputLength] = useState(0);
  const [errors, setErrors] = useState("");
  const { loading, error, currentUser } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const role = searchParams.get("role");
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is Required"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: async (values) => {
      const data = JSON.stringify(values);
      try {
        if (role === "admin") {
          if (values.email !== "admin@talentfolder.com") {
            return Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Only Admin Can Login Here",
            });
          }
        }
        if (role === "candidate") {
          if (values.email === "admin@talentfolder.com") {
            return Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Only Applicant Can Login Here",
            });
          }
        }
        dispatch(signinstart());
        const response = await axios.post(`${BASE_URL}/user/signin`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        const userinfo = response.data.data.user;
        dispatch(signInSuccess(userinfo));
        const token = response.data.data.token;
        if (token) {
          localStorage.setItem("token", token);
        }

        if (response?.data?.data?.user?.role === "ADMIN") {
          navigate("/dashboard");
        }
        if (response?.data?.data?.user?.role === "APPLICANT") {
          navigate("/applicantprofile");
        }
      } catch (error) {
        if (error.response) {
          setErrors(error.response.data.error.message);
        } else {
          // console.log("Error: not able to send");
        }
      }
    },
  });
  useEffect(() => {
    // Check if user is already logged in
    const token = localStorage.getItem("token");
    if (currentUser) {
      if (currentUser.role === "ADMIN") {
        navigate("/dashboard");
      }
      // Check If role is user then navidate to
      else if (currentUser.role === "APPLICANT") {
        navigate("/jobfilter");
      }
    }
  }, [currentUser, navigate]);
  const togglePasswordVisibility = () => {
    inputLength && setShowPassword(!showPassword);
  };
  // const toggleCountry = () => {
  //   setIsOpen(!isOpen);
  // };
  return (
    <>
      <section
        className="w-full bg-center bg-cover bg-no-repeat h-screen "
        style={{
          backgroundImage: `url('/img/login-bg.png')`,
          backgroundAttachment: "fixed",
        }}
      >
        <div className="flex flex-col justify-center items-center px-6 py-12 lg:px-8">
          <div className="  bg-white border rounded-xl border-slate-300 w-full md:w-[40%] ld:w-[39%] py-10 px-5 md:px-5 lg:px-0">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
              <img
                className="mx-auto h-16 w-60"
                src="/img/logo.png"
                alt="Your Company"
              />
              <h2 className="mt-4 text-center text-lg font-semibold leading-9 tracking-tight text-black-600">
                Welcome! Nice to see you again
              </h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
              <form onSubmit={formik.handleSubmit} className="space-y-6">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Email Address<span className="text-redTalent">*</span>
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      className="block w-full rounded-md border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring--600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  {formik.touched.email && formik.errors.email ? (
                    <div className="text-redTalent">{formik.errors.email}</div>
                  ) : null}
                  {/* {errors ? (
                    <div className="text-redTalent">{errors}</div>
                  ) : null} */}
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Password<span className="text-redTalent">*</span>
                  </label>
                  <div className="mt-2 relative">
                    <input
                      id="password"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      onChange={(e) => {
                        formik.handleChange(e);
                        setInputLength(e.target.value.length);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                      className="block w-full rounded-md px-4 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    <button
                      type="button"
                      className="absolute inset-y-0 right-0 pr-3 flex items-center"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? (
                        <FontAwesomeIcon icon={faEye} />
                      ) : (
                        <FontAwesomeIcon icon={faEyeSlash} />
                      )}
                    </button>
                  </div>
                  {formik.touched.password && formik.errors.password ? (
                    <div className="text-redTalent">
                      {formik.errors.password}
                    </div>
                  ) : null}
                  {errors ? (
                    <div className="text-redTalent">{errors}</div>
                  ) : null}
                </div>

                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-redTalent px-3 py-1.5 text-md font-semibold leading-6 text-white shadow-sm hover:bg-white hover:border hover:border-redTalent hover:text-redTalent focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Login Now
                  </button>
                </div>
              </form>
            </div>
            <div className="flex flex-col lg:flex-row items-center justify-between px-2 lg:px-16 pt-10 ">
              {role === "candidate" && (
                <div className="flex gap-2">
                  <span className="font-medium">Already Have Password</span>
                  <Link
                    to={
                      role === "candidate" && "/candidatesignup"
                      // : "/requitersignup"
                    }
                    className="text-redTalent hover:underline font-semibold"
                  >
                    Signup?
                  </Link>
                </div>
              )}
              {role === "candidate" && (
                <div className="hover:text-redTalent mt-5 lg:mt-0 font-medium">
                  <button
                    onClick={(e) => {
                      navigate("/forgetpassword");
                    }}
                  >
                    Lost Password?
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Signin;

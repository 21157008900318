import React, { useState, useEffect } from "react";

export const array = [
  {
    id: "1",
    img: "img/icon/categories1.svg",
    title: "Development & IT",
    name: "958 Skills",
  },
  {
    id: "2",
    img: "img/icon/categories7.svg",
    title: "Design & Creative",
    name: "515 Skills",
  },
  {
    id: "3",
    img: "img/icon/categories3.svg",
    title: "Digital Marketing",
    name: "486 Skills",
  },
  {
    id: "4",
    img: "img/icon/categories4.svg",
    title: "Writing & Translati...",
    name: "956 Skills",
  },
  {
    id: "5",
    img: "img/icon/categories5.svg",
    title: "Music & Video",
    name: "662 Skills",
  },
  {
    id: "6",
    img: "img/icon/categories6.svg",
    title: "Video & Animation",
    name: "256 Skills",
  },
  {
    id: "7",
    img: "img/icon/categories7.svg",
    title: "Engineering & Arch",
    name: "756 Skills",
  },
  {
    id: "8",
    img: "img/icon/categories8.svg",
    title: "Finance & Account...",
    name: "958 Skills",
  },
  {
    id: "9",
    img: "img/icon/categories5.svg",
    title: "Music & Video",
    name: "662 Skills",
  },
  {
    id: "10",
    img: "img/icon/categories6.svg",
    title: "Video & Animation",
    name: "256 Skills",
  },
  {
    id: "11",
    img: "img/icon/categories7.svg",
    title: "Engineering & Arch",
    name: "756 Skills",
  },
  {
    id: "12",
    img: "img/icon/categories8.svg",
    title: "Finance & Account...",
    name: "958 Skills",
  },
];

export const BASE_URL = "https://talent.eztech.it/api/v1";
// export const BASE_URL = "http://localhost:5000/api/v1";

// Custom hook for debouncing
export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

import React from "react";
import styled from "styled-components";
import backgroundImage from "./misusebg.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const PageWrapper = styled.div`
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const PrivacyContainer = styled.div`
  padding: 40px 20px;
  max-width: 900px;
  margin: 40px auto;
  background: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-family: "Roboto", sans-serif;
  color: #333;

  @media (max-width: 768px) {
    padding: 20px 15px;
    margin: 20px auto;
  }
`;

const PrivacyHeader = styled.h1`
  font-size: 2.5em;
  text-align: center;
  color: #2c3e50;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    font-size: 2em;
    margin-bottom: 20px;
  }
`;

const PrivacySection = styled.section`
  margin-bottom: 30px;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const SectionTitle = styled.h2`
  font-size: 1.8em;
  color: #34495e;
  margin-bottom: 15px;
  position: relative;
  padding-bottom: 10px;

  &::after {
    content: "";
    width: 50px;
    height: 3px;
    background: #fe4324;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  @media (max-width: 768px) {
    font-size: 1.5em;
    margin-bottom: 10px;
    padding-bottom: 5px;

    &::after {
      width: 40px;
      height: 2px;
    }
  }
`;

const SectionContent = styled.p`
  color: #7f8c8d;
  font-size: 1em;
  line-height: 1.8;
  margin: 0;
  
  @media (max-width: 768px) {
    font-size: 0.9em;
  }
`;

const TickMark = styled(FontAwesomeIcon)`
  color: #dc143c;
  margin-right: 10px;
  margin-top: 6px;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start;

  @media (max-width: 768px) {
    font-size: 0.9em;
  }
`;

function Misuse() {
  return (
    <>
      <PageWrapper>
        <PrivacyContainer>
          <PrivacyHeader>Misuse Of Our Brands.</PrivacyHeader>
          <PrivacySection>
            <SectionContent></SectionContent>
          </PrivacySection>
          <PrivacySection>
            <SectionContent>
              <ul>
                <ListItem>
                  <TickMark icon={faCheck} />
                  <span>
                    fraud attempts by misuse brands Talent Folder LLC.
                  </span>
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  <span>
                    Please be aware that it happens increasingly often that
                    people who are in no way connected to the Talent Folder LLC,
                    pretend vis-à-vis the outside world to be part of one of the
                    Talent Folder LLC brands. Without our permission, they pose
                    as a recruiter of a Talent Folder LLC company by contacting
                    people (e.g. via email, letter, phone, social media) about a
                    “suitable position” (either a real vacancy of a Talent
                    Folder LLC company or a fake one); or try to tempt people to
                    contact them by placing fake job adverts on (employment)
                    websites, using the name of a Talent Folder LLC brand.
                  </span>
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  <span>
                    The persons concerned request their “potential victims” to
                    provide them with all kinds of personal information and to
                    make (a) payment(s). It should be noted that none of the
                    Talent Folder LLC brands will ever request payment of any
                    kind or a copy of a credit card or debit card from a
                    candidate during a recruitment or job search process.
                  </span>
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  <span>
                    If you become aware of misuse of the brands of the Talent
                    Folder LLC and in particular of fraud attempts, please
                    contact us as soon as possible.
                  </span>
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  <span>
                    In case of brand misuse in the US, please report via to this
                    email address. For misuse in other countries than the US,
                    please reach out to the local Talent Folder LLC
                    organization.
                  </span>
                </ListItem>
              </ul>
            </SectionContent>
          </PrivacySection>
        </PrivacyContainer>
      </PageWrapper>
    </>
  );
}

export default Misuse;

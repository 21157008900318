import React from "react";
import styled from "styled-components";
import backgroundImage from "./privacybg.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const PageWrapper = styled.div`
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const PrivacyContainer = styled.div`
  padding: 40px 20px;
  max-width: 900px;
  margin: 40px auto;
  background: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-family: "Roboto", sans-serif;
  color: #333;

  @media (max-width: 768px) {
    padding: 20px 15px;
    margin: 20px auto;
  }
`;

const PrivacyHeader = styled.h1`
  font-size: 2.5em;
  text-align: center;
  color: #2c3e50;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    font-size: 2em;
    margin-bottom: 20px;
  }
`;

const PrivacySection = styled.section`
  margin-bottom: 30px;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const SectionTitle = styled.h2`
  font-size: 1.8em;
  color: #34495e;
  margin-bottom: 15px;
  position: relative;
  padding-bottom: 10px;

  &::after {
    content: "";
    width: 50px;
    height: 3px;
    background: #fe4324;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  @media (max-width: 768px) {
    font-size: 1.5em;
    margin-bottom: 10px;
    padding-bottom: 5px;

    &::after {
      width: 40px;
      height: 2px;
    }
  }
`;

const SectionContent = styled.p`
  color: #7f8c8d;
  font-size: 1em;
  line-height: 1.8;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 0.9em;
  }
`;

const TickMark = styled(FontAwesomeIcon)`
  color: #dc143c;
  margin-right: 10px;
  margin-top: 6px;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start;

  @media (max-width: 768px) {
    font-size: 0.9em;
  }
`;

function Privacy() {
  return (
    <>
      <PageWrapper>
        <PrivacyContainer>
          <PrivacyHeader>Privacy Policy</PrivacyHeader>
          <PrivacySection>
            <SectionTitle>Introduction</SectionTitle>
            <SectionContent>
              We, Talent Folder LLC, are committed to protecting and respecting
              your privacy. We operate on the principle that your personal
              information belongs to you and only you can decide who you want to
              share it with and why. This is fundamental to the way we work, and
              we are committed to providing a secure environment for you to
              store your personal information and share it with others when you
              want to.
            </SectionContent>
            <br></br>
            <SectionContent>
              Talent Folder LLC values the confidentiality of the information we
              collect from you. We are committed to protecting and respecting
              your personal data privacy.
            </SectionContent>
          </PrivacySection>
          <PrivacySection>
            <SectionTitle>Information We Collect</SectionTitle>
            <SectionContent>
              Talent Folder LLC values the confidentiality of the information we
              collect from you. We are committed to protecting and respecting
              your personal data privacy.
              <ul>
                <ListItem>
                  <TickMark icon={faCheck} />
                  <span>
                    Personal information refers to any information whether
                    recorded in a material form or not, from which the identity
                    of an individual is apparent or can be reasonably and
                    directly ascertained by the entity holding the information,
                    or when put together with other information would directly
                    and certainly identify an individual.
                  </span>
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  <span>
                    We may collect, process, store, and use personal or
                    sensitive information to ensure effective service, such as
                    but not limited to the following:
                  </span>
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  <span>
                    Basic information that directly ascertains the identity of
                    an individual; this includes but is not limited to your
                    name, address, contact information, email address, age,
                    gender, date of birth, marital status, nationality, and
                    organization you work for.
                  </span>
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  <span>Legal and financial information.</span>
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  <span>
                    Billing information such as cardholder name, credit card
                    number, and expiry date.
                  </span>
                </ListItem>

                <ListItem>
                  <TickMark icon={faCheck} />
                  <span>
                    Data is collected through various means including verbal,
                    written, telephonic, or electronic forms. All information
                    that has been provided from the first contact with Talent
                    Folder LLC to the services provided and billing process
                    becomes part of client data.
                  </span>
                </ListItem>
              </ul>
            </SectionContent>
          </PrivacySection>
          <PrivacySection>
            <SectionTitle>
              When do we collect your Personal Information
            </SectionTitle>
            <SectionContent>
              We may collect and record personally identifiable information when
              you voluntarily provide it to us when engaging our services.
              <ul>
                <ListItem>
                  <TickMark icon={faCheck} />
                  If the personal information was provided by a minor or one who
                  is not legally capable of entering into contractual
                  transactions without parental or guardian consent, the parent
                  or guardian shall be responsible in giving the consent
                  required under this Data Privacy Policy and in correcting any
                  information given or in informing us that the information
                  should be disregarded or deleted.
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  If you provide us with personal information of other people in
                  connection with the services we offer, you hereby represent
                  that you have the authority to do so and have secured consent
                  to permit us to use the information in accordance with this
                  Data Privacy Policy.
                </ListItem>
              </ul>
            </SectionContent>
          </PrivacySection>
          <PrivacySection>
            <SectionTitle>How your data are stored and secured</SectionTitle>
            <SectionContent>
              <ul>
                <ListItem>
                  <TickMark icon={faCheck} />
                  We make all reasonable arrangements to ensure that personal
                  information in our possession or under our control is
                  protected against unauthorized access, collection, use,
                  disclosure, copying, modification, disposal or similar risks.
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  The transmission of information via the Internet is never
                  completely secure. Although we do our best to protect the
                  information using standard protocols and encryption, we cannot
                  guarantee the security of the data transmitted to us via
                  email, including completed forms sent via email. Any such
                  transmission is at your own risk.
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  On receiving information, we have established appropriate
                  physical, technical, and organizational security measures to
                  protect the personal information provided to us and to prevent
                  unauthorized access. These safeguards are regularly reviewed
                  to protect against unauthorized access, disclosure and
                  improper use of your information, and to maintain the accuracy
                  and integrity of the data. We will adapt and implement the
                  necessary changes for security measures to ensure continuous
                  security of your personal information.
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  Personal information that is no longer necessary will either
                  be deleted or anonymized.
                </ListItem>
              </ul>
            </SectionContent>
          </PrivacySection>
          <PrivacySection>
            <SectionTitle>
              Processing of Information by third party and links to other
              websites
            </SectionTitle>
            <SectionContent>
              This Data Privacy Policy shall not apply to third party service
              providers and to other websites. The processing of your personal
              information on the third party’s sites is subject to their
              respective Data Privacy Policy. In no event will Talent Folder LLC
              be responsible for the information these third parties will
              collect and process, neither will Talent Folder LLC be liable for
              any loss, damage or injury arising out of or in connection with
              the use of or reliance on such third party.
            </SectionContent>
          </PrivacySection>
          <PrivacySection>
            <SectionTitle>
              What are your rights under the Data Privacy Act of 2012
            </SectionTitle>
            <SectionContent>
              Under the Data Privacy Act of 2012, you have the following rights
              in connection with the processing of your personal data:
              <ul>
                <ListItem>
                  <TickMark icon={faCheck} />
                  to be informed if your personal data is being or has been
                  processed;
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  to object to the processing of your personal data or withdraw
                  consent that may have been given for direct marketing,
                  training, or research.
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  opt not to receive any marketing information from us.
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  to reasonable access, upon written request, to the contents of
                  your personal data that was processed and the manner by which
                  this was processed, as well as the sources from which this was
                  obtained, the recipients, and reasons for disclosure.
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  to rectify any error in your personal data and if warranted,
                  request immediate rectification, unless the request is
                  vexatious or otherwise unreasonable.
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  to suspend, withdraw, or order the blocking, removal, or
                  destruction of your personal data from our database if your
                  personal data is incomplete, outdated, false, or unlawfully
                  obtained; being used for purposes not authorized by you;
                  subject to unlawful data processing; or if your rights have
                  been violated.
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  to obtain from us a copy of the data that is processed in an
                  electronic or structured format.
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  to be indemnified for any damages sustained due to inaccurate,
                  incomplete, outdated, false, unlawfully obtained, or
                  unauthorized use of personal data.
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  to file a complaint about the processing of your personal
                  information; if we do not satisfactorily deal with your
                  complaint, then you may contact and raise your privacy
                  concerns to the National Privacy Commission through
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  upon death or incapacity, your rights may be transferred to
                  your lawful heirs.
                </ListItem>
              </ul>
            </SectionContent>
          </PrivacySection>

          <PrivacySection>
            <SectionTitle>Sharing of personal information</SectionTitle>
            <SectionContent>
              The information we gather may be shared within any of our
              affiliated and/or client companies in order to deliver and provide
              our services and within our team. In particular:
              <ul>
                <ListItem>
                  <TickMark icon={faCheck} />
                  We disclose your information to government offices or private
                  entities that we transact with in relation to your availed
                  services/s with us.
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  We may share your information to companies and individuals we
                  employ to perform functions on our behalf (e.g. web hosting
                  servers; processing credit card payments; and providing
                  customer service, marketing assistance, data analysis; and the
                  like);
                </ListItem>
                <ListItem>
                  We may disclose such information to third parties if you
                  consent to such disclosure.
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  Personal data will be kept confidential but, subject to the
                  provisions of any applicable law, we will only share
                  information with our clients and/or third parties where we
                  have your permission to do so in accordance with this Policy,
                  and where we believe it is necessary for a legitimate reason,
                  or where it is necessary for us to comply with the terms and
                  conditions of the Client Service Agreement, Referrers
                  Agreement, Terms of Use, and this Policy. In addition, we will
                  only share information with a third party if we are satisfied
                  that such a third party will keep that information secure.
                  Finally, we may disclose your personal information to third
                  parties:
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  if we are under a duty to disclose or share your personal
                  information in order to comply with any legal or regulatory
                  obligation or request.
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  to enforce or apply our terms of use/terms and conditions and
                  other agreements or to investigate potential breaches; or to
                  protect our rights, property or safety, including that of the
                  Website and/or our users.
                </ListItem>
              </ul>
            </SectionContent>
          </PrivacySection>

          <PrivacySection>
            <SectionTitle>Retention</SectionTitle>
            <SectionContent>
              Your information will be stored as long as necessary to fulfil any
              of the purposes described in this Data Privacy Policy or to comply
              with any contractual, legal, tax, accounting, or any other
              obligation. We destroy personal information if it is no longer
              needed for the purposes for which it was collected, and the law no
              longer requires us to retain it.
            </SectionContent>
          </PrivacySection>

          <PrivacySection>
            <SectionTitle>Changes to the Data Privacy Policy</SectionTitle>
            <SectionContent>
              We reserve the right to change the terms of this policy at any
              time and your continued use of our services constitutes your
              consent to those changes.
            </SectionContent>
          </PrivacySection>

          <PrivacySection>
            <SectionTitle>
              Communication Regarding Data Privacy Concerns
            </SectionTitle>
            <SectionContent>
              For all inquiries, requests, notifications and other concerns
              regarding this Data Privacy Policy, communication may be directed
              to{" "}
              <strong style={{ color: "#dc143c" }}>
                hello@talentfolder.com
              </strong>
            </SectionContent>
          </PrivacySection>
        </PrivacyContainer>
      </PageWrapper>
    </>
  );
}

export default Privacy;

import {
  Footer,
  Navbar,
  Home,
  Signin,
  ForgetPassword,
  Recruitersignup,
  Candidatesignup,
  Loader,
  Contact,
  Main,
  Careeradvice,
  BookModal,
  Jobfilter,
  Resetpassword,
  Postjob,
  Managejob,
  Manageresume,
  Adminprofile,
  Applicantprofile,
  Blog,
  Applicant,
  BlogDetails,
  AddBlog,
  UpdateBanner,
  ManageCompany,
  BlogList,
  Faq,
  Privacy,
  Fair,
  Antispam,
  Terms,
  Accessibility,
  Usage,
  Misconduct,
  Misuse,
  About,
} from "./COMPONENTS";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate,
} from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Jobsearch from "./COMPONENTS/jobsearch/Jobsearch";
import React, { useEffect } from "react";
import Privateroute from "./COMPONENTS/authentication/privateroute";
import Privaterouteadmin from "./COMPONENTS/authentication/privaterouteadmin";
import useSwr from "./HOOKS/ip";
import { useDispatch, useSelector } from "react-redux";
import useMutation from "./HOOKS/useMutation";
import { BASE_URL } from "./DATA/category";
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  const { loading, error, currentUser } = useSelector((state) => state.user);

  const { data } = useSwr("http://ip-api.com/json");
  const ip = data?.data?.query;
  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetch(`${BASE_URL}/dashboard`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ip: "1",
          }),
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [ip]);
  return (
    <>
      <Router>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/forgetpassword" element={<ForgetPassword />} />
          <Route path="/candidatesignup" element={<Candidatesignup />} />
          <Route path="/requitersignup" element={<Recruitersignup />} />
          <Route path="/loader" element={<Loader />} />
          <Route path="/jobsearch" element={<Jobsearch />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/careeradvice" element={<Careeradvice />} />
          <Route path="/blog/:id" element={<BlogDetails />} />
          <Route path="/book" element={<BookModal />} />
          <Route path="/jobfilter" element={<Jobfilter />} />
          <Route path="/privacy-policies" element={<Privacy />} />
          <Route path="/fair-use-policies" element={<Fair />} />
          <Route path="/anti-spam-policies" element={<Antispam />} />
          <Route path="/terms-condition" element={<Terms />} />
          <Route path="/accessibility" element={<Accessibility />} />
          <Route path="/acceptable-usage-policies" element={<Usage />} />
          <Route path="/misconduct-reporting" element={<Misconduct />} />
          <Route path="/mis-use-brand" element={<Misuse />} />
          <Route path="/about-us" element={<About />} />

          <Route element={<Privateroute />}>
            {currentUser && (
              <Route path="/applicantprofile" element={<Applicantprofile />} />
            )}
          </Route>
          {/* admin protected route */}
          <Route element={<Privaterouteadmin />}>
            <Route path="/dashboard" element={<Main />} />
            <Route path="/adminprofile" element={<Adminprofile />} />
            <Route path="/jobpost" element={<Postjob />} />
            <Route path="/manageresume" element={<Manageresume />} />
            <Route path="/managejob" element={<Managejob />} />
            <Route path="/applicant" element={<Applicant />} />
            <Route path="/addblog" element={<AddBlog />} />
            <Route path="/banner-update" element={<UpdateBanner />} />
            <Route path="/manage-company" element={<ManageCompany />} />
            <Route path="/bloglist" element={<BlogList />} />
            <Route path="/faq" element={<Faq />} />
          </Route>
          <Route path="/resetpassword" element={<Resetpassword />} />
        </Routes>
        <ConditionalFooter />
      </Router>
    </>
  );
}

export default App;

function ConditionalFooter() {
  const location = useLocation();

  // Function to determine if the footer should be displayed
  const shouldDisplayFooter = () => {
    // Check if the current route is not the "signin" page
    return (
      location.pathname !== "/signin" &&
      location.pathname !== "/candidatesignup" &&
      location.pathname !== "/requitersignup" &&
      location.pathname !== "/dashboard" &&
      location.pathname !== "/resetpassword" &&
      location.pathname !== "/book" &&
      location.pathname !== "/applicantprofile" &&
      location.pathname !== "/addblog" &&
      location.pathname !== "/banner-update" &&
      location.pathname !== "/manageresume" &&
      location.pathname !== "/jobpost" &&
      location.pathname !== "/applicant" &&
      location.pathname !== "/adminprofile" &&
      location.pathname !== "/manage-company" &&
      location.pathname !== "/managejob" &&  
      location.pathname !== "/bloglist"
    );
  };

  // Conditionally render the footer
  return shouldDisplayFooter() ? <Footer /> : null;
}

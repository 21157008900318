import React from "react";
import styled from "styled-components";
import backgroundImage from "./fairbg.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const PageWrapper = styled.div`
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const PrivacyContainer = styled.div`
  padding: 40px 20px;
  max-width: 900px;
  margin: 40px auto;
  background: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-family: "Roboto", sans-serif;
  color: #333;

  @media (max-width: 768px) {
    padding: 20px 15px;
    margin: 20px auto;
  }
`;

const PrivacyHeader = styled.h1`
  font-size: 2.5em;
  text-align: center;
  color: #2c3e50;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    font-size: 2em;
    margin-bottom: 20px;
  }
`;

const PrivacySection = styled.section`
  margin-bottom: 30px;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const SectionTitle = styled.h2`
  font-size: 1.8em;
  color: #34495e;
  margin-bottom: 15px;
  position: relative;
  padding-bottom: 10px;

  &::after {
    content: "";
    width: 50px;
    height: 3px;
    background: #fe4324;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  @media (max-width: 768px) {
    font-size: 1.5em;
    margin-bottom: 10px;
    padding-bottom: 5px;

    &::after {
      width: 40px;
      height: 2px;
    }
  }
`;

const SectionContent = styled.p`
  color: #7f8c8d;
  font-size: 1em;
  line-height: 1.8;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 0.9em;
  }
`;

const TickMark = styled(FontAwesomeIcon)`
  color: #dc143c;
  margin-right: 10px;
  margin-top: 6px;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start;

  @media (max-width: 768px) {
    font-size: 0.9em;
  }
`;

function Usage() {
  return (
    <>
      <PageWrapper>
        <PrivacyContainer>
          <PrivacyHeader>Acceptable Usage Policy</PrivacyHeader>
          <PrivacySection>
            <SectionContent>
              To provide a professional service, we, Talent Folder LLC (“TALENT
              FOLDER”) have set up a few guidelines for users of our service.
              Our guidelines ensure a safe, functional, and trusted environment
              for all our customers to publish their information on the Web.
              <br></br>
              Many of our competitors do not require their customers to comply
              with these guidelines. If any of our potential customers are
              unwilling or unable to comply with these guidelines, we will not
              be able to provide them with our services.
            </SectionContent>
          </PrivacySection>
          <PrivacySection>
            <SectionTitle>Use of our Service</SectionTitle>
            <SectionContent>
              <ul>
                <ListItem>
                  <TickMark icon={faCheck} />
                  Whether directly or indirectly using our services, constitutes
                  your acceptance of these terms and your agreement to abide by
                  them. We reserve the right to amend or adjust these terms at
                  any time, though we will do our best to notify you in advance.
                  Your continued use of our services constitutes your acceptance
                  of any changes we make.
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  You agree that you will only use our services in a manner
                  consistent with this Acceptable Usage Policy (“AUP”) and that
                  your failure to do so may be grounds for us to suspend or
                  discontinue the service.
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  You are solely and fully responsible for the contents of your
                  account. Any and all repercussions will be borne solely by
                  you.
                </ListItem>
              </ul>
            </SectionContent>
          </PrivacySection>

          <PrivacySection>
            <SectionTitle>Prohibited Uses</SectionTitle>
            <SectionContent>
              <ul>
                <ListItem>
                  <TickMark icon={faCheck} />
                  You may not publish/host any material that is grossly
                  offensive, including, exploitation of children, blatant
                  expressions of bigotry, hatred, harassment, defamation, or
                  invasion of privacy.
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  This prohibition includes, but is not limited to, the use or
                  transmission of any data that is protected by copyright,
                  trademark, trade secret, patent or other intellectual property
                  right without proper authorization and the transmission of any
                  material that constitutes an illegal threat, violates export
                  control laws.
                </ListItem>
              </ul>
            </SectionContent>
          </PrivacySection>

          <PrivacySection>
            <SectionTitle>Email Usage Policies</SectionTitle>
            <SectionContent>
              <ul>
                <ListItem>
                  <TickMark icon={faCheck} />
                  TALENT FOLDER does not support unsolicited email messages sent
                  by users of our system (also known as junk email or spam).
                  Users sending unsolicited email messages from our system will
                  have all of their services temporarily suspended. They will
                  then be contacted by TALENT FOLDER and informed of the
                  suspension before having their services reinstated. A repeated
                  offense may cause cancellation of service without refund of
                  any fees (as may be applicable).
                </ListItem>
              </ul>
            </SectionContent>
          </PrivacySection>

          <PrivacySection>
            <SectionTitle>Fraudulent Activities</SectionTitle>
            <SectionContent>
              <ul>
                <ListItem>
                  <TickMark icon={faCheck} />
                  TALENT FOLDER prohibits utilizing dedicated services or
                  network services for fraudulent activities. Participation in
                  fraudulent activities is in direct violation of state and
                  federal law and TALENT FOLDER’s AUP.
                </ListItem>
              </ul>
            </SectionContent>
          </PrivacySection>

          <PrivacySection>
            <SectionTitle>Miscellaneous Prohibited Activities</SectionTitle>
            <SectionContent>
              TALENT FOLDER prohibits customers and users from using TALENT
              FOLDER’s Network for any prohibited activities, including, but not
              limited to, the following activities:
              <ul>
                <ListItem>
                  <TickMark icon={faCheck} />
                  Intentionally transmitting files containing a computer virus
                  or corrupted data.
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  If TALENT FOLDER has specified bandwidth limitations for your
                  user account, use of the services shall not be more than those
                  limitations.
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  Attempting to circumvent or alter the processes or procedures
                  to measure time, bandwidth utilization, or other methods to
                  document use of the services.
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  Unauthorized monitoring of data or traffic on any network or
                  system without express authorization of the owner of the
                  system or network.
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  Unauthorized access to or use of data, systems or networks,
                  including any attempt to probe, scan or test the vulnerability
                  of a system or network or to breach security or authentication
                  measures without express authorization of the owner of the
                  system or network.
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  Advertising, transmitting, or otherwise making available any
                  software, program, product, or service that is designed to
                  violate this AUP, which includes the facilitation of the means
                  to deliver unsolicited commercial e-mail.
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  Any activity that disrupts, degrades, harms or threatens to
                  harm TALENT FOLDER’s Network or its services.
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  Any other inappropriate activity or abuse of the services (as
                  determined by TALENT FOLDER in its sole discretion), whether
                  or not specifically listed in this AUP, may result in
                  suspension or termination of the user’s access to or use of
                  the services.
                </ListItem>
              </ul>
            </SectionContent>
          </PrivacySection>

          <PrivacySection>
            <SectionTitle>Vulnerability Monitoring and Testing</SectionTitle>
            <SectionContent>
              <ul>
                <ListItem>
                  <TickMark icon={faCheck} />
                  You may not attempt to probe, scan, penetrate or test the
                  vulnerability of TALENT FOLDER’s system, subsystem or network
                  or to attempt to breach security or authentication measures,
                  whether by passive or intrusive techniques, without TALENT
                  FOLDER’s express written consent.
                </ListItem>
              </ul>
            </SectionContent>
          </PrivacySection>

          <PrivacySection>
            <SectionTitle>Message Source</SectionTitle>
            <SectionContent>
              <ul>
                <ListItem>
                  <TickMark icon={faCheck} />
                  You may not obscure the source of your email in any manner,
                  including by intentionally omitting, forging, or
                  misrepresenting messages headers or return addresses.
                </ListItem>
              </ul>
            </SectionContent>
          </PrivacySection>

          <PrivacySection>
            <SectionTitle>Illegal Activities</SectionTitle>
            <SectionContent>
              <ul>
                <ListItem>
                  <TickMark icon={faCheck} />
                  Customers or users caught using our system for illegal
                  activities, including but not limited to breaking into remote
                  systems, credit card fraud, theft, vandalism, threats, or
                  violence, will have their accounts immediately cancelled
                  without refund of any fees.
                </ListItem>
              </ul>
            </SectionContent>
          </PrivacySection>

          <PrivacySection>
            <SectionTitle>Further Terms of Service</SectionTitle>
            <SectionContent>
              <ul>
                <ListItem>
                  <TickMark icon={faCheck} />
                  In no event will TALENT FOLDER be liable for any losses or
                  damages arising out of the use of or inability to use its
                  services or any content thereon. We take no liability in case
                  of data loss. Users should always keep their own backups of
                  important data.
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />A billing dispute of any kind does
                  not exempt you from paying current and future bills. Billing
                  disputes resolved in the customer’s favor will receive account
                  credit for overpayment.
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  If any chargebacks occur on your account, we reserve the right
                  to suspend your service without notice and will terminate your
                  service and recycle your server(s) if contact and payment has
                  not been made within 3 days.
                </ListItem>
              </ul>
            </SectionContent>
          </PrivacySection>

          <PrivacySection>
            <SectionTitle>Right to Refuse Service</SectionTitle>
            <SectionContent>
              <ul>
                <ListItem>
                  <TickMark icon={faCheck} />
                  TALENT FOLDER reserves the right to refuse service to anyone
                  for any reason. We enforce this policy to ensure a
                  professional environment for the users of our system.
                </ListItem>
              </ul>
            </SectionContent>
          </PrivacySection>

          <PrivacySection>
            <SectionTitle>Complaints</SectionTitle>
            <SectionContent>
              <ul>
                <ListItem>
                  <TickMark icon={faCheck} />
                  TALENT FOLDER reserves the right to suspend access. If TALENT
                  FOLDER receives complaints directly from Internet users,
                  through Internet organizations and through other parties,
                  TALENT FOLDER shall not be required to determine the validity
                  of complaints received, or of information obtained from
                  anti-spamming organizations, before acting under this AUP. A
                  complaint from the recipient of commercial e-mail, whether
                  received directly, or through an anti- spamming organization,
                  shall be evidence that the message was unsolicited. TALENT
                  FOLDER has no obligation to forward the complaint to the
                  customer or user, or to identify the complaining parties.
                </ListItem>
              </ul>
            </SectionContent>
          </PrivacySection>

          <PrivacySection>
            <SectionTitle>Suspension or Termination of Services</SectionTitle>
            <SectionContent>
              <ul>
                <ListItem>
                  <TickMark icon={faCheck} />
                  If customers or users engage in conduct or a pattern of
                  conduct, including without limitation repeated violations by a
                  user whereby correction of individual violations does not, in
                  TALENT FOLDER’s sole discretion, correct a pattern of the same
                  or similar violations, while using the services that violates
                  the AUP, or is otherwise illegal or improper, TALENT FOLDER
                  reserves the right to suspend and/or terminate the services or
                  the user’s access to the services. TALENT FOLDER will
                  generally attempt to notify the customer or user of any
                  activity in violation of the AUP and request that the user
                  cease such activity; however, in cases where the operation of
                  TALENT FOLDER’s Network is threatened or cases involving
                  unsolicited commercial e- mail/spam, a pattern of violations,
                  mail relaying, alteration of the customer’s or user’s source
                  IP address information, denial of service attacks, illegal
                  activities, suspected fraud in connection with the use of
                  services, harassment or copyright infringement, the Company
                  reserves the right to suspend or terminate the Services or the
                  user’s access to the services without notification.
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  In the event that TALENT FOLDER becomes aware that any such
                  material may violate the terms of this AUP and/or expose
                  Company to civil or criminal liability, TALENT FOLDER reserves
                  the right to block access to such material and suspend or
                  terminate the access of any user creating, storing, copying,
                  or communicating such material, including any user whom TALENT
                  FOLDER becomes aware the customer or user has engaged in any
                  of the foregoing activity multiple times.
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  TALENT FOLDER does not make any promise, nor does TALENT
                  FOLDER have any obligation to monitor or police activity
                  occurring using the services and will have no liability to any
                  party, including a customer and user, for any violation of the
                  AUP.
                </ListItem>
              </ul>
            </SectionContent>
          </PrivacySection>

          <PrivacySection>
            <SectionTitle>Investigation and Enforcement</SectionTitle>
            <SectionContent>
              <ul>
                <ListItem>
                  <TickMark icon={faCheck} />
                  TALENT FOLDER has the right, but is not obligated, to strictly
                  enforce this AUP through self- help, active investigation,
                  litigation and prosecution. TALENT FOLDER shall not be
                  obligated to monitor or exercise any editorial control over
                  any material stored, copied, or communicated using the
                  services or TALENT FOLDER’s Network, but reserves the right to
                  do so. In addition, TALENT FOLDER may take any other
                  appropriate action against the customer or a user for
                  violations of the AUP, including repeated violations wherein
                  correction of individual violations does not, in TALENT
                  FOLDER’s sole discretion, correct a pattern of the same or
                  similar violations.
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  Company further reserves the right to conduct investigations
                  into fraud, violations of the terms of this AUP or other laws
                  or regulations, and to cooperate with legal authorities and
                  third parties in the investigation of alleged wrongdoing,
                  including disclosing the identity of the user that Company
                  deems responsible for the wrongdoing.
                </ListItem>
              </ul>
            </SectionContent>
          </PrivacySection>

          <PrivacySection>
            <SectionTitle>Miscellaneous Provisions</SectionTitle>
            <SectionContent>
              <ul>
                <ListItem>
                  <TickMark icon={faCheck} />
                  Governing Law: This AUP is governed by and construed under the
                  laws of the Republic of the Philippines without regard to its
                  conflict of laws principles. The courts within the Philippines
                  have exclusive jurisdiction over and venue of any suit that
                  relates to this AUP. You are responsible for ensuring that
                  your conduct is always in compliance with all applicable laws,
                  rules, and regulations.
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  No Waiver and Severability of Policy: Failure by TALENT FOLDER
                  to insist upon or enforce strict performance of any provision
                  of this AUP will not be construed as a waiver of any provision
                  or right. Neither the course of conduct between the parties
                  nor trade practice will act to modify any provision of this
                  AUP. TALENT FOLDER may assign its rights and duties under
                  these terms to any party at any time without notice to you. If
                  any provision of this AUP is found to be unenforceable or
                  invalid, this AUP’s unaffected provisions will remain in
                  effect.
                </ListItem>
              </ul>
            </SectionContent>
          </PrivacySection>

          <PrivacySection>
            <SectionTitle>Miscellaneous Provisions</SectionTitle>
            <SectionContent>
              <ul>
                <ListItem>
                  <TickMark icon={faCheck} />
                  For all inquiries, requests, notifications and other concerns
                  regarding this Data Privacy Policy, communication may be
                  directed to hello@talentfolder.com
                </ListItem>
              </ul>
            </SectionContent>
          </PrivacySection>
        </PrivacyContainer>
      </PageWrapper>
    </>
  );
}

export default Usage;

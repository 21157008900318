import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { MdAddLocationAlt } from "react-icons/md";
import useMutation from "../../../HOOKS/useMutation";
import {
  CountrySelect,
  StateSelect,
  CitySelect,
} from "react-country-state-city";
import Swal from "sweetalert2";
import useStore from "../../../ZUSTAND";

const Card = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledH3 = styled.h3`
  margin-bottom: 10px;
  font-weight: bold;
`;

const AddressIcon = styled(MdAddLocationAlt)`
  font-size: 24px;
  color: #007bff;
  cursor: pointer;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 2; /* Ensure the modal content is above the overlay */
`;
const ModalHeader = styled.h3`
  margin-bottom: 20px;
  font-weight: bold;
`;

const InputField = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Button = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: ${(props) => (props.primary ? "#007bff" : "#ccc")};
  color: white;
`;
const Label = styled.span`
  font-weight: bold;
  color: #002365;
`;
const AddressDetails = styled.div`
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
`;

const DetailRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;

  &:last-child {
    border-bottom: none;
  }
`;

const DetailValue = styled.span`
  color: #333;
  font-weight: 400;
`;

const Address = (user) => {
  const { mutate } = useStore();
  const { mutation } = useMutation();

  const userAddress = user?.userAddress;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [country, setCountry] = useState(userAddress?.country);
  const [state, setState] = useState(userAddress?.state);
  const [city, setCity] = useState(userAddress?.city);
  const [street, setStreet] = useState(userAddress?.street);
  const [zipCode, setZipCode] = useState(userAddress?.zipcode);
  const [address, setAddress] = useState({
    country: userAddress?.country,
    state: userAddress?.state,
    city: userAddress?.city,
    street: userAddress?.street,
    zipCode: userAddress?.zipcode,
  });

  const [countryid, setCountryid] = useState(0);
  const [stateid, setstateid] = useState(0);

  const handleIconClick = () => {
    setStreet("");
    setZipCode("");
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setAddress({ country, state, city, street, zipCode });
    handleCloseModal();
    // console.log(country, state, city, street, zipCode);
    try {
      const res = await mutation(`user/updateinfo/${userAddress?._id}`, {
        method: "PUT",
        body: {
          country: country || userAddress?.country,
          state: state || userAddress?.state,
          city: city || userAddress?.city,
          street: street || userAddress?.street,
          zipcode: zipCode || userAddress?.zipCode,
        },
      });
      mutate();
      if (res?.results?.success) {
        Swal.fire({
          title: "Success",
          text: "",
          icon: "success",
          confirmButtonText: "ok",
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: error.message,
        icon: "Error",
        confirmButtonText: "ok",
      });
    }
  };

  useEffect(() => {
    if (country) {
      // console.log("Selected country:", country);
      // Reset state and city when country changes
      setState(null);
      setCity(null);
    }
  }, [country]);

  useEffect(() => {
    if (state) {
      // Reset city when state changes
      setCity(null);
    }
  }, [state]);

  return (
    <div>
      <Card>
        <StyledH3>Address</StyledH3>
        <AddressIcon onClick={handleIconClick} />
      </Card>
      {address && (
        <AddressDetails>
          <DetailRow>
            <Label>Country:</Label>
            <DetailValue>{userAddress?.country || "Not Provided"}</DetailValue>
          </DetailRow>
          <DetailRow>
            <Label>State:</Label>
            <DetailValue>{userAddress?.state || "Not Provided"}</DetailValue>
          </DetailRow>
          <DetailRow>
            <Label>City:</Label>
            <DetailValue>{userAddress?.city || "Not Provided"}</DetailValue>
          </DetailRow>
          <DetailRow>
            <Label>Street:</Label>
            <DetailValue>{userAddress?.street || "Not Provided"}</DetailValue>
          </DetailRow>
          <DetailRow>
            <Label>Zip Code:</Label>
            <DetailValue>{userAddress?.zipcode || "Not Provided"}</DetailValue>
          </DetailRow>
        </AddressDetails>
      )}

      {isModalOpen && (
        <ModalOverlay>
          <ModalContent>
            <ModalHeader>Enter Address</ModalHeader>

            <h1 className="mt-2 mb-2 text-sm font-medium ">
              Country<span className="text-redTalent">*</span>
            </h1>
            <CountrySelect
              className="stsearch-box input"
              onChange={(e) => {
                setCountry(e.name);
                setCountryid(e.id);
              }}
              value={userAddress}
            />
            <h1 className="mt-2 mb-2 text-sm font-medium">
              State<span className="text-redTalent">*</span>
            </h1>
            <StateSelect
              countryid={countryid}
              onChange={(e) => {
                setState(e.name);
                setstateid(e.id);
              }}
              value={state}
            />
            <h1 className="mt-2 mb-2 text-sm font-medium">
              City<span className="text-redTalent">*</span>
            </h1>
            <CitySelect
              countryid={countryid}
              stateid={stateid}
              onChange={(e) => {
                setCity(e.name);
              }}
              value={userAddress?.city}
            />
            <h1 className="mt-2 mb-2 text-sm font-medium">
              Street<span className="text-redTalent">*</span>
            </h1>
            <InputField
              type="text"
              value={userAddress?.street}
              onChange={(e) => setStreet(e.target.value)}
            />
            <h1 className="mt-2 mb-2 text-sm font-medium">
              ZipCode<span className="text-redTalent">*</span>
            </h1>
            <InputField
              type="text"
              value={userAddress?.zipcode}
              onChange={(e) => setZipCode(e.target.value)}
            />
            <ButtonContainer>
              <Button primary onClick={handleSave}>
                Save
              </Button>
              <Button onClick={handleCloseModal}>Cancel</Button>
            </ButtonContainer>
          </ModalContent>
        </ModalOverlay>
      )}
    </div>
  );
};

export default Address;

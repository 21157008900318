import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  faChartLine,
  faBriefcase,
  faTasks,
  faFileAlt,
  faStar,
  faUser,
  faSignOutAlt,
  faHome,
  faBars,
  faBell,
  faUsers,
  faBuilding,
  faPen,
  faImage,
  faList,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import "./dashboard.css";
import "./postjob.css";
import { useDispatch } from "react-redux";
import { SignoutuserSuccess } from "../../../../redux/users/UserSlice";
import useMutation from "../../../../HOOKS/useMutation";
import useSwr from "../../../../HOOKS/useSWR";
import { useDebounce } from "../../../../DATA/category";
import { IoCloudUploadOutline } from "react-icons/io5";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

function Postjob() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [companyName, setCompanyName] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [studentImage, setStudentImage] = useState(null);
  const [loading, seLoading] = useState(false);
  const { mutation, isLoading } = useMutation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const debouncedInputValue = useDebounce(companyName, 500);

  useEffect(() => {
    if (debouncedInputValue) {
      setInputValue(debouncedInputValue);
    }
  }, [debouncedInputValue]);

  let url = `company/getallcompany`;
  if (inputValue) {
    url = `company/getallcompany?search=${inputValue}`;
  }

  const {
    data: company,
    isValidating: companyVakidating,
    mutate,
  } = useSwr(url);
  const { data: category } = useSwr("jobcategory/getallcategory");
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    if (windowWidth < 768) setIsSidebarOpen(false);

    return () => window.removeEventListener("resize", handleResize);
  }, [windowWidth]);

  const toggleSidebar = () => {
    if (windowWidth <= 768) {
      setIsSidebarOpen(!isSidebarOpen);
    }
  };

  const onLogout = () => {
    dispatch(SignoutuserSuccess());
    localStorage.removeItem("token");
    navigate("/signin");
    // Additional logout logic (e.g., clearing tokens, redirecting) can be added here
  };

  const formik = useFormik({
    initialValues: {
      industry: "",
      designation: "",
      companyName: "",
      minExperience: "",
      maxExperience: "",
      country: "",
      state: "",
      city: "",
      companyLogo: null,
      jobDescription: "",
      imagePreviewUrl: "",
      skills: [],
      minSalary: "",
      maxSalary: "",
      modeOfWork: "",
      jobPostedDate: "",
      jobExpiryDate: "",
      // vacancyPosition: "",
      industry: "",
      company_info: "",
      recrumentCount: "",
      currency: "",
    },
    validationSchema: Yup.object({
      designation: Yup.string().required("Required"),
      companyName: Yup.string().required("Required"),
      minExperience: Yup.number().required("Required"),
      maxExperience: Yup.number().required("Required"),
      country: Yup.string().required("Required"),
      state: Yup.string().required("Required"),
      city: Yup.string().required("Required"),
      minSalary: Yup.number().required("Required"),
      maxSalary: Yup.number().required("Required"),
      modeOfWork: Yup.string().required("Required"),
      currency: Yup.string().required("Required"),
      skills: Yup.array()
        .min(1, "At least one skill is required")
        .required("Required"),
      jobPostedDate: Yup.date().required("Required"),
      jobExpiryDate: Yup.date().required("Required"),
      // vacancyPosition: Yup.string().required("Required"),
      industry: Yup.string().required("Required"),
      jobDescription: Yup.string().required("Required"),
      recrumentCount: Yup.number().required("Required"),
    }),
    onSubmit: async (values, props) => {
      seLoading(true);
      const formData = new FormData();
      // formData.append("industry", String(values.industry));
      formData.append("designation", String(values.designation));
      formData.append("recrumentCount", String(values.recrumentCount));
      formData.append("companyName", String(values.companyName));
      formData.append("minExperience", String(values.minExperience));
      formData.append("maxExperience", String(values.maxExperience));
      formData.append("country", String(values.country));
      formData.append("state", String(values.state));
      formData.append("city", String(values.city));
      formData.append("minSalary", String(values.minSalary));
      formData.append("maxSalary", String(values.maxSalary));
      formData.append("modeOfWork", values.modeOfWork);
      formData.append("currency", values.currency);
      formData.append("jobDescription", values.jobDescription);
      formData.append("jobPostedDate", values.jobPostedDate);
      formData.append("jobExpiryDate", values.jobExpiryDate);
      // formData.append("vacancyPosition", String(values.vacancyPosition));
      formData.append("category_id", values.industry);
      formData.append("skills", JSON.stringify(values.skills));
      formData.append("companyInfo", values.company_info);
      if (studentImage) {
        formData.append("companyLogo", studentImage);
      }
      try {
        const res = await mutation(`jobpost/createjobpost`, {
          method: "POST",
          body: formData,
          isFormData: true,
          isAlert: true,
        });
        if (res?.results?.success) {
          seLoading(false);
          Swal.fire({
            title: "Success",
            text: "",
            icon: "success",
            confirmButtonText: "ok",
          });
          props?.resetForm();
          setImagePreview(null);
          setStudentImage(null);
        }
      } catch (error) {
        console.error("Error:", error);
        Swal.fire({
          title: "Error",
          text: error.message,
          icon: "Error",
          confirmButtonText: "ok",
        });
      }
    },
  });

  const [imagePreview, setImagePreview] = useState(null);

  // const handleFileChange = (e) => {
  //   const reader = new FileReader();
  //   const file = e.target.files[0];

  //   reader.onloadend = () => {
  //     formik.setFieldValue("companyLogo", file);
  //     formik.setFieldValue("imagePreviewUrl", reader.result);
  //     setImagePreview(reader.result);
  //   };

  //   if (file) {
  //     reader.readAsDataURL(file);
  //   }
  // };
  const handleFileChange = (event) => {
    setStudentImage(event.target.files ? event.target.files[0] : null);
    const file = event.target.files[0];
    // setStudentImage(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        formik.setFieldValue("companyLogo", file);
      };
      reader.readAsDataURL(file);
    }
  };
  const addSkill = () => {
    if (formik.values.newSkill) {
      formik.setFieldValue("skills", [
        ...formik.values.skills,
        formik.values.newSkill,
      ]);
      formik.setFieldValue("newSkill", "");
    }
  };

  const removeSkill = (index) => {
    const updatedSkills = formik.values.skills.filter((_, i) => i !== index);
    formik.setFieldValue("skills", updatedSkills);
  };

  // const industryOptions = [
  //   { value: "IT", label: "Information Technology" },
  //   { value: "Finance", label: "Finance" },
  //   { value: "Healthcare", label: "Healthcare" },
  //   { value: "Education", label: "Education" },
  //   { value: "Marketing", label: "Marketing" },
  //   { value: "Sales", label: "Sales" },
  // ];

  return (
    <div className="dashboard">
      {isSidebarOpen && (
        <div className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
          <ul>
            <li>
              <Link to="/dashboard" className="sidebar-item">
                <FontAwesomeIcon icon={faChartLine} />
                <span>Dashboard</span>
              </Link>
            </li>
            <li>
              <Link to="/jobpost" className="sidebar-item">
                <FontAwesomeIcon icon={faBriefcase} />
                <span>Manage Jobs Post</span>
              </Link>
            </li>
            <li>
              <Link to="/managejob" className="sidebar-item">
                <FontAwesomeIcon icon={faTasks} />
                <span>Manage Jobs</span>
              </Link>
            </li>
            <li>
              <Link to="/manageresume" className="sidebar-item">
                <FontAwesomeIcon icon={faFileAlt} />
                <span>Manage Resume</span>
              </Link>
            </li>
            <li>
              <Link to="/manage-company" className="sidebar-item">
                <FontAwesomeIcon icon={faBuilding} />
                <span>Manage Company</span>
              </Link>
            </li>
            <li>
              <Link to="/applicant" className="sidebar-item">
                <FontAwesomeIcon icon={faUsers} />
                <span>Applicants</span>
              </Link>
            </li>
            <li>
              <Link to="/addblog" className="sidebar-item">
                <FontAwesomeIcon icon={faPen} />
                <span>Add Blogs</span>
              </Link>
            </li>
            <li>
              <Link to="/addblog" className="sidebar-item">
                <FontAwesomeIcon icon={faList} />
                <span>Blog List</span>
              </Link>
            </li>
            <li>
              <Link to="/banner-update" className="sidebar-item">
                <FontAwesomeIcon icon={faImage} />
                <span>Update Banner</span>
              </Link>
            </li>
            <li>
              <Link to="/faq" className="sidebar-item">
                <FontAwesomeIcon icon={faQuestionCircle} />
                <span>Faq</span>
              </Link>
            </li>
            <li>
              <Link to="/adminprofile" className="sidebar-item">
                <FontAwesomeIcon icon={faUser} />
                <span>My Profile</span>
              </Link>
            </li>
            <li>
              <div className="sidebar-item" onClick={onLogout}>
                <FontAwesomeIcon icon={faSignOutAlt} />
                <span>Logout</span>
              </div>
            </li>
          </ul>
        </div>
      )}

      {loading ? (
        <div class="text-center">
          <div role="status">
            <svg
              aria-hidden="true"
              class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="postjob-main-content">
          <form className="job-posting-form" onSubmit={formik.handleSubmit}>
            <h2>Post a Job</h2>
            <div className="form-group">
              <div className="row">
                <div className="col">
                  <label htmlFor="designation">Designation</label>
                  <input
                    type="text"
                    id="designation"
                    name="designation"
                    value={formik.values.designation}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                  />
                  {formik.touched.designation && formik.errors.designation ? (
                    <div className="error">{formik.errors.designation}</div>
                  ) : null}
                </div>
                {/* <div className="col">
                <label htmlFor="recrumentCount">Number Of Jobs</label>
                <input
                  type="number"
                  id="recrumentCount"
                  name="recrumentCount"
                  value={formik.values.recrumentCount}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
                {formik.touched.recrumentCount &&
                formik.errors.recrumentCount ? (
                  <div className="error">{formik.errors.recrumentCount}</div>
                ) : null}
              </div> */}
                <div className="col">
                  <label htmlFor="company-name">Company Name</label>
                  <input
                    type="text"
                    id="company-name"
                    name="companyName"
                    value={formik.values.companyName}
                    onChange={(e) => {
                      formik.handleChange(e);
                      setCompanyName(e.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    required
                  />
                  {companyName.length > 0 && (
                    <div className="bg-white shadow-lg ">
                      {company?.data?.data?.data.map((suggestion, index) => (
                        <div
                          key={index}
                          onClick={() => {
                            formik.setFieldValue(
                              "companyName",
                              suggestion?.companyName
                            );
                            setCompanyName("");
                            // setCompanyName(suggestion);
                            // setSuggestions([]);
                          }}
                          style={{ cursor: "pointer", padding: "5px" }}
                        >
                          {suggestion?.companyName}
                        </div>
                      ))}
                    </div>
                  )}
                  {formik.touched.companyName && formik.errors.companyName ? (
                    <div className="error">{formik.errors.companyName}</div>
                  ) : null}
                </div>
              </div>
              {company?.data?.data?.data < 1 && (
                <>
                  <div className="">
                    <label htmlFor="company-logo">Company Logo</label>
                    <div className="flex flex-col justify-center items-center h-[200px] border border-slate-400 rounded-md">
                      <input
                        type="file"
                        id="company-logo"
                        name="companyLogo"
                        accept="image/*"
                        onChange={handleFileChange}
                        // required
                        className="!hidden"
                      />
                      <label
                        htmlFor="company-logo"
                        className="cursor-pointer flex flex-col items-center"
                      >
                        {imagePreview ? (
                          <img
                            src={imagePreview}
                            alt="Company Logo"
                            className="h-32 w-56 object-cover rounded-md"
                          />
                        ) : (
                          <IoCloudUploadOutline className="h-20 w-20 text-gray-500 hover:text-gray-700" />
                        )}
                      </label>
                      {imagePreview && (
                        <button
                          onClick={(e) => {
                            setImagePreview(null);
                          }}
                        >
                          delete
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="company_info">Company Info</label>
                    <textarea
                      id="company_info"
                      name="company_info"
                      value={formik.values.company_info}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      required
                    ></textarea>
                    {formik.touched.company_info &&
                    formik.errors.company_info ? (
                      <div className="error">{formik.errors.company_info}</div>
                    ) : null}
                  </div>
                </>
              )}
            </div>
            <div className="form-group">
              <div className="row">
                <div className="col">
                  <label htmlFor="min-years-of-experience">
                    Min Years of Experience
                  </label>
                  <input
                    type="number"
                    id="min-years-of-experience"
                    name="minExperience"
                    value={formik.values.minExperience}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    min={0}
                    required
                  />
                  {formik.touched.minExperience &&
                  formik.errors.minExperience ? (
                    <div className="error">{formik.errors.minExperience}</div>
                  ) : null}
                </div>
                <div className="col">
                  <label htmlFor="max-years-of-experience">
                    Max Years of Experience
                  </label>
                  <input
                    type="number"
                    id="max-years-of-experience"
                    name="maxExperience"
                    value={formik.values.maxExperience}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                    min={0}
                  />
                  {formik.touched.maxExperience &&
                  formik.errors.maxExperience ? (
                    <div className="error">{formik.errors.maxExperience}</div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className="row">
                <div className="col">
                  <label htmlFor="currency">Salary Type</label>
                  <select
                    id="currency"
                    name="currency"
                    value={formik.values.currency}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                  >
                    <option value="">Select Salary Type</option>
                    <option value="AED">AED</option>
                    <option value="LPA">LPA</option>
                    <option value="USD">USD</option>
                    <option value="GBP">GBP</option>
                    <option value="EURO">EURO</option>
                    <option value="SGD">SGD</option>
                  </select>
                  {formik.touched.currency && formik.errors.currency ? (
                    <div className="error">{formik.errors.currency}</div>
                  ) : null}
                </div>
                <div className="col">
                  <label htmlFor="min-salary">Min Salary</label>
                  <input
                    type="number"
                    id="min-salary"
                    name="minSalary"
                    value={formik.values.minSalary}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                    min={0}
                  />
                  {formik.touched.minSalary && formik.errors.minSalary ? (
                    <div className="error">{formik.errors.minSalary}</div>
                  ) : null}
                </div>
                <div className="col">
                  <label htmlFor="max-salary">Max Salary</label>
                  <input
                    type="number"
                    id="max-salary"
                    name="maxSalary"
                    value={formik.values.maxSalary}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                    min={0}
                  />
                  {formik.touched.maxSalary && formik.errors.maxSalary ? (
                    <div className="error">{formik.errors.maxSalary}</div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="row">
                <div className="col">
                  <label htmlFor="job-posted-date">Job Posted Date</label>
                  <input
                    type="date"
                    id="job-posted-date"
                    name="jobPostedDate"
                    value={formik.values.jobPostedDate}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                  />
                  {formik.touched.jobPostedDate &&
                  formik.errors.jobPostedDate ? (
                    <div className="error">{formik.errors.jobPostedDate}</div>
                  ) : null}
                </div>
                <div className="col">
                  <label htmlFor="job-expiry-date">Job Expiry Date</label>
                  <input
                    type="date"
                    id="job-expiry-date"
                    name="jobExpiryDate"
                    value={formik.values.jobExpiryDate}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                  />
                  {formik.touched.jobExpiryDate &&
                  formik.errors.jobExpiryDate ? (
                    <div className="error">{formik.errors.jobExpiryDate}</div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="row">
                <div className="col">
                  <label htmlFor="recrumentCount">Vacancy Number</label>
                  <input
                    type="number"
                    id="recrumentCount"
                    name="recrumentCount"
                    value={formik.values.recrumentCount}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                    min={0}
                  />
                  {formik.touched.recrumentCount &&
                  formik.errors.recrumentCount ? (
                    <div className="error">{formik.errors.recrumentCount}</div>
                  ) : null}
                </div>
                <div className="col">
                  <label htmlFor="industry">Industry</label>
                  <select
                    id="industry"
                    name="industry"
                    value={formik.values.industry}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                  >
                    <option value="" className="industry-dropdown-container">
                      Select Industry
                    </option>
                    {category?.data?.data.map((option) => (
                      <option key={option._id} value={option?._id}>
                        {option?.industryName}
                      </option>
                    ))}
                  </select>
                  {formik.touched.industry && formik.errors.industry ? (
                    <div className="error">{formik.errors.industry}</div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="row">
                <div className="col">
                  <label htmlFor="country">Country</label>
                  <input
                    type="text"
                    id="country"
                    name="country"
                    value={formik.values.country}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                  />
                  {formik.touched.country && formik.errors.country ? (
                    <div className="error">{formik.errors.country}</div>
                  ) : null}
                </div>
                <div className="col">
                  <label htmlFor="state">State</label>
                  <input
                    type="text"
                    id="state"
                    name="state"
                    value={formik.values.state}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                  />
                  {formik.touched.state && formik.errors.state ? (
                    <div className="error">{formik.errors.state}</div>
                  ) : null}
                </div>
                <div className="col">
                  <label htmlFor="city">City</label>
                  <input
                    type="text"
                    id="city"
                    name="city"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                  />
                  {formik.touched.city && formik.errors.city ? (
                    <div className="error">{formik.errors.city}</div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="row">
                <div className="col">
                  <label htmlFor="skills">Skills </label>

                  <div className="flex gap-2">
                    <input
                      type="text"
                      id="newSkill"
                      name="newSkill"
                      value={formik.values.newSkill}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      // required
                      className="!w-[70%]"
                    />
                    <button
                      className="!w-[30%]"
                      type="button"
                      onClick={addSkill}
                    >
                      Add More
                    </button>
                  </div>
                  <div className="flex gap-2">
                    {formik.values.skills.map((skill, index) => (
                      <div key={index} className="flex ">
                        <div>
                          <p
                            className="!cursor-pointer bg-black text-white  rounded-full px-1 flex justify-center items-center w-7 h-7 mt-2 "
                            type="button"
                            onClick={() => removeSkill(index)}
                          >
                            <div className="">
                              <i class="fa-solid fa-xmark"></i>
                            </div>
                          </p>
                          <span>{skill}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                  {formik.touched.skills && formik.errors.skills ? (
                    <div className="error">{formik.errors.skills}</div>
                  ) : null}
                </div>
                <div className="col">
                  <label htmlFor="mode-of-work">Mode of Work</label>
                  <select
                    id="mode-of-work"
                    name="modeOfWork"
                    value={formik.values.modeOfWork}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                  >
                    <option value="">Select Mode of Work</option>
                    <option value="onsite">Onsite</option>
                    <option value="remote">Remote</option>
                    <option value="hybrid">Hybrid</option>
                  </select>
                  {formik.touched.modeOfWork && formik.errors.modeOfWork ? (
                    <div className="error">{formik.errors.modeOfWork}</div>
                  ) : null}
                </div>
                {/* {company?.data?.data?.data.length === 0 && (
                <div className="col">
                  <label htmlFor="company-logo">Company Logo</label>
                  <input
                    type="file"
                    id="company-logo"
                    name="companyLogo"
                    accept="image/*"
                    onChange={handleFileChange}
                    required
                  />
                </div>
              )} */}
              </div>
            </div>
            {/* {formik.values.imagePreviewUrl && (
            <div className="form-group">
              <label>Image Preview:</label>
              <img
                src={formik.values.imagePreviewUrl}
                alt="Uploaded"
                className="image-preview"
              />
            </div>
          )} */}
            <div className="form-group">
              <label htmlFor="job-description">Job Description</label>
              <textarea
                id="job-description"
                name="jobDescription"
                value={formik.values.jobDescription}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required
              ></textarea>
              {formik.touched.jobDescription && formik.errors.jobDescription ? (
                <div className="error">{formik.errors.jobDescription}</div>
              ) : null}
            </div>
            <div className="form-group">
              <button type="submit">
                {loading ? "...Loading " : "Job Post"}
              </button>
            </div>
          </form>
        </div>
      )}
      <div className="bottom-nav">
        <ul>
          <li>
            <FontAwesomeIcon icon={faHome} />
            <span>Home</span>
          </li>
          <li onClick={toggleSidebar}>
            <FontAwesomeIcon icon={faBars} />
            <span>Menu</span>
          </li>
          <li>
            <FontAwesomeIcon icon={faBell} />
            <span>Notifications</span>
          </li>
          <li>
            <FontAwesomeIcon icon={faUser} />
            <span>Profile</span>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Postjob;

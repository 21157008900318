import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store.jsx";
import { PersistGate } from "redux-persist/integration/react";
import useSwr from "./HOOKS/useSWR.jsx";

import { useDispatch } from "react-redux";
import { signInSuccess } from "./redux/users/UserSlice";
const root = ReactDOM.createRoot(document.getElementById("root"));

// const Main = () => {
//   const dispatch = useDispatch();

//   const { data: user, isValidating, mutate } = useSwr(`user/currentuser`);

//   if (isValidating) {
//     return <div>Loading...</div>;
//   }
//   if (user.data.success === true) {
//     const userInfo = user.data.data.user;
//     dispatch(signInSuccess(userInfo));
//   }
//   return <App user={user} mutate={mutate} />;
//   // Render app once user data is loaded
// };

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);

reportWebVitals();

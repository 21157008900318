import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  faChartLine,
  faBriefcase,
  faTasks,
  faFileAlt,
  faUser,
  faSignOutAlt,
  faHome,
  faBars,
  faBell,
  faUsers,
  faBuilding,
  faPen,
  faImage,
  faList,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import "./dashboard.css";
import "./postjob.css";
import { useDispatch } from "react-redux";
import { SignoutuserSuccess } from "../../../../redux/users/UserSlice";
import useMutation from "../../../../HOOKS/useMutation";

import { IoCloudUploadOutline, IoCloseOutline } from "react-icons/io5";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function AddBlog() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const [studentImage, setStudentImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const { mutation, isLoading } = useMutation();
  const [value, setValue] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [lastSectionImagePreview, setLastSectionImagePreview] = useState(null);
  const [lastSectionImage, setLastSectionImage] = useState(null);
  const imageInputRef = useRef(null);
  const [image1Preview, setImage1Preview] = useState(null);
  const [image2Preview, setImage2Preview] = useState(null);
  const [image3Preview, setImage3Preview] = useState(null);
  const [image4Preview, setImage4Preview] = useState(null);
  const [imageOne, setImageOne] = useState(null);
  const [imageTwo, setImageTwo] = useState(null);
  const [imageThree, setImageThree] = useState(null);
  const [imageFour, setImageFour] = useState(null);
  const [lastSectionImageData, setLastSectionImageData] = useState(null);

  const image1InputRef = useRef(null);
  const image2InputRef = useRef(null);
  const image3InputRef = useRef(null);
  const image4InputRef = useRef(null);

  const handleImageChange = (
    event,
    setImagePreview,
    setImageField,
    inputRef
  ) => {
    const file = event.target.files[0];
    if (file) {
      setImageField(file);
      setImagePreview(URL.createObjectURL(file));
      inputRef.current.disabled = true; // Disable the file input after uploading
    }
  };

  const handleDeleteImage = (setImageField, setImagePreview, inputRef) => {
    setImageField(null);
    setImagePreview(null);
    if (inputRef.current) {
      inputRef.current.value = ""; // Reset the file input value to enable re-uploading
      inputRef.current.disabled = false; // Enable the file input after deleting
    }
  };

  const handleImageChangeForLastSection = (event) => {
    const file = event.target.files[0];
    if (file) {
      setLastSectionImage(file);
      setLastSectionImageData(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setLastSectionImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
      // Disable the file input after uploading
      if (imageInputRef.current) {
        imageInputRef.current.disabled = true;
      }
    }
  };

  const handleDeleteLastSectionImage = () => {
    setLastSectionImage(null);
    setLastSectionImagePreview(null);
    // Reset the file input value to enable re-uploading
    if (imageInputRef.current) {
      imageInputRef.current.value = "";
      imageInputRef.current.disabled = false; // Enable the file input after deleting
    }
  };

  const toggleSidebar = () => {
    if (windowWidth <= 768) {
      setIsSidebarOpen(!isSidebarOpen);
    }
  };

  const onLogout = () => {
    dispatch(SignoutuserSuccess());
    localStorage.removeItem("token");
    navigate("/signin");
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      content: "",
      blogQuoteHeading: "",
      blogQuoteContent: "",
      image1Heading: "",
      image1Content: "",
      image2Heading: "",
      image2Content: "",
      image3Heading: "",
      image3Content: "",
      image4Heading: "",
      image4Content: "",
      lastSectionContent: "",
      lastSectionHeading: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Required"),
      content: Yup.string().required("Required"),
      blogQuoteHeading: Yup.string().required("Required"),
      blogQuoteContent: Yup.string().required("Required"),
      image1Heading: Yup.string().required("Required"),
      image1Content: Yup.string().required("Required"),
      image2Heading: Yup.string().required("Required"),
      image2Content: Yup.string().required("Required"),
      image3Heading: Yup.string().required("Required"),
      image3Content: Yup.string().required("Required"),
      image4Heading: Yup.string().required("Required"),
      image4Content: Yup.string().required("Required"),
      lastSectionContent: Yup.string().required("Required"),
      lastSectionHeading: Yup.string().required("Required"),
    }),
    onSubmit: async (values, props) => {
      setLoading(true);
      const formData = new FormData();
      formData.append("title", String(values.title));
      formData.append("content", values.content);
      formData.append("blogQuoteHeading", values.blogQuoteHeading);
      formData.append("blogQuoteContent", values.blogQuoteContent);
      formData.append("image1Heading", values.image1Heading);
      formData.append("image1Content", values.image1Content);
      formData.append("image2Heading", values.image2Heading);
      formData.append("image2Content", values.image2Content);
      formData.append("image3Heading", values.image3Heading);
      formData.append("image3Content", values.image3Content);
      formData.append("image4Heading", values.image4Heading);
      formData.append("image4Content", values.image4Content);
      formData.append("lastSectionContent", values.lastSectionContent);
      formData.append("lastSectionHeading", values.lastSectionHeading);

      if (studentImage) {
        formData.append("photo", studentImage);
      }
      if (imageOne) {
        formData.append("imageOne", imageOne);
      }
      if (imageTwo) {
        formData.append("imageTwo", imageTwo);
      }
      if (imageThree) {
        formData.append("imageThree", imageThree);
      }
      if (imageFour) {
        formData.append("imageFour", imageFour);
      }
      if (lastSectionImageData) {
        formData.append("lastSectionImageData", lastSectionImageData);
      }

      try {
        const res = await mutation(`blog`, {
          method: "POST",
          body: formData,
          isFormData: true,
          isAlert: true,
        });
        if (res?.results?.success) {
          setLoading(false);
          Swal.fire({
            title: "Success",
            text: "",
            icon: "success",
            confirmButtonText: "ok",
          });
          props?.resetForm();
          setImagePreview(null);
          setStudentImage(null);
          setImage1Preview(null);
          setImage2Preview(null);
          setImage3Preview(null);
          setImage4Preview(null);
          setLastSectionImagePreview(null);
          setValue("");
        }
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: error.message,
          icon: "error",
          confirmButtonText: "ok",
        });
      }
    },
  });
  const handleQuillChange = (content) => {
    setValue(content);
    formik.setFieldValue("content", content);
  };

  const [imagePreview, setImagePreview] = useState(null);

  const handleFileChange = (event) => {
    setStudentImage(event.target.files ? event.target.files[0] : null);
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        formik.setFieldValue("companyLogo", file);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="dashboard">
      {isSidebarOpen && (
        <div className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
          <ul>
            <li>
              <Link to="/dashboard" className="sidebar-item">
                <FontAwesomeIcon icon={faChartLine} />
                <span>Dashboard</span>
              </Link>
            </li>
            <li>
              <Link to="/jobpost" className="sidebar-item">
                <FontAwesomeIcon icon={faBriefcase} />
                <span>Manage Jobs Post</span>
              </Link>
            </li>
            <li>
              <Link to="/managejob" className="sidebar-item">
                <FontAwesomeIcon icon={faTasks} />
                <span>Manage Jobs</span>
              </Link>
            </li>
            <li>
              <Link to="/manageresume" className="sidebar-item">
                <FontAwesomeIcon icon={faFileAlt} />
                <span>Manage Resume</span>
              </Link>
            </li>
            <li>
              <Link to="/manage-company" className="sidebar-item">
                <FontAwesomeIcon icon={faBuilding} />
                <span>Manage Company</span>
              </Link>
            </li>
            <li>
              <Link to="/applicant" className="sidebar-item">
                <FontAwesomeIcon icon={faUsers} />
                <span>Applicants</span>
              </Link>
            </li>
            <li>
              <Link to="/addblog" className="sidebar-item">
                <FontAwesomeIcon icon={faPen} />
                <span>Add Blogs</span>
              </Link>
            </li>
            <li>
              <Link to="/bloglist" className="sidebar-item">
                <FontAwesomeIcon icon={faList} />
                <span>Blog List</span>
              </Link>
            </li>
            <li>
              <Link to="/banner-update" className="sidebar-item">
                <FontAwesomeIcon icon={faImage} />
                <span>Update Banner</span>
              </Link>
            </li>
            <li>
              <Link to="/faq" className="sidebar-item">
                <FontAwesomeIcon icon={faQuestionCircle} />
                <span>Faq</span>
              </Link>
            </li>
            <li>
              <Link to="/adminprofile" className="sidebar-item">
                <FontAwesomeIcon icon={faUser} />
                <span>My Profile</span>
              </Link>
            </li>
            <li>
              <div className="sidebar-item" onClick={onLogout}>
                <FontAwesomeIcon icon={faSignOutAlt} />
                <span>Logout</span>
              </div>
            </li>
          </ul>
        </div>
      )}
      <div className="postjob-main-content">
        <form className="job-posting-form" onSubmit={formik.handleSubmit}>
          <h2>Add a Blog</h2>
          <div className="form-group">
            <div className="row">
              <div className="col">
                <label htmlFor="title">Title</label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                />
                {formik.touched.title && formik.errors.title ? (
                  <div className="error">{formik.errors.title}</div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="">
            <label htmlFor="company-logo">Blog Image</label>
            <div className="flex flex-col justify-center items-center h-[200px] border border-slate-400 rounded-md">
              <input
                type="file"
                id="company-logo"
                name="companyLogo"
                accept="image/*"
                onChange={handleFileChange}
                className="!hidden"
              />
              <label
                htmlFor="company-logo"
                className="cursor-pointer flex flex-col items-center"
              >
                {imagePreview ? (
                  <img
                    src={imagePreview}
                    alt="Company Logo"
                    className="h-32 w-56 object-cover rounded-md"
                  />
                ) : (
                  <IoCloudUploadOutline className="h-20 w-20 text-gray-500 hover:text-gray-700" />
                )}
              </label>
              {imagePreview && (
                <button type="button" onClick={() => setImagePreview(null)}>
                  Delete
                </button>
              )}
            </div>
          </div>{" "}
          <div className="mt-7 mb-14">
            <ReactQuill
              className="h-72"
              theme="snow"
              value={value}
              onChange={handleQuillChange} // Use handleQuillChange function
            />
          </div>
          <div className="mt-7">
            <label htmlFor="blogQuoteHeading">Blog Quote Heading</label>
            <input
              type="text"
              id="blogQuoteHeading"
              name="blogQuoteHeading"
              value={formik.values.blogQuoteHeading}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full border border-gray-300 rounded-md p-2"
            />
          </div>
          <div className="mt-4">
            <label htmlFor="blogQuoteContent">Blog Quote Content</label>
            <textarea
              id="blogQuoteContent"
              name="blogQuoteContent"
              value={formik.values.blogQuoteContent}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              rows="4"
            ></textarea>
          </div>
          <div className="mt-7 grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label htmlFor="image1">Image 1</label>

              <input
                type="file"
                id="image1"
                name="image1"
                accept="image/*"
                onChange={(e) => {
                  handleImageChange(
                    e,
                    setImage1Preview,
                    formik.setFieldValue.bind(null, "image1"),
                    image1InputRef
                  );
                  setImageOne(e.target.files ? e.target.files[0] : null);
                }}
                ref={image1InputRef}
                className="!hidden"
              />

              <div className="mt-2 relative border border-gray-300 rounded-md p-2 h-24">
                {image1Preview ? (
                  <div className="flex items-center justify-center">
                    <img
                      src={image1Preview}
                      alt="Image 1"
                      className="h-20 w-20 object-cover rounded-md mr-2 "
                    />

                    <button
                      type="button"
                      onClick={() =>
                        handleDeleteImage(
                          formik.setFieldValue.bind(null, "image1"),
                          setImage1Preview,
                          image1InputRef
                        )
                      }
                      className="text-red-500 hover:text-red-700"
                    >
                      <IoCloseOutline className="h-6 w-6" />
                    </button>
                  </div>
                ) : (
                  <label
                    htmlFor="image1"
                    className="absolute inset-0 cursor-pointer flex flex-col items-center justify-center"
                  >
                    <span>Upload Image 1</span>
                    <div className="text-gray-500 hover:text-gray-700">
                      <IoCloudUploadOutline className="h-12 w-12" />
                    </div>
                  </label>
                )}
              </div>

              <div className="mt-2">
                <input
                  type="text"
                  id="image1-heading"
                  name="image1Heading"
                  value={formik.values.image1Heading}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Heading for Image 1"
                  className="w-full border border-gray-300 rounded-md p-2"
                />
              </div>

              <div className="mt-2">
                <textarea
                  id="image1-content"
                  name="image1Content"
                  value={formik.values.image1Content}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  rows="2"
                  placeholder="Content for Image 1"
                  className="w-full border border-gray-300 rounded-md p-2"
                ></textarea>
              </div>
            </div>

            <div>
              <label htmlFor="image2">Image 2</label>

              <input
                type="file"
                id="image2"
                name="image2"
                accept="image/*"
                onChange={(e) => {
                  handleImageChange(
                    e,
                    setImage2Preview,
                    formik.setFieldValue.bind(null, "image2"),
                    image2InputRef
                  );
                  setImageTwo(e.target.files ? e.target.files[0] : null);
                }}
                ref={image2InputRef}
                className="!hidden"
              />

              <div className="mt-2 relative border border-gray-300 rounded-md p-2 h-24">
                {image2Preview ? (
                  <div className="flex items-center justify-center">
                    <img
                      src={image2Preview}
                      alt="Image 2"
                      className="h-20 w-20 object-cover rounded-md mr-2 "
                    />

                    <button
                      type="button"
                      onClick={() =>
                        handleDeleteImage(
                          formik.setFieldValue.bind(null, "image2"),
                          setImage2Preview,
                          image2InputRef
                        )
                      }
                      className="text-red-500 hover:text-red-700"
                    >
                      <IoCloseOutline className="h-6 w-6" />
                    </button>
                  </div>
                ) : (
                  <label
                    htmlFor="image2"
                    className="absolute inset-0 cursor-pointer flex flex-col items-center justify-center"
                  >
                    <span>Upload Image 2</span>
                    <div className="text-gray-500 hover:text-gray-700">
                      <IoCloudUploadOutline className="h-12 w-12" />
                    </div>
                  </label>
                )}
              </div>

              <div className="mt-2">
                <input
                  type="text"
                  id="image2-heading"
                  name="image2Heading"
                  value={formik.values.image2Heading}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Heading for Image 2"
                  className="w-full border border-gray-300 rounded-md p-2"
                />
              </div>

              <div className="mt-2">
                <textarea
                  id="image2-content"
                  name="image2Content"
                  value={formik.values.image2Content}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  rows="2"
                  placeholder="Content for Image 2"
                  className="w-full border border-gray-300 rounded-md p-2"
                ></textarea>
              </div>
            </div>

            <div>
              <label htmlFor="image3">Image 3</label>

              <input
                type="file"
                id="image3"
                name="image3"
                accept="image/*"
                onChange={(e) => {
                  handleImageChange(
                    e,
                    setImage3Preview,
                    formik.setFieldValue.bind(null, "image3"),
                    image3InputRef
                  );
                  setImageThree(e.target.files ? e.target.files[0] : null);
                }}
                ref={image3InputRef}
                className="!hidden"
              />

              <div className="mt-2 relative border border-gray-300 rounded-md p-2 h-24">
                {image3Preview ? (
                  <div className="flex items-center justify-center">
                    <img
                      src={image3Preview}
                      alt="Image 3"
                      className="h-20 w-20 object-cover rounded-md mr-2 "
                    />

                    <button
                      type="button"
                      onClick={() =>
                        handleDeleteImage(
                          formik.setFieldValue.bind(null, "image3"),
                          setImage3Preview,
                          image3InputRef
                        )
                      }
                      className="text-red-500 hover:text-red-700"
                    >
                      <IoCloseOutline className="h-6 w-6" />
                    </button>
                  </div>
                ) : (
                  <label
                    htmlFor="image3"
                    className="absolute inset-0 cursor-pointer flex flex-col items-center justify-center"
                  >
                    <span>Upload Image 3</span>
                    <div className="text-gray-500 hover:text-gray-700">
                      <IoCloudUploadOutline className="h-12 w-12" />
                    </div>
                  </label>
                )}
              </div>

              <div className="mt-2">
                <input
                  type="text"
                  id="image3-heading"
                  name="image3Heading"
                  value={formik.values.image3Heading}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Heading for Image 3"
                  className="w-full border border-gray-300 rounded-md p-2"
                />
              </div>

              <div className="mt-2">
                <textarea
                  id="image3-content"
                  name="image3Content"
                  value={formik.values.image3Content}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  rows="2"
                  placeholder="Content for Image 3"
                  className="w-full border border-gray-300 rounded-md p-2"
                ></textarea>
              </div>
            </div>

            <div>
              <label htmlFor="image4">Image 4</label>

              <input
                type="file"
                id="image4"
                name="image4"
                accept="image/*"
                onChange={(e) => {
                  setImageFour(e.target.files ? e.target.files[0] : null);
                  handleImageChange(
                    e,
                    setImage4Preview,
                    formik.setFieldValue.bind(null, "image4"),
                    image4InputRef
                  );
                }}
                ref={image4InputRef}
                className="!hidden"
              />

              <div className="mt-2 relative border border-gray-300 rounded-md p-2 h-24">
                {image4Preview ? (
                  <div className="flex items-center justify-center">
                    <img
                      src={image4Preview}
                      alt="Image 4"
                      className="h-20 w-20 object-cover rounded-md mr-2 "
                    />

                    <button
                      type="button"
                      onClick={() =>
                        handleDeleteImage(
                          formik.setFieldValue.bind(null, "image4"),
                          setImage4Preview,
                          image4InputRef
                        )
                      }
                      className="text-red-500 hover:text-red-700"
                    >
                      <IoCloseOutline className="h-6 w-6" />
                    </button>
                  </div>
                ) : (
                  <label
                    htmlFor="image4"
                    className="absolute inset-0 cursor-pointer flex flex-col items-center justify-center"
                  >
                    <span>Upload Image 4</span>
                    <div className="text-gray-500 hover:text-gray-700">
                      <IoCloudUploadOutline className="h-12 w-12" />
                    </div>
                  </label>
                )}
              </div>

              <div className="mt-2">
                <input
                  type="text"
                  id="image4-heading"
                  name="image4Heading"
                  value={formik.values.image4Heading}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Heading for Image 4"
                  className="w-full border border-gray-300 rounded-md p-2"
                />
              </div>

              <div className="mt-2">
                <textarea
                  id="image4-content"
                  name="image4Content"
                  value={formik.values.image4Content}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  rows="2"
                  placeholder="Content for Image 4"
                  className="w-full border border-gray-300 rounded-md p-2"
                ></textarea>
              </div>
            </div>
          </div>
          <div className="mt-7">
            <label htmlFor="last-section-image">Last Section Image</label>
            <div className="flex flex-col justify-center items-center h-[200px] border border-slate-400 rounded-md">
              <input
                type="file"
                id="last-section-image"
                name="lastSectionImage"
                accept="image/*"
                onChange={(e) => handleImageChangeForLastSection(e)}
                className="!hidden"
              />
              <label
                htmlFor="last-section-image"
                className="cursor-pointer flex flex-col items-center"
              >
                {lastSectionImagePreview ? (
                  <img
                    src={lastSectionImagePreview}
                    alt="Last Section Image"
                    className="h-32 w-56 object-cover rounded-md"
                  />
                ) : (
                  <IoCloudUploadOutline className="h-20 w-20 text-gray-500 hover:text-gray-700" />
                )}
              </label>
              {lastSectionImagePreview && (
                <button type="button" onClick={handleDeleteLastSectionImage}>
                  Delete
                </button>
              )}
            </div>
          </div>
          <div className="mt-2">
            <input
              type="text"
              id="last-section-heading"
              name="lastSectionHeading"
              value={formik.values.lastSectionHeading}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Heading for Last Section"
              className="w-full border border-gray-300 rounded-md p-2"
            />
          </div>
          <div className="mt-2">
            <textarea
              id="last-section-content"
              name="lastSectionContent"
              value={formik.values.lastSectionContent}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              rows="4"
              placeholder="Content for Last Section"
              className="w-full border border-gray-300 rounded-md p-2"
            ></textarea>
          </div>
          <div className="form-group">
            <button type="submit">{loading ? "...Loading " : "save"}</button>
          </div>
        </form>
      </div>
      <div className="bottom-nav">
        <ul>
          <li>
            <FontAwesomeIcon icon={faHome} />
            <span>Home</span>
          </li>
          <li onClick={toggleSidebar}>
            <FontAwesomeIcon icon={faBars} />
            <span>Menu</span>
          </li>
          <li>
            <FontAwesomeIcon icon={faBell} />
            <span>Notifications</span>
          </li>
          <li>
            <FontAwesomeIcon icon={faUser} />
            <span>Profile</span>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default AddBlog;

import React, { useState, useEffect, useRef } from "react";
import "./Navbar.css";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faUserCircle,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import profile from "./pf.jpeg";
import { useSelector, useDispatch } from "react-redux";
import { SignoutuserSuccess } from "../../redux/users/UserSlice";
import { ClickAwayListener } from "@mui/base";
import useSwr from "../../HOOKS/useSWR";
import useMutation from "../../HOOKS/useSWR";
import axios from "axios";
import { BASE_URL } from "../../DATA/category";

function Navbar() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [notificationCount, setNotificationCount] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isNotificationBoxOpen, setIsNotificationBoxOpen] = useState(false);

  const menuRef = useRef(null);
  const { currentUser } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const notificationBoxRef = useRef(null);

  const notificationUrl =
    currentUser?.role === "ADMIN"
      ? "notification/admin"
      : "notification/applicant";
  const { data: notification, isValidating, mutate } = useSwr(notificationUrl);
  const notifications = notification?.data?.data || [];

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const toggleNotificationBox = () => {
    setIsNotificationBoxOpen((prev) => !prev);
  };
  const handleNotificationClick = async (notifId) => {
    try {
      const token = localStorage.getItem("token");
      await axios.put(
        `${BASE_URL}/notification/updatenotification/${notifId}`,
        { isRead: true },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // const {
      //   data: markasread,
      //   isValidating,
      //   mutate,
      // } = useSwr(`notification/updatenotification/${notifId}`);
      mutate(); // Re-fetch notifications
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  useEffect(() => {
    if (notifications) {
      const unreadCount = notifications.filter((notif) => !notif.isRead).length;
      setNotificationCount(unreadCount);
    }
  }, [notifications]);
  // mutate();
  useEffect(() => {
    setNotificationCount(notifications.length);
  }, [notifications]);

  return (
    <nav className={`navbar ${isMenuOpen ? "active" : ""}`} ref={menuRef}>
      <div className="menu-toggle" onClick={toggleMenu}>
        <div className={`bar ${isMenuOpen ? "active" : ""}`}></div>
        <div className={`bar ${isMenuOpen ? "active" : ""}`}></div>
        <div className={`bar ${isMenuOpen ? "active" : ""}`}></div>
      </div>
      <div className="navbar-left">
        <Link to="/">
          <img src="./img/logo.png" alt="Logo" className="navbar-logo" />
        </Link>
      </div>
      <div className={`navbar-center ${isMenuOpen ? "show-menu" : ""}`}>
        <Link to="/jobsearch" onClick={() => setIsMenuOpen(false)}>
          Job Search
        </Link>
        <Link to="/careeradvice" onClick={() => setIsMenuOpen(false)}>
          Career Advice
        </Link>
        <Link to="/contact" onClick={() => setIsMenuOpen(false)}>
          Contact Us
        </Link>
      </div>
      {currentUser ? (
        <ClickAwayListener
          onClickAway={() => {
            setIsDropdownOpen(false);
            setIsNotificationBoxOpen(false);
          }}
        >
          <div className={`navbar-right ${isMenuOpen ? "show-menu" : ""}`}>
            <div className="profile-nav">
              <div className="notification-i" onClick={toggleNotificationBox}>
                <FontAwesomeIcon icon={faBell} className="navbar-icon" />
                <span className="notification-count">{notificationCount}</span>
              </div>
              {isNotificationBoxOpen && (
                <div className="notification-box-container">
                  <div className="notification-box" ref={notificationBoxRef}>
                    <ul>
                      {notifications.length > 0 ? (
                        notifications.map((notif) => (
                          <Link
                            to={notif.link}
                            onClick={() => handleNotificationClick(notif._id)}
                          >
                            <li key={notif._id}>
                              <h2>
                                <strong>{notif.title}</strong>
                              </h2>
                              <p>{notif.description}</p>
                            </li>
                          </Link>
                        ))
                      ) : (
                        <li>No notifications</li>
                      )}
                    </ul>
                  </div>
                </div>
              )}
              <div className="profile-nav">
                <div
                  onClick={() => setIsDropdownOpen((prev) => !prev)}
                  className="dropdown-toggle"
                  ref={dropdownRef}
                >
                  <img
                    src={currentUser?.avatar || profile}
                    alt="Profile"
                    className="navbar-avatar"
                  />
                </div>
                <div className="dropdown">
                  <div
                    className={`dropdown-menu ${isDropdownOpen ? "show" : ""}`}
                  >
                    <ul>
                      <li>
                        {currentUser?.role === "ADMIN" ? (
                          <Link
                            to="/adminprofile"
                            onClick={() => setIsDropdownOpen(false)}
                          >
                            <FontAwesomeIcon
                              icon={faUserCircle}
                              className="profile-menu-icon"
                            />{" "}
                            {currentUser?.name.split(" ")[0] || "Not Provided"}
                          </Link>
                        ) : (
                          <Link
                            to="/applicantprofile"
                            onClick={() => setIsDropdownOpen(false)}
                          >
                            <FontAwesomeIcon
                              icon={faUserCircle}
                              className="profile-menu-icon"
                            />{" "}
                            {currentUser?.name.split(" ")[0] || "Not Provided"}
                          </Link>
                        )}
                      </li>
                      <li>
                        <Link
                          onClick={() => {
                            setIsDropdownOpen(false);
                            dispatch(SignoutuserSuccess());
                            localStorage.removeItem("token");
                            localStorage.clear();
                          }}
                          to={
                            currentUser?.role === "ADMIN"
                              ? "/signin?role=admin"
                              : "/signin?role=candidate"
                          }
                        >
                          <FontAwesomeIcon
                            icon={faSignOutAlt}
                            className="profile-menu-icon"
                          />{" "}
                          Logout
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ClickAwayListener>
      ) : (
        <div className={`navbar-right ${isMenuOpen ? "show-menu" : ""}`}>
          <Link
            to="/signin?role=candidate"
            onClick={() => setIsMenuOpen(false)}
          >
            <img src="/img/icon/lock.svg" alt="Candidate Image" />
            Candidate Login
          </Link>
          <button
            onClick={(e) => {
              e.preventDefault();
              searchParams.set("role", "admin");
              navigate(`signin?${searchParams.toString()}`);
            }}
            className="emp-btn btn-primary"
          >
            Employers / Post Job
          </button>
        </div>
      )}
    </nav>
  );
}

export default Navbar;

import React from "react";
import styled from "styled-components";
import backgroundImage from "./fairbg.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const PageWrapper = styled.div`
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const PrivacyContainer = styled.div`
  padding: 40px 20px;
  max-width: 900px;
  margin: 40px auto;
  background: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-family: "Roboto", sans-serif;
  color: #333;

  @media (max-width: 768px) {
    padding: 20px 15px;
    margin: 20px auto;
  }
`;

const PrivacyHeader = styled.h1`
  font-size: 2.5em;
  text-align: center;
  color: #2c3e50;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    font-size: 2em;
    margin-bottom: 20px;
  }
`;

const PrivacySection = styled.section`
  margin-bottom: 30px;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const SectionTitle = styled.h2`
  font-size: 1.8em;
  color: #34495e;
  margin-bottom: 15px;
  position: relative;
  padding-bottom: 10px;

  &::after {
    content: "";
    width: 50px;
    height: 3px;
    background: #fe4324;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  @media (max-width: 768px) {
    font-size: 1.5em;
    margin-bottom: 10px;
    padding-bottom: 5px;

    &::after {
      width: 40px;
      height: 2px;
    }
  }
`;

const SectionContent = styled.p`
  color: #7f8c8d;
  font-size: 1em;
  line-height: 1.8;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 0.9em;
  }
`;

const TickMark = styled(FontAwesomeIcon)`
  color: #dc143c;
  margin-right: 10px;
  margin-top: 6px;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start;

  @media (max-width: 768px) {
    font-size: 0.9em;
  }
`;

function Privacy() {
  return (
    <>
      <PageWrapper>
        <PrivacyContainer>
          <PrivacyHeader>Fair Use Policy</PrivacyHeader>
          <PrivacySection>
            <SectionTitle>Resource Fair Usage Policies</SectionTitle>
            <SectionContent>
              <ul>
                <ListItem>
                  <TickMark icon={faCheck} />
                  <span>
                    It is acknowledged that any single account is entitled to
                    utilize the server resources, within reason, up to what is
                    allotted or by what is physically available. If resources
                    become scarce, Talent Folder LLC (“TALENT FOLDER” or
                    “Company”) reserves the right to limit users of the affected
                    machine to a lower limit to preserve the effectiveness of
                    the service for all users. This policy only applies to
                    websites that are abusive in service, disk space or resource
                    consumption and where it is evident that the fair-use of
                    resources among customers has been breached, particularly
                    regarding disk space, bandwidth or CPU processing power
                    utilization. Websites that are found to contain either/or no
                    html documents, many unlinked files are subject to warning,
                    suspension or cancellation at the sole discretion of TALENT
                    FOLDER.
                  </span>
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  <span>
                    If a Fair-Usage breach occurs, whose determination within
                    reason is solely up to TALENT FOLDER, the subscriber will be
                    notified of any actions that TALENT FOLDER may have to take.
                  </span>
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  <span>
                    In order to ensure a consistent and quality experience for
                    all subscribers, TALENT FOLDER does place automated
                    safeguards to protect against any one site growing too
                    quickly and adversely impacting the system until TALENT
                    FOLDER can evaluate said sites resource needs. TALENT FOLDER
                    also reserves the right to limit processor time, bandwidth,
                    or processes in cases where it is necessary to prevent
                    negatively impacting other subscribers or users.
                  </span>
                </ListItem>
              </ul>
            </SectionContent>
          </PrivacySection>
          <PrivacySection>
            <SectionTitle>General Fair Usage</SectionTitle>
            <SectionContent>
              <ul>
                <ListItem>
                  <TickMark icon={faCheck} />
                  The Company will not be responsible for any damage your
                  business may suffer by use of its network, system, or website.
                  The Company disclaims any warranty or merchantability or
                  fitness for a particular purpose.
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  TALENT FOLDER reserves the right to change any terms of this
                  policy without prior notice.
                </ListItem>
              </ul>
            </SectionContent>
          </PrivacySection>
        </PrivacyContainer>
      </PageWrapper>
    </>
  );
}

export default Privacy;

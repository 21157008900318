import React from "react";
import styled from "styled-components";
import backgroundImage from "./terms.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const PageWrapper = styled.div`
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const PrivacyContainer = styled.div`
  padding: 40px 20px;
  max-width: 900px;
  margin: 40px auto;
  background: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-family: "Roboto", sans-serif;
  color: #333;

  @media (max-width: 768px) {
    padding: 20px 15px;
    margin: 20px auto;
  }
`;

const PrivacyHeader = styled.h1`
  font-size: 2.5em;
  text-align: center;
  color: #2c3e50;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    font-size: 2em;
    margin-bottom: 20px;
  }
`;

const PrivacySection = styled.section`
  margin-bottom: 30px;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const SectionTitle = styled.h2`
  font-size: 1.8em;
  color: #34495e;
  margin-bottom: 15px;
  position: relative;
  padding-bottom: 10px;

  &::after {
    content: "";
    width: 50px;
    height: 3px;
    background: #fe4324;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  @media (max-width: 768px) {
    font-size: 1.5em;
    margin-bottom: 10px;
    padding-bottom: 5px;

    &::after {
      width: 40px;
      height: 2px;
    }
  }
`;

const SectionContent = styled.p`
  color: #7f8c8d;
  font-size: 1em;
  line-height: 1.8;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 0.9em;
  }
`;

const TickMark = styled(FontAwesomeIcon)`
  color: #dc143c;
  margin-right: 10px;
  margin-top: 6px;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start;

  @media (max-width: 768px) {
    font-size: 0.9em;
  }
`;

function Terms() {
  return (
    <>
      <PageWrapper>
        <PrivacyContainer>
          <PrivacyHeader>Terms &amp; Conditions.</PrivacyHeader>
          <PrivacySection>
            <SectionTitle>Website Operator</SectionTitle>
            <SectionContent>
              <ul>
                <ListItem>
                  <TickMark icon={faCheck} />
                  <span>
                    This website is owned, controlled and operated by Talent
                    Folder LLC whose registered office is at Business Center,
                    SPC Freezone, Sharjh, UAE (“Talent Folder LLC”). Information
                    or material submitted to this website is hosted and stored
                    in a database on servers situated in The Netherlands.
                    References to Group Companies are references to companies
                    that are controlled, directly or indirectly, by Talent
                    Folder LLC or are group companies of Talent Folder LLC.
                    <br></br>
                    In these terms and conditions &quot;we&quot; means Talent
                    Folder LLC, and &quot;our&quot; shall be construed
                    accordingly. &quot;you&quot; means the person, company or
                    organization browsing and/or using the Website, and
                    &quot;your&quot; shall be construed accordingly.
                  </span>
                </ListItem>
              </ul>
            </SectionContent>
          </PrivacySection>
          <PrivacySection>
            <SectionTitle>Applicability</SectionTitle>
            <SectionContent>
              <ul>
                <ListItem>
                  <TickMark icon={faCheck} />
                  These terms and conditions are applicable to every legal
                  relationship between Talent Folder LLC and you which is
                  related directly or indirectly to this website and the
                  material contained therein or provided thereby (including
                  design, layout, look, appearance, information, texts,
                  graphics, images, trademarks, logo&#39;s, product names and
                  software; together hereinafter referred to as
                  &quot;Material&quot; and this website and the Material
                  hereinafter referred to as this &quot;Website&quot;).
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  Talent Folder LLC grants you the right to access and use this
                  Website subject to these terms and conditions. You agree to
                  these terms and conditions by accessing or using this Website
                  or continuing accessing or using this Website, including after
                  changes have been made to this Website or these terms and
                  conditions (pursuant to clause 2.4 below). Any terms which you
                  desire to be applicable are hereby explicitly rejected. If you
                  do not agree to these terms of use, you must not use or access
                  our website.
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  Terms of business of Talent Folder LLC or its Group Companies
                  will apply to any offer or agreement related to our services
                  or our organization, or as the case may be, the terms that
                  regulate the relationship between the jobseeker and the
                  relevant Group Company. Copies of these may be provided by or
                  can otherwise be obtained directly from the relevant Group
                  Company.
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  Talent Folder LLC reserves the right to and may, at any time
                  and without prior notice, (i) terminate or limit such access
                  and use, (ii) vary or amend this Website and these terms and
                  conditions and (iii) charge and subsequently increase fees.
                  The amended terms shall apply from the moment they are
                  published on this page, without prior notice being required.
                  Please check this page from time to time to take notice of any
                  changes we have made, as they are binding on you.
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  Any offers made by means of this Website may be revoked by
                  Talent Folder LLC, its Group companies, job offerors or
                  recruiters both before and immediately after acceptance.
                </ListItem>
              </ul>
            </SectionContent>
          </PrivacySection>

          <PrivacySection>
            <SectionTitle>Use Of Website</SectionTitle>
            <SectionContent>
              <ul>
                <ListItem>
                  <TickMark icon={faCheck} />
                  This Website is protected by intellectual property rights,
                  including copyrights, database rights and trademark rights,
                  and such rights are owned by Talent Folder LLC, its licensors
                  or contributors to this Website. The content of the Website
                  and all downloads are intellectual property rights of Talent
                  Folder LLC or Group companies or third parties. All
                  trademarks, trade names and logos displayed on the site are
                  registered and unregistered trademarks of Talent Folder LLC or
                  Group companies.
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  Talent Folder LLC, its licensors and the contributors to this
                  Website only grant you the revocable and nonexclusive right,
                  which may not be sublicensed or transferred, to use this
                  Website solely for (i) in case of job seekers, personal,
                  noncommercial use, (ii) in case of job offerors and
                  recruiters, recruitment purposes relating to job seekers who
                  provided information to or via this Website (meaning, among
                  other things, that job offerors and recruiters other than
                  Talent Folder LLC or its Group Companies may only read such
                  information and use it for a specific job proposal and not,
                  among other things, make such information public, copy parts
                  of the database or use it to bring your services to the
                  attention of others) and (iii) in case you are not a job
                  seeker, job offeror or recruiter, personal, non-commercial use
                  (meaning, among other things, that job offerors or recruiters
                  may not be approached for providing services by other means
                  than the employment relationship which they are seeking to
                  have with a job seeker). The use of any software that may be
                  made available to download from this Website is, insofar an
                  end user license agreement accompanies is or is included with
                  such software, subject to the terms and conditions of such end
                  user license agreement with the exclusion of these terms and
                  conditions. If, however, such end user license agreement is
                  (for whatever reason) not applicable, the use of such software
                  is subject to these terms and conditions.
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  Any use of this Website other than in accordance with clause
                  3.2 (including making public, copying other than per instance
                  viewing of a page from the Website, spamming, denial of
                  service attacks, automated searches, spidering, using
                  intelligent agents, anything which may decrease performance of
                  the Website, harvesting, modifying, framing, transmitting or
                  distributing) (i) is not permitted without prior approval from
                  Talent Folder LLC and (ii) may constitute to intellectual
                  property right infringement, be a criminal offence or give
                  rise to claim for damages.
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  You will comply with all applicable laws, rules and
                  regulations relating to your use of the Website. You will
                  comply with all reasonable instructions we give you relating
                  to the Website.
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  You are responsible for use of the Website by any person using
                  your password. You will ensure the confidentiality of your
                  password and prevent any unauthorized person accessing the
                  Website through the use of your password.
                </ListItem>
              </ul>
            </SectionContent>
          </PrivacySection>

          <PrivacySection>
            <SectionTitle>Your Materials</SectionTitle>
            <SectionContent>
              <ul>
                <ListItem>
                  <TickMark icon={faCheck} />
                  You guarantee that all material you submit, send, or upload to
                  the Website (“Your Material”) (i) is legal, decent, honest and
                  truthful, (ii) complies with all laws, rules and regulations,
                  (iii) does not infringe intellectual property rights of use or
                  any third party, (iv) is not subject to any duty of
                  confidentiality and is non-proprietary, (v) is not defamatory,
                  obscene, offensive, pornographic or otherwise objectionable,
                  (vi) is free of bugs, worms or viruses and (vii) is not
                  fraudulent, false, unreliable or misleading. You agree that
                  Talent Folder LLC or its Group Companies may remove Your
                  Material from the Website if it deems that you are in breach
                  of the foregoing sentence.
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  You grant Talent Folder LLC a worldwide, non-exclusive,
                  irrevocable and perpetual license to use, make public, copy,
                  modify, display, frame, transmit or distribute Your Material
                  for any purpose (together &quot;Using Your Material&quot;), it
                  being understood that your personal information will always be
                  held by Talent Folder LLC in accordance with its privacy
                  statement.
                </ListItem>
              </ul>
            </SectionContent>
          </PrivacySection>

          <PrivacySection>
            <SectionTitle>
              Reliance &amp; relationships between visitors
            </SectionTitle>
            <SectionContent>
              <ul>
                <ListItem>
                  <TickMark icon={faCheck} />
                  This Website should not be relied on and does not constitute
                  any form of advice or recommendation. Any arrangements made
                  between you and any third party named or referred to on the
                  Website are entirely at your sole risk and responsibility. Any
                  of the content on our Website may be out of date at any given
                  time, and we are under no obligation to update it. We do not
                  guarantee that our website, or any content on it, will be free
                  from errors or omissions.
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  Nothing on this Website is intended to be nor should be
                  construed as an offer to enter into a contractual relationship
                  and Talent Folder LLC makes no representation or warranty and
                  is not responsible for you finding suitable employment or
                  filling your vacancy.
                </ListItem>
              </ul>
            </SectionContent>
          </PrivacySection>

          <PrivacySection>
            <SectionTitle>Linking</SectionTitle>
            <SectionContent>
              <ul>
                <ListItem>
                  <TickMark icon={faCheck} />
                  This Website contains links to other websites and other
                  websites may contain links to this Website. Talent Folder LLC
                  is not responsible for or liable for such other websites or
                  the availability of such links. Any link provided on this
                  Website is provided as a convenience only and such links or
                  websites linking to this Website are not intended to be, nor
                  may be construed as, an endorsement of any kind by Talent
                  Folder LLC of those other websites.
                </ListItem>
              </ul>
            </SectionContent>
          </PrivacySection>

          <PrivacySection>
            <SectionTitle>Liability</SectionTitle>
            <SectionContent>
              <ul>
                <ListItem>
                  <TickMark icon={faCheck} />
                  No representation, warranty or condition, express or implied,
                  statutory or otherwise, as to condition satisfactory quality,
                  performance, fitness for purpose or otherwise is given by
                  Talent Folder LLC or its Group Companies in respect of the
                  Website, including the Website (i) being compatible with all
                  hardware and software which may be used by visitors to the
                  Website, (ii) operating in accordance with your expectations,
                  (iii) being error free, (iv) being accurate, or (v) being
                  available. All such representations, warranties and conditions
                  are excluded except to the extent their exclusion is
                  prohibited by law.
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  Talent Folder LLC liability to you and your sole remedy
                  vis-à-vis Talent Folder LLC under or in connection with the
                  Website (whether based on negligence, breach of contract,
                  breach of statutory duty, restitution, tort or otherwise)
                  shall be to take such actions reasonably required at Talent
                  Folder LLC discretion to remove or delete data entered in
                  error or the subject of dispute, or correct data entered in
                  error.
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  These terms and conditions do not exclude liability (if any)
                  to you for personal injury or death resulting from negligence
                  or fraud of Talent Folder LLC or its Group Companies or for
                  any matter which would be illegal for Talent Folder LLC or any
                  such Group Company to exclude or to attempt to exclude its
                  liability.
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  Except as set out in clause 7.3, each of Talent Folder LLC
                  will not be liable to you whatsoever (whether based on
                  negligence, breach of contract, breach of statutory duty,
                  restitution, tort or otherwise) for any injury, death, cost,
                  damage or direct, indirect or consequential loss (all three of
                  which terms include pure economic loss, loss of profits, loss
                  of business, depletion of goodwill and like loss) whether it
                  was foreseeable or not, howsoever caused, arising out of or in
                  connection with the use or relying on this Website, including
                  as a result of any computer virus. It is your responsibility
                  to run an antivirus program when using this Website.
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  You agree to indemnify Talent Folder LLC and hold them
                  harmless from and against any costs, expenses, liabilities,
                  injuries, damages, claims, demands, proceedings or legal costs
                  which Talent Folder LLC or such Group Companies may suffer or
                  incur in consequence of (i) Using Your Material or (ii) you
                  are being in breach of these terms and conditions.
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  Certain statements on this Website comprise forecasts on
                  Talent Folder LLC’s future financial condition and results
                  from operations and certain plans and goals. By their nature,
                  such forecasts generate risk and uncertainty because they
                  concern events in the future and depend on circumstances which
                  then apply. Any number of factors can cause actual results and
                  developments to deviate from those expressed in the forecasts
                  stated here. Such factors can be, for example, general
                  economic conditions, scarcity on the employment market and the
                  resulting demand for (temporary) personnel, changes in labor
                  legislation, personnel costs, future exchange rates and
                  interest, changes in tax rates, future corporate mergers,
                  acquisitions and divestments and the speed of technical
                  change. You are cautioned not to place undue reliance on these
                  forecasts. These are not intended to provide any guarantees
                  regarding future results. Due to the risks and uncertainties
                  that are always involved in any operating environment or
                  business, we cannot make any assurances that these forecasts
                  will prove correct.
                </ListItem>
              </ul>
            </SectionContent>
          </PrivacySection>

          <PrivacySection>
            <SectionTitle>Privacy and Cookies</SectionTitle>
            <SectionContent>
              <ul>
                <ListItem>
                  <TickMark icon={faCheck} />
                  We are committed to the protection of Personal Data collected
                  via our website. Talent Folder LLC privacy statement can be
                  seen by clicking here and forms part of these terms and
                  conditions. Our privacy statement explains what personal data
                  we collect, for what purposes we may use it, how we handle it,
                  to whom may we disclose it and what are your rights. If you
                  have any questions related to our Data Protection statement or
                  any data protection concerns, or would like to exercise your
                  rights, you can contact us at Hello@talentfolder.com
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  Our website uses web cookies. We provide you with information
                  on the use of cookies in our Cookies statement, that you can
                  read by clicking here. These terms also apply to your use of
                  and access to our website.
                </ListItem>
              </ul>
            </SectionContent>
          </PrivacySection>

          <PrivacySection>
            <SectionTitle>Jurisdiction and general</SectionTitle>
            <SectionContent>
              <ul>
                <ListItem>
                  <TickMark icon={faCheck} />
                  The formation, existence, construction, performance, validity
                  in all aspects whatsoever of these terms and conditions or of
                  any term of these terms and conditions or any dispute which
                  may arise out of or in connection with (i) any legal
                  relationship between Talent Folder LLC and you which is
                  related directly or indirectly to this Website, (ii) these
                  terms and conditions, (iii) use of the Website or (iv) Use of
                  Your Materials shall be governed by UAE law and the competent
                  court in SHARJH shall have exclusive jurisdiction to settle
                  any such disputes.
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  These terms and conditions (including the privacy statement
                  referred to in clause 8) contain all the terms which you and
                  Talent Folder LLC have agreed to in relation to the use of the
                  Website.
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />
                  If a provision of these terms and conditions is or becomes
                  illegal, invalid or unenforceable in any jurisdiction, that
                  will not affect (a) the legality, validity or enforceability
                  in any other jurisdiction of that or any other provisions; or
                  (b) the legality, validity or enforceability in that
                  jurisdiction of any other provision of these terms and
                  conditions.
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />A waiver (whether express or
                  implied) by Talent Folder LLC of any of the provisions of
                  these terms and conditions or of any breach or default by the
                  other of Talent Folder LLC in performing any of these
                  provisions will not constitute a continuing waiver and that
                  waiver will not prevent Talent Folder LLC from subsequently
                  enforcing any of the provisions of these terms and conditions
                  not waived or from acting on any subsequent breach or default
                  by the other party under any of the provisions of these terms
                  and conditions.
                </ListItem>
                <ListItem>
                  <TickMark icon={faCheck} />A person who is not a party to
                  these terms and conditions has no rights to enforce, or to
                  enjoy the benefit of, these terms and conditions. However,
                  every Group Company shall be entitled to enforce and benefit
                  from the provisions of the terms and conditions as if it were
                  a party thereto. Also, every employee of Talent Folder LLC,
                  supplier, subcontractor or person/party for which Talent
                  Folder LLC is vicariously liable shall be entitled to benefit
                  from the provisions of the terms and conditions as if it were
                  a party thereto.
                </ListItem>
              </ul>
            </SectionContent>
          </PrivacySection>
        </PrivacyContainer>
      </PageWrapper>
    </>
  );
}

export default Terms;
